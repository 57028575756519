import { Injectable } from '@angular/core';
import { AddressService } from '@app/services/address.service';
import { Globals } from '@app/services/globals';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DepotUtils {
    listen = [];
    allDepotsLabel;


    constructor(
        private globals: Globals,
        private addressService: AddressService,
        public translate: TranslateService,
    ) { }

    getDepotsWithAllDepotsOption() {
        this.getTranslations();
        let depots = [...this.globals.depotsWithNamesArray];
        depots.unshift({ companyDepot: { id: -1 }, name: this.allDepotsLabel });
        depots = [...depots];
        return depots;
    }

    // if depot has no name, then fall back to the depot's address label
    getDepotName(companyDepot) {
        let depotName: string;

        if (companyDepot.address.label) {
            depotName = companyDepot.address.label;
        } else {
            depotName = companyDepot.address.value;
        }

        return depotName;
    }

    // fetches the first depot that has the provided warehouseId
    getFirstDepotWithWarehouseId(warehouseId) {
        for (let depot of this.globals.depotsArray) {
            if (depot['companyDepot']['warehouse_ids'].includes(warehouseId)) {
                return depot;
            }
        }
    }

    // fetches the first depot that has the provided depot id
    getFirstDepotWithDepotId(depotId) {
        for (let depot of this.globals.depotsArray) {
            if (depot['companyDepot']['id'] == depotId) {
                return depot;
            }
        }
    }

    // returns an array of drivers w/ name & id that belong to a certain depot
    getDriversOfDepot(drivers, depotId) {
        const driversOfDepot = [];
        drivers.forEach(driver => {
            if (driver.driverLocations?.companyDepot?.modelId == depotId) {
                driversOfDepot.push({
                    id: driver.driver.id,
                    name: driver.userProfile.name
                });
            }
        });
        return [...driversOfDepot];
    }

    isDepotOverloadedWithWarehouseId(warehouseId: number): boolean {
        const depot = this.getFirstDepotWithWarehouseId(warehouseId);
        if (depot) return depot.companyDepot.is_depot_overloaded;
        return false;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.STATIONS_ALL').subscribe((res: string) => { this.allDepotsLabel = res; }));
    }
}
