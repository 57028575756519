import { Component, OnInit, ViewChild, OnDestroy, Injector, AfterViewInit } from '@angular/core';
import { Globals } from '@app/services/globals';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import * as moment from 'moment-timezone';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SettingsService } from '@app/services/settings.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, fromEvent } from 'rxjs';
import { map, debounceTime, tap, take } from 'rxjs/operators';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import * as libphonenumber from 'libphonenumber-js';
import { LmNotificationService } from '@app/core/services/notification.service';
import { SettingsDepotsModalComponent } from './depots/depots-modal/settings-depots-modal.component';
import { INITIAL_DATA } from '@app/model/initial-data';
import { DepotsGridComponent } from '@app/grids/depots-grid/depots-grid.component';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
    @ViewChild(DepotsGridComponent) depots: DepotsGridComponent;

    listen = [];

    days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    dayLabels = [];

    currentLang;

    selectedTimeWindow;
    customTimeWindows = [];
    doubleTimeWindow = [];
    selectedTimeWindowId = null;
    timeWindowSingleDefault = [480, 1200];
    timeWindowDoubleDefault = [480, 840, 1020, 1200];
    timeWindowsData = [];
    timeWindowSingle = [];
    timeWindowDouble = [];
    timeWindowDailyValues = {};
    timeWindowUnformattedDefault = ['08:00', '20:00'];
    timeWindowUnformatted = [];
    timeWindowOptions;
    timeWindowOptionsDouble;
    toggleTimeTableText;

    reloadDurationAtIntermediateDepot = '';
    reloadTimeUnformatted = '00:30';
    reloadTimeMinutes = [30];
    reloadTimeOptions;

    solverSigma = '';
    sigmaUnformatted = '';
    sigmaValue = [30];
    sigmaOptions;

    companyUrl = 'api/v1/companies/' + this.globals.companyId;
    companyId = this.globals.companyId;
    companySettingsId;
    myForm: FormGroup;
    companyName = '';
    contactName = '';
    countryPrefix = '';
    phoneNumber = '';
    telephone = '';
    email = '';
    website = '';
    tin = '';
    registryNumber = '';
    taxOffice = '';
    description = '';

    // defaultDepartureTime = '';
    // defaultDepartureDatetime = '';
    // defaultDepartureTimezone = '';

    durationUnformatted = '02:00';
    durationSeconds = [120];
    duration = '';

    durationOffsetUnformatted = '00:00';
    durationOffsetSeconds = [0];
    durationOffset = '';

    auditEnabled = false;
    isAutoInvoiceApprovalEnabled = false;
    routingOptimizeMode = 1;
    reloadSystemSwitch = true;
    // lateStops = false;
    defaultLoad = 1;

    printPageSizes = [];
    printPageSize = 4;
    isSingleVoucherPrintEnabled = false;

    printVoucherOrderOptions = [];
    printVoucherOrder;

    invoiceNotes = '';

    // defaultWorkingHoursUnformatted = '08:00';
    // defaultWorkingHoursMinutes = [480];
    // defaultWorkingHours = '';

    driverOvertime = false;
    isClickedOnce = false;
    durationOptions;
    durationOffsetOptions;
    // defaultWorkingHoursOptions;
    addressTerm: String = '';
    addressType: String = 'houseNumber';
    freeformAddress = '';
    country = 'GR';
    lat = '';
    lon = '';

    portalAccess = 0;
    portalAccessDefault = 0;
    portalOnDefault = false;
    avoidTolls = false;
    portalPlusDefault = false;

    minuteLabel = '';

    onlyOneDepotAlert = '';

    constructor(
        public globals: Globals,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private dataService: DataService,
        private settingsService: SettingsService,
        public translate: TranslateService,
        private dateTimeCalculatorService: DateTimeCalculatorService,
        private _notificationSvc: LmNotificationService,
        private _injector: Injector
    ) {
        this.initializeSlider();
        this.myForm = formBuilder.group({
            'company': formBuilder.group({
                'id': [this.companyId],
                'name': [this.companyName],
                'countryPrefix': [this.countryPrefix],
                'phoneNumber': [this.phoneNumber],
                'telephone': [this.telephone],
                'email': [this.email],
                'website': [this.website],
                'tax_office': this.taxOffice,
                'registry_number': this.registryNumber,
                'tin': this.tin,
                'description': this.description,
            }),
            'companySettings': formBuilder.group({
                'timeWindows': formBuilder.array([]),
                'recurringDays': formBuilder.array([]),
                'company_id': [this.companySettingsId],
                // 'default_departure_time': [this.defaultDepartureTime],
                // 'default_project_problem_departure_datetime': [this.defaultDepartureDatetime],
                // 'default_project_problem_departure_datetime_timezone': [this.defaultDepartureTimezone],
                'default_stop_point_duration': [this.duration],
                'durationSeconds': [this.durationSeconds],
                'default_stop_point_delivery_load': [this.defaultLoad],
                'default_stop_point_pickup_load': [this.defaultLoad],
                // 'project_problem_horizon': [this.defaultWorkingHours],
                // 'defaultWorkingHoursUnformatted': [this.defaultWorkingHoursUnformatted],
                // 'defaultWorkingHoursMinutes': [this.defaultWorkingHoursMinutes],
                'driver_overtime': [this.driverOvertime],
                'companyTimeWindow': [this.selectedTimeWindow],
                'companyTimeWindowCategoryId': [this.selectedTimeWindowId],
                'reload_system_switch': [this.reloadSystemSwitch],
                'reload_duration_at_intermediate_depot': [this.reloadDurationAtIntermediateDepot],
                'reloadTimeMinutes': [this.reloadTimeMinutes],
                'solver_sigma': [this.solverSigma],
                'sigma': [this.sigmaValue],
                // 'allow_late_stops': [this.lateStops],
                'portal_access': [this.portalAccessDefault],
                'portalOnDefault': [this.portalOnDefault],
                'avoid_tolls': [this.avoidTolls],
                'portalPlusDefault': [this.portalPlusDefault],
                'is_qr_audit_enabled': [this.auditEnabled],
                'invoice_notes': [this.invoiceNotes],
                'is_auto_invoice_approval_enabled': [this.isAutoInvoiceApprovalEnabled],
                'routing_optimize_mode': [this.routingOptimizeMode]
            }),
            'companyVoucherSettings': formBuilder.group({
                'print_voucher_order': [this.printVoucherOrder],
                'print_page_size': [this.printPageSize],
                'is_single_voucher_print_enabled': [this.isSingleVoucherPrintEnabled],
            }),
            'companyDurationOffsets': formBuilder.group({
                'durationOffsetSeconds': [this.durationOffsetSeconds],
                '1': [this.durationOffset],
            }),
        });
    }

    ngAfterViewInit(): void {
        // setTimeout(_=> this.showDepotModal(), 500)
    }

    showDepotModal() {
        this._notificationSvc.showDialog<boolean>({ 
            componentType: SettingsDepotsModalComponent,   
            injector: this._injector, 
            data: {globals: this.globals, item: {...INITIAL_DATA.newDepotItem}} 
        })
        .subscribe((res:any) => {
            if(res) this.depots.setDepotsGridData();
        });
    }

    langChanged() {
        this.translate.use(this.currentLang);
        this.globals.currentLang = this.currentLang;
        localStorage.setItem('lang', this.currentLang);
    }

    loadData() {
        this.http.get(this.companyUrl).pipe(take(1)).subscribe(response => {
            const data = response['items'];
            this.selectedTimeWindow = {};
            this.companyId = data.company.id;
            this.companyName = data.company.name;
            this.description = data.company.description;

            this.telephone = data.company.telephone;    
            if (this.telephone === 'n/a') { this.telephone = ''; }
            if (this.telephone) {
                this.phoneNumber = this.telephone;
                if (this.telephone.length > 5) {
                    const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
                    this.phoneNumber = phoneObj.nationalNumber;
                    this.countryPrefix = '+' + phoneObj.countryCallingCode;
                }
            }

            this.email = data.company.email;
            this.website = data.company.website;
            this.registryNumber = data.company.registry_number;
            this.tin = data.company.tin;
            this.taxOffice = data.company.tax_office;

            this.companySettingsId = data.companySettings.company_id;
            // this.lateStops = data.companySettings.allow_late_stops;
            // this.reload = data.companySettings.reload;
            // this.setTimeWindows(data.companySettings.time_windows);

            this.printPageSize = data.companyVoucherSettings.print_page_size ?? this.globals.printPageSizes['A4'];
            this.printVoucherOrder = data.companyVoucherSettings.print_voucher_order;
            this.isSingleVoucherPrintEnabled = data.companyVoucherSettings.is_single_voucher_print_enabled ?? false;

            this.reloadSystemSwitch = data.companySettings.reload_system_switch;
            this.auditEnabled = data.companySettings.is_qr_audit_enabled;
            this.isAutoInvoiceApprovalEnabled = data.companySettings.is_auto_invoice_approval_enabled;
            this.routingOptimizeMode = data.companySettings.routing_optimize_mode;

            this.invoiceNotes = data.companySettings.invoice_notes;

            this.portalAccessDefault = data.companySettings.portal_access;
            if (this.portalAccessDefault === this.globals.portalAccessConstants['NO_ACCESS']) {
                this.portalOnDefault = false;
                this.portalPlusDefault = false;
            } else if (this.portalAccessDefault === this.globals.portalAccessConstants['ACCESS_NO_HISTORY']) {
                this.portalOnDefault = true;
                this.portalPlusDefault = false;
            } else if (this.portalAccessDefault === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
                this.portalOnDefault = true;
                this.portalPlusDefault = true;
            }

            this.avoidTolls = data.companySettings.avoid_tolls;

            if (data.companySettings.default_project_problem_departure_datetime) {
                // this.defaultDepartureDatetime = data.companySettings.default_project_problem_departure_datetime;
                // this.defaultDepartureTimezone = data.companySettings.default_project_problem_departure_datetime_timezone;
                // this.defaultDepartureTime = moment(this.defaultDepartureDatetime).format('HH:mm');
            }
            this.defaultLoad = data.companySettings.default_stop_point_delivery_load;
            this.driverOvertime = data.companySettings.driver_overtime;

            // if (data.companySettings.project_problem_horizon) {
            //     this.defaultWorkingHours = data.companySettings.project_problem_horizon;
            //     this.defaultWorkingHoursUnformatted = moment(data.companySettings.project_problem_horizon, 'HH:mm:SS').format('HH:mm');
            //     this.defaultWorkingHoursMinutes = [moment.duration(this.defaultWorkingHours).asMinutes()];
            // }
            if (data.companySettings.default_stop_point_duration) {
                this.duration = data.companySettings.default_stop_point_duration;
                this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
                this.durationSeconds = [moment.duration(this.duration).asSeconds()];
            }
            if (data.companyDurationOffsets) {
                if (data.companyDurationOffsets['1']) {
                    this.durationOffset = data.companyDurationOffsets['1'];
                    this.durationOffsetUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.durationOffset, true);
                    this.durationOffsetSeconds = [moment.duration(this.durationOffset).asSeconds()];
                }
            }
            if (data.companySettings.reload_duration_at_intermediate_depot) {
                this.reloadDurationAtIntermediateDepot = data.companySettings.reload_duration_at_intermediate_depot;
                this.reloadTimeUnformatted = moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes() + this.minuteLabel;
                this.reloadTimeMinutes = [moment.duration(this.reloadDurationAtIntermediateDepot).asMinutes()];
            }
            if (data.companySettings.solver_sigma) {
                this.solverSigma = data.companySettings.solver_sigma;
                this.sigmaUnformatted = data.companySettings.solver_sigma;
                this.sigmaValue = [data.companySettings.solver_sigma];
            }

            this.patchFormForLoad();
        });

    }

    patchForm() {
        this.myForm.patchValue({
            'company': {
                'telephone': this.telephone,
            },
            'companySettings': {
                // 'default_departure_time': this.defaultDepartureTime,
                // 'default_project_problem_departure_datetime': this.defaultDepartureDatetime,
                // 'default_project_problem_departure_datetime_timezone': this.defaultDepartureTimezone,
                'default_stop_point_duration': this.duration,
                // 'project_problem_horizon': this.defaultWorkingHours,
                'companyTimeWindowCategoryId': this.selectedTimeWindowId,
                'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
                'solver_sigma': this.solverSigma,
                'portal_access': this.portalAccessDefault,
            },
            'companyVoucherSettings': {
                'is_single_voucher_print_enabled': this.isSingleVoucherPrintEnabled,
            },
            'companyDurationOffsets': {
                '1': this.durationOffset,
            },
        });
        M.updateTextFields();
    }

    patchFormForLoad() {
        this.myForm.patchValue({
            'company': {
                'id': this.companyId,
                'name': this.companyName,
                // 'countryPrefix': this.countryPrefix,
                'phoneNumber': this.phoneNumber,
                'telephone': this.telephone,
                'email': this.email,
                'website': this.website,
                'tax_office': this.taxOffice,
                'registry_number': this.registryNumber,
                'tin': this.tin,
                'description': this.description,
            },
            'companySettings': {
                'company_id': this.companySettingsId,
                // 'default_departure_time': this.defaultDepartureTime,
                // 'default_project_problem_departure_datetime': this.defaultDepartureDatetime,
                // 'default_project_problem_departure_datetime_timezone': this.defaultDepartureTimezone,
                'durationSeconds': this.durationSeconds,
                'default_stop_point_duration': this.duration,
                'default_stop_point_delivery_load': this.defaultLoad,
                'default_stop_point_pickup_load': this.defaultLoad,
                // 'project_problem_horizon': this.defaultWorkingHours,
                // 'defaultWorkingHoursUnformatted': this.defaultWorkingHoursUnformatted,
                // 'defaultWorkingHoursMinutes': this.defaultWorkingHoursMinutes,
                'driver_overtime': this.driverOvertime,
                'companyTimeWindowCategoryId': this.selectedTimeWindowId,
                'reload_system_switch': this.reloadSystemSwitch,
                'reload_duration_at_intermediate_depot': this.reloadDurationAtIntermediateDepot,
                'reloadTimeMinutes': this.reloadTimeMinutes,
                'solver_sigma': this.solverSigma,
                'sigma': this.sigmaValue,
                // 'allow_late_stops': this.lateStops,
                'portal_access': this.portalAccessDefault,
                'portalOnDefault': this.portalOnDefault,
                'avoid_tolls': this.avoidTolls,
                'portalPlusDefault': this.portalPlusDefault,
                'is_qr_audit_enabled': this.auditEnabled,
                'invoice_notes': this.invoiceNotes,
                'is_auto_invoice_approval_enabled': this.isAutoInvoiceApprovalEnabled,
                'routing_optimize_mode': this.routingOptimizeMode
            },
            'companyVoucherSettings': {
                'is_single_voucher_print_enabled': this.isSingleVoucherPrintEnabled,
                'print_page_size': this.printPageSize,
                'print_voucher_order': this.printVoucherOrder,
            },
            'companyDurationOffsets': {
                'durationOffsetSeconds': this.durationOffsetSeconds,
                '1': this.durationOffset,
            },
        });
        M.updateTextFields();
        M.textareaAutoResize(document.getElementById('invoice-notes-settings-form'));
    }

    initializeSlider() {
        this.durationOptions = {
            step: 15,
            start: [120],
            connect: 'lower',
            range: {
                min: 0,
                max: 1800
            },
        };
        this.durationOffsetOptions = {
            step: 15,
            start: [0],
            connect: 'lower',
            range: {
                min: -300,
                max: 300
            },
        };
        // this.defaultWorkingHoursOptions = {
        //     step: 15,
        //     start: [480],
        //     connect: 'lower',
        //     range: {
        //         min: 60,
        //         max: 720
        //     },
        // };
        this.reloadTimeOptions = {
            step: 5,
            start: [30],
            connect: 'lower',
            range: {
                min: 15,
                max: 90
            },
        };
        this.sigmaOptions = {
            step: 0.1,
            start: [1.5],
            connect: 'lower',
            range: {
                min: 0.8,
                max: 1.5
            },
        };
        this.timeWindowOptions = {
            margin: 30, // how many minutes between start and stop
            step: 15,
            start: [480, 1200],
            connect: [false, true, false],
            range: {
                min: 0,
                max: 1439
            },
        };
        this.timeWindowOptionsDouble = {
            margin: 30, // how many minutes between start and stop
            step: 15,
            start: [480, 840, 1020, 1200],
            connect: [false, true, false, true, false],
            range: {
                min: 0,
                max: 1439
            },
        };
    }

    convertValuesToTime(index) {
        const self = this;
        const timeWindows = this.myForm.value.customer.timeWindows;
        let hours = 0, minutes = 0;
        if (self.doubleTimeWindow[index]) {
            timeWindows[index].timeWindowDouble.forEach(function (timeWindow, i) {
                hours = self.convertToHour(timeWindow);
                minutes = self.convertToMinute(timeWindow, hours);
                self.timeWindowUnformatted[index][i] = self.formatHoursAndMinutes(hours, minutes);
            });
        } else {
            timeWindows[index].timeWindowSingle.forEach(function (timeWindow, i) {
                hours = self.convertToHour(timeWindow);
                minutes = self.convertToMinute(timeWindow, hours);
                self.timeWindowUnformatted[index][i] = self.formatHoursAndMinutes(hours, minutes);
            });
        }
    }

    convertSecondsToTime() {
        let minutes = 0, seconds = 0;
        minutes = this.convertToHour(this.myForm.value.companySettings.durationSeconds);
        seconds = this.convertToMinute(this.myForm.value.companySettings.durationSeconds, minutes);
        this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
    }

    convertSecondsToTimeOffset() {
        let minutes = 0, seconds = 0;
        minutes = this.convertToHour(this.myForm.value.companyDurationOffsets.durationOffsetSeconds);
        seconds = this.convertToMinute(this.myForm.value.companyDurationOffsets.durationOffsetSeconds, minutes);
        this.durationOffsetUnformatted = this.formatHoursAndMinutes(minutes, seconds);
    }

    showReloadTimeMinutes() {
        this.reloadTimeUnformatted = this.myForm.value.companySettings.reloadTimeMinutes + this.minuteLabel;
    }

    showSigma() {
        this.sigmaUnformatted = this.myForm.value.companySettings.sigma;
    }

    convertToHour(value) {
        return Math.trunc(value / 60);
    }

    convertToMinute(value, hour) {
        return value - hour * 60;
    }

    formatHoursAndMinutes(hours, minutes) {
        let isNegative = false;
        if (hours < 0) {
            hours = Math.abs(hours);
            isNegative = true;
        }
        if (minutes < 0) {
            minutes = Math.abs(minutes);
            isNegative = true;
        }
        if (hours.toString().length === 1) { hours = '0' + hours; }
        if (minutes.toString().length === 1) { minutes = '0' + minutes; }
        return (isNegative ? '-' : '') + hours + ':' + minutes;
    }

    setTimeWindows(timeWindows) {
        const self = this;
        self.days.forEach(function (day, index) {

            if (timeWindows[day].length === 1) {
                self.doubleTimeWindow[index] = false;
            } else {
                self.doubleTimeWindow[index] = true;
            }

            let timeWindowDouble = [];
            let timeWindowRange, timeWindowRangeMinutes, timeWindowStartDatetime;
            let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
            let timeWindowStartUnformatted, timeWindowEndUnformatted;
            self.timeWindowUnformatted[index] = [];

            timeWindows[day].forEach(timeWindow => {
                timeWindowRange = timeWindow.time_window_range;
                timeWindowStartDatetime = timeWindow.time_window_start_datetime;
                timeWindowRange = timeWindow.time_window_range;
                timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
                timeWindowStart = moment(timeWindowStartDatetime).format();
                timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
                timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
                timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
                self.timeWindowUnformatted[index].push(timeWindowStartUnformatted);
                self.timeWindowUnformatted[index].push(timeWindowEndUnformatted);
                timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
                timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();

                if (timeWindows[day].length === 1) {
                    self.timeWindowSingle[index] = [timeWindowStartMinutes, timeWindowEndMinutes];
                    timeWindowDouble = self.timeWindowDoubleDefault;
                } else {
                    self.timeWindowSingle[index] = self.timeWindowSingleDefault;
                    timeWindowDouble.push(timeWindowStartMinutes);
                    timeWindowDouble.push(timeWindowEndMinutes);
                }

            });

            self.timeWindowDouble[index] = timeWindowDouble;

            self.timeWindowsData.push({
                timeWindowSingle: self.timeWindowSingle[index],
                timeWindowDouble: self.timeWindowDouble[index],
                isDouble: self.doubleTimeWindow[index],
            });

        });
    }

    timeWindowSelected() {
        if (this.myForm.value.customer.companyTimeWindow) {
            const timeWindows = this.myForm.value.customer.companyTimeWindow['CompanyTimeWindowCategory']['time_windows'];
            this.setTimeWindows(timeWindows);
        }
    }

    timeWindowCheckboxClicked(i) {
        if (this.doubleTimeWindow[i]) {
            this.doubleTimeWindow[i] = false;
            this.timeWindowUnformatted[i] = ['08:00', '20:00'];
        } else {
            this.doubleTimeWindow[i] = true;
            this.timeWindowUnformatted[i] = ['08:00', '14:00', '17:00', '20:00'];
        }
    }

    toggleTimeTable() {
        const timeTable = document.getElementById('time-window-sliders-container');
        if (timeTable.classList.contains('open')) {
            timeTable.classList.remove('open');
            this.listen.push(this.translate.get('STOP_POINT.OPEN_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));
        } else {
            timeTable.classList.add('open');
            this.listen.push(this.translate.get('STOP_POINT.CLOSE_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));
        }
    }

    inputFocusOut() {
        if (!this.myForm.value.stopPoint.address.term.timeZone && !this.myForm.value.stopPoint.address.term.customer) {
            if (this.ngSelect.itemsList['_filteredItems']) {
                const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
                if (firstItem) {
                    this.ngSelect.select(firstItem);
                }
            }
        }
    }

    inputAddress() {
        this.ngSelect.filter((<HTMLInputElement>document.getElementById('settings-custom-input')).value);
    }

    addDepot() {
        if (this.globals.depotsArray.length === 0) {
            this.settingsService.openDepotModal(null);
        } else if (this.globals.collaboratorModeEnabled && this.globals.foodModeEnabled) {
            this.settingsService.openDepotModal(null);
        } else {
            this.settingsService.openDepotModal(null);
        }
    }

    

    addPackagingField() {
        this.settingsService.openPackagingModal();
    }

    addDynamicField() {
        this.settingsService.openDynamicFieldModal();
    }

    setActiveListElements() {
        const page = document.getElementById('settings-page');
        const navbarHeight = document.getElementsByTagName('nav')[0].offsetHeight;
        const marginTop = Number(window.getComputedStyle(document.getElementById('routing-settings')).getPropertyValue('margin-top').replace('px', ''));
        document.querySelectorAll('#navigation-list li a').forEach(currentLink => {
            const refElement = document.getElementById(currentLink.getAttribute('ref'));
            if (
                refElement.offsetTop - navbarHeight - marginTop <= page.scrollTop &&
                (refElement.offsetTop + refElement.offsetHeight - navbarHeight) > page.scrollTop
            ) {
                currentLink.classList.add('active');
            } else {
                currentLink.classList.remove('active');
            }
        });
    }

    goToDiv(fragment: string): void {
        document.getElementById(fragment).scrollIntoView();
        window.scrollTo(0, 0);
    }

    public submitSettingsForm() {
        this.isClickedOnce = true;
        const companyFormValues = this.myForm.value.company;
        const companySettingsFormValues = this.myForm.value.companySettings;
        const companyDurationOffsetsFormValues = this.myForm.value.companyDurationOffsets;
        const companyVoucherSettings = this.myForm.value.companyVoucherSettings;

        if (companyFormValues.phoneNumber) {
            this.telephone = companyFormValues.countryPrefix + companyFormValues.phoneNumber;
        }

        // let defaultWorkingHoursMinutes;
        // if (Array.isArray(companySettingsFormValues.defaultWorkingHoursMinutes)) {
        //     defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes[0];
        // } else {
        //     defaultWorkingHoursMinutes = companySettingsFormValues.defaultWorkingHoursMinutes;
        // }
        // this.defaultWorkingHours = moment.duration(defaultWorkingHoursMinutes, 'minutes').toISOString();

        let durationSeconds;
        if (Array.isArray(companySettingsFormValues.durationSeconds)) {
            durationSeconds = companySettingsFormValues.durationSeconds[0];
        } else {
            durationSeconds = companySettingsFormValues.durationSeconds;
        }
        this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

        let durationOffsetSeconds;
        if (Array.isArray(companyDurationOffsetsFormValues.durationOffsetSeconds)) {
            durationOffsetSeconds = companyDurationOffsetsFormValues.durationOffsetSeconds[0];
        } else {
            durationOffsetSeconds = companyDurationOffsetsFormValues.durationOffsetSeconds;
        }
        this.durationOffset = moment.duration(durationOffsetSeconds, 'seconds').toISOString();

        let reloadTimeMinutes;
        if (Array.isArray(companySettingsFormValues.reloadTimeMinutes)) {
            reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes[0];
        } else {
            reloadTimeMinutes = companySettingsFormValues.reloadTimeMinutes;
        }
        this.reloadDurationAtIntermediateDepot = moment.duration(reloadTimeMinutes, 'minutes').toISOString();

        let sigma;
        if (Array.isArray(companySettingsFormValues.sigma)) {
            sigma = companySettingsFormValues.sigma[0];
        } else {
            sigma = companySettingsFormValues.sigma;
        }
        this.solverSigma = sigma;
        this.isSingleVoucherPrintEnabled = companyVoucherSettings.print_page_size === this.globals.printPageSizes['A6'];
        this.printVoucherOrder = companyVoucherSettings.print_voucher_order;

        // const departureTimeElement = document.querySelector('#departure-time-settings-form');
        // const departureTime = M.Timepicker.getInstance(departureTimeElement).time;
        // const today = moment().format('YYYY-MM-DD');
        // if (moment(departureTime, 'HH:mm', true).isValid()) {
        //     const departureDatetimeUnformatted = today + ' ' + departureTime;
        // this.defaultDepartureDatetime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format();
        // this.defaultDepartureTime = moment(departureDatetimeUnformatted, 'YYYY-MM-DD HH:mm').format('HH:mm');
        // this.defaultDepartureTimezone = moment.tz.guess();
        // }

        if (companySettingsFormValues.companyTimeWindow) {
            if (companySettingsFormValues.companyTimeWindow['CompanyTimeWindowCategory']) {
                this.selectedTimeWindowId = companySettingsFormValues.companyTimeWindow['CompanyTimeWindowCategory']['id'];
            }
        }

        if (companySettingsFormValues.portalOnDefault) {
            if (companySettingsFormValues.portalPlusDefault) {
                this.portalAccessDefault = this.globals.portalAccessConstants['ACCESS_WITH_HISTORY'];
            } else {
                this.portalAccessDefault = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
            }
        } else {
            this.portalAccessDefault = this.globals.portalAccessConstants['NO_ACCESS'];
        }

        this.patchForm();
        const myObserver = {
            next: (response) => {
                this.globals.getCompanyData();
            },
            error: (error) => {
                console.error(error);
                this.isClickedOnce = false;
            },
            complete: () => {
                this.isClickedOnce = false;
            },
        };
        this.http.put(this.companyUrl, this.myForm.value).subscribe(myObserver);
    }

    scrollFix() {
        window.scrollTo(0, 0);
    }

    getTranslations() {
        this.listen.push(this.translate.get('STOP_POINT.OPEN_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));

        this.listen.push(this.translate.get('GENERIC.SUNDAY_INITIAL').subscribe((res: string) => { this.dayLabels[0] = res; }));
        this.listen.push(this.translate.get('GENERIC.MONDAY_INITIAL').subscribe((res: string) => { this.dayLabels[1] = res; }));
        this.listen.push(this.translate.get('GENERIC.TUESDAY_INITIAL').subscribe((res: string) => { this.dayLabels[2] = res; }));
        this.listen.push(this.translate.get('GENERIC.WEDNESDAY_INITIAL').subscribe((res: string) => { this.dayLabels[3] = res; }));
        this.listen.push(this.translate.get('GENERIC.THURSDAY_INITIAL').subscribe((res: string) => { this.dayLabels[4] = res; }));
        this.listen.push(this.translate.get('GENERIC.FRIDAY_INITIAL').subscribe((res: string) => { this.dayLabels[5] = res; }));
        this.listen.push(this.translate.get('GENERIC.SATURDAY_INITIAL').subscribe((res: string) => { this.dayLabels[6] = res; }));

        this.listen.push(this.translate.get('SETTINGS').subscribe((res: string) => {

            this.printVoucherOrderOptions = [
                {
                    label: res['POSTAL_CODE'],
                    value: this.globals.printVoucherOrder['POSTAL_CODE']
                },
                {
                    label: res['CREATION_DATE_AND_TIME'],
                    value: this.globals.printVoucherOrder['CREATION_DATE_AND_TIME']
                },
                {
                    label: res['ORDER_ID'],
                    value: this.globals.printVoucherOrder['ORDER_ID']
                },
                {
                    label: res['RECEPIENT_NAME'],
                    value: this.globals.printVoucherOrder['RECEPIENT_NAME']
                },
            ];
         }));

        this.listen.push(this.translate.get('GENERIC.MINUTE_LABEL').subscribe((res: string) => { this.minuteLabel = res; }));
        this.listen.push(this.translate.get('ALERTS.ONE_DEPOT').subscribe((res: string) => { this.onlyOneDepotAlert = res; }));
    }

    ngOnInit() {
        const self = this;
        this.currentLang = this.translate.currentLang;
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        this.countryPrefix = this.globals.defaultCountryCode;
        this.myForm.patchValue({
            'company': {
                'countryPrefix': this.countryPrefix,
            }
        });

        this.printPageSizes = [
            {
                label: 'A4',
                value: this.globals.printPageSizes['A4']
            },
            {
                label: 'A6',
                value: this.globals.printPageSizes['A6']
            }
        ];

        this.days.forEach(day => {
            self.timeWindowDailyValues[day] = [];
            self.timeWindowSingle.push(self.timeWindowSingleDefault);
            self.timeWindowDouble.push(self.timeWindowDoubleDefault);
            self.timeWindowUnformatted.push(self.timeWindowUnformattedDefault.slice(0));
            self.doubleTimeWindow.push(false);
            self.myForm.controls['companySettings']['controls']['timeWindows'].push(self.formBuilder.group({
                timeWindowSingle: [self.timeWindowSingleDefault],
                timeWindowDouble: [self.timeWindowDoubleDefault],
                isDouble: [false],
            }));
            self.myForm.controls['companySettings']['controls']['recurringDays'].push(self.formBuilder.group({
                recurringEnabled: [false],
            }));
        });

        this.loadData();
        const elems = document.querySelectorAll('select');
        const instances = M.FormSelect.init(elems);
        const departureTimeElement = document.querySelectorAll('.timepicker');
        const timeInstances = M.Timepicker.init(departureTimeElement, {
            twelveHour: false
        });

        const timeWindowsDataRefreshIntervalId = setInterval(timeWindowsDataChecker, 200);
        function timeWindowsDataChecker() {
            if (self.globals.timeWindowsDataDone) {
                clearInterval(timeWindowsDataRefreshIntervalId);
                self.customTimeWindows = self.globals.customTimeWindows;
            }
        }

        document.getElementById('settings-page').addEventListener('scroll', this.setActiveListElements);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
