import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '@app/services/globals';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { Router } from '@angular/router';
import { GenericService } from '@app/services/generic.service';
import { StopPointsCCHandleDummyComponent } from './stop-points-cc-handle-modal-dummy/stop-points-cc-handle-modal-dummy.component';

@Component({
  selector: 'stop-points-cc-handle-modal',
  templateUrl: './stop-points-cc-handle-modal.component.html',
  styleUrls: ['./stop-points-cc-handle-modal.component.scss']
})
export class StopPointsCCHandleComponent implements OnInit, OnDestroy {
  @ViewChild(StopPointsCCHandleDummyComponent, { static: false }) stopPointsCCHandleDummyComponent: StopPointsCCHandleDummyComponent;

  listen = [];

  isModalOpen = false;

  convertToNextDayDelivery = false;

  constructor(
    public translate: TranslateService,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public router: Router,
    private genericService: GenericService,
  ) {
    this.listen.push(this.viewProjectProblemService.openStopPointsCCHandleModalListen().subscribe((mode) => {
      this.openCCHandleModal();
      setTimeout(() => {
        this.stopPointsCCHandleDummyComponent.stopPointsCCHandleGridComponent.getStopsGridData();
      }, 50);
    }));
  }

  emptyCCHandleModal() {
    this.stopPointsCCHandleDummyComponent.stopPointsCCHandleGridComponent.stopPointsDataArray = [];
    this.stopPointsCCHandleDummyComponent.stopPointsCCHandleGridComponent.resetGrid();
  }

  openCCHandleModal() {
    this.genericService.comm100ZIndexFix();
    this.isModalOpen = true;
    setTimeout(() => {
      const modal = document.querySelector('.stop-points-cc-handle-modal');
      const modalBackground = document.querySelector('.stop-points-cc-handle-modal-background');
      modal.classList.remove('closed');
      modal.classList.add('open');
      modalBackground.classList.remove('hidden');
    }, 50);

  }

  closeButtonPressed() {
    this.closeCCHandleModal();
  }

  closeCCHandleModal() {
    const modal = document.querySelector('.stop-points-cc-handle-modal');
    const modalBackground = document.querySelector('.stop-points-cc-handle-modal-background');
    modal.classList.add('closed');
    modal.classList.remove('open');
    modalBackground.classList.add('hidden');
    this.emptyCCHandleModal();

    setTimeout(() => {
      this.isModalOpen = false;
    }, 1000);
  }

  getTranslations() { }

  ngOnInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
