import { AfterViewInit, Component, ElementRef, Inject, Injector, OnDestroy, OnInit, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { SelectMapperService } from '@app/core/services/select-mapper.service';
import { LmDialogContext } from '@app/model/dialog';
import { LmButtonConfig } from '@app/shared/structure/button/button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { MapService } from '@app/services/map.service';
import { Observable, fromEvent, of } from 'rxjs';
import { ColourService } from '@app/services/colour.service';
import { ICountryModel } from '@app/model/country-model';
import { IAddressModel } from '@app/model/address-model';
import { LmSettingsDepotsViewModelService } from './settings-depots-modal-viewmodel.service';
import { IDepotDto } from '@app/model/depot-model';
import { LmModelProxyService } from '@app/core/services/model-proxy/model-proxy.service';
import { DepotsMapComponent } from '../settings-depots-map/settings-depots-map.component';
import { ISettings_TR_AreaDto } from '@app/api/models/settings-transportation-regions-dto';
import { hasValue, telephonator } from '@app/shared/utils';
import { FORM_VM_VALIDATOR, FormVmValidationFactory, IFormVmValidator } from '@app/core/services/form-validation.service';
import { LmAutoCompleteComponent } from '@app/shared/controls/input/auto-complete/auto-complete.component';
import { LmMultiSelectComponent } from '@app/shared/controls/input/multi-select/multi-select.component';
import { LmTextComponent } from '@app/shared/controls/input/text/text.component';

@UntilDestroy()
@Component({
  templateUrl: 'settings-depots-modal.component.html',
  styles:[`
    :host ::ng-deep{[class*='icon']:not(.p-dialog-header-close-icon){ color: #ccc; }}
    :host ::ng-deep lm-auto-complete .p-float-label{ margin-right: 5px; margin-left: 40px;}
    :host ::ng-deep .lm-double-control-first .lm-dropdown-control{ margin-left: 16px;}
    :host ::ng-deep .p-dialog .p-dialog-footer{position: relative}
    :host ::ng-deep .lm-map-instructions{position: absolute; left: 0; top: 4px; right: 0; bottom: auto; z-index:2}
    :host ::ng-deep .lm-map-instructions ._instruction{ font-size:0.8rem; padding: 6px 15px; background-color: #fff; color: #666; border-radius: 20px;}
    :host ::ng-deep .lm-map-instructions.__congrat{ top: 4px; bottom: auto; left: auto; right: 40px}
    :host ::ng-deep .lm-map-polygon-clear{ position: absolute; left: auto; top: 5px; right: 6px; width: 27px; height: 27px; border-radius: 30px; color: #fff; background-color: #000; z-index: 1; text-align: center; line-height: 200%;}
    :host ::ng-deep .lm-map-sub-description{font-size: .7rem; position: absolute; z-index: 1; top: auto; bottom: -7px; left: 0px; right: 0px; text-align: center; color: #999;}
  `],
  providers:[
    LmSettingsDepotsViewModelService, 
    LmModelProxyService,
    { provide: FORM_VM_VALIDATOR, useFactory: FormVmValidationFactory, deps: [Injector, Renderer2] }
  ]
})
export class SettingsDepotsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DepotsMapComponent) map: DepotsMapComponent;
  @ViewChild(LmAutoCompleteComponent) addressCtrl: LmAutoCompleteComponent;
  @ViewChild('nameCtrl') nameCtrl: LmTextComponent;
  saveBttn: LmButtonConfig;
  address: IAddressModel;
  phonePrefixes: ICountryModel[];
  company_depot_identification_number: string;
  item: IDepotDto;
  mi:number;
  dragged:boolean;
  congrat = false;
  polygonComplete = false;
  isOverloadedEnabled = false;

  onAddressSelect: (e) => void;
  handleName: (e) => void;
  onAreaSelect: (e) => Observable<ISettings_TR_AreaDto>;
  onAreaUnselect: (e, field) => void;
  testEmptyAddress: (...args) => void;
  testEmptyName: (...args) => void;


  constructor(
    public VM: LmSettingsDepotsViewModelService,
    public config: LmDialogContext<any>,
    public selectMapperSvc: SelectMapperService,
    private mapService: MapService,
    private colourService: ColourService,
    private _renderer: Renderer2,
    @Inject(FORM_VM_VALIDATOR) public FV: IFormVmValidator,
  )
  {
    this.saveBttn = {command: () => this.ok()};

    const {data:{globals, item}} = config;
    const {countryCodesNew} = globals;
    this.item = item;
        
    const tel = telephonator(this.item.telephone);
    this.phonePrefixes = countryCodesNew;
    this.item.countryPrefix = tel.countryPrefix;
    this.item.phoneNumber = tel.phoneNumber;

    this.isOverloadedEnabled = globals.isOverloadedEnabled

    this.onAddressSelect = (e) => { 
      if(e) {
        this.setAddress(e);
        this.map.showDraggableMarker(e.lat, e.lon);
        this.map.centerToMapElements();
      }
    };


    this.testEmptyAddress = (comp) => {
      setTimeout(_=> {
        const {innerValue, label} = comp;
        this.FV.testEmptyValue(innerValue.value, this.validateForm, label, this.FV.msgs.empty, comp);
      }, 500)
    };


    this.testEmptyName = (comp) => {
      setTimeout(_=> {
        const {innerValue, label} = comp;
        this.FV.testEmptyValue(innerValue, this.validateForm, label, this.FV.msgs.empty);
      }, 500)
    };


    this.handleName = (e) => {
      this.disableSaveAction((!Boolean(this.item.address.value) || !hasValue(e?.target?.value)))
    };

    if(!this.item.id) this.mi = 1;
  } 




  emptyMap(){
    this.map.emptyMap();
    this.mi = undefined;
  };


  clickMap(){
    if(!this.item.id && this.mi){
      if(this.dragged && !this.polygonComplete) {
        this.map.emptyDrawMode();
        this.mi = 1;
        this.dragged = false;
        return;
      }
      if(this.mi <= 5){
        this.mi++;
        if(this.polygonComplete) this.congrat = true;
      };
      if(this.congrat === true) setTimeout(_=> this.mi = undefined, 3000);
    }
    
  };


  private setAddress(e){
    this.item.address = {...(!e.value && {value: e.label}),...(!('id' in e) && {id: null}),...e};
    this.map.lat = e.lat;
    this.map.lon = e.lon;
    this.testEmptyAddress(this.addressCtrl);
  }


  // Should be implemented in map component, but it lacks needed reactive strategy design
  private onMapContextMenu$(el, e: string){
    return fromEvent(el, e).pipe(
      map((ev:any) => this.map.map.screenToGeo(ev.viewportX, ev.viewportY)),
      tap(coords => this.map.showDraggableMarker(coords.lat, coords.lng)),
      exhaustMap(coords => this.VM.getLocationFromMapCoords(coords.lat, coords.lng)),
      map((location:any) => location?.data?.addresses?.items[0]?.address),
      tap(address => (address && this.setAddress({...(address || {}), lat: this.VM.lat, lon: this.VM.lon})))
    )
  }

 
  private renderAdministrationMultipolygon() {
    this.map.emptyMap();
    const {name, depot_administration_multipolygon:{multipolygon}} = this.item;
    const colour = this.colourService.colourCalculator(0);
    const lineStrings = this.mapService.calculateMultiPolygons(multipolygon);

    lineStrings.forEach(lineString => this.map.createResizablePolygon(lineString, colour, name, true));
    this.map.centerToPolygon(null);
    this.map.drawModeEnabled = true;
  }


  private validateForm = () => {
    if(!Boolean(this.item.address.value) || !Boolean(this.item.name)) this.disableSaveAction(true); 
    else this.disableSaveAction(false);
  }


  private disableSaveAction = (decision) =>{
    this.saveBttn.disabled = decision;
  } 



  ngAfterViewInit(): void {
    setTimeout(_=> {
      const {id, address, depot_administration_multipolygon} = this.item;

      if(id && address) this.map.showDraggableMarker(address.lat, address.lon);
      if(depot_administration_multipolygon) this.renderAdministrationMultipolygon();

      this.onMapContextMenu$(this.map.map, 'contextmenu').pipe(untilDestroyed(this)).subscribe();
      this.map.polygonComplete.subscribe(complete => this.polygonComplete = complete);

      this.map.pinDropped.pipe(
          switchMap((coords:any) => this.VM.getLocationFromMapCoords(coords.lat, coords.lng)),
          map((location:any) => location?.data?.addresses?.items[0]?.address),
          tap(address => (address && this.onAddressSelect({...(address || {}), lat: this.VM.lat, lon: this.VM.lon}), false))
        )
        .subscribe()

        

        this.map.mapDragged.subscribe(res => this.dragged = res);
    }, 800);

  }


  ngOnInit(): void {
    setTimeout(() => this.validateForm())
  }
  ngOnDestroy(): void {}

  
  ok = () => {
    const polygon = this.map.getPolygon();
    if(polygon.length > 15) this.item.depot_administration_multipolygon = {multipolygon: polygon};
    this.item.address.label = this.item.name;

    if(this.item.id){
      return this.VM.put({companyDepot: this.item}, this.item.id)
        .pipe(take(1), untilDestroyed(this))
        .subscribe(_ => this.config.close(true));
    }
    else{
      return this.VM.post({companyDepot: this.item})
        .pipe(take(1), untilDestroyed(this))
        .subscribe(_ => this.config.close(true));
    }
  }

  cancel = () => this.config.close();
}
