import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlDirective, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ImageUtils } from '@app/utils/image-utils';
import Pickr from '@simonwep/pickr';
import { take } from 'rxjs';
import { extractInteger, hasValue, valueIsInteger } from '@app/shared/utils';
import { LmNotificationService } from '@app/core/services/notification.service';

@Component({
    selector: 'app-client-portal-settings',
    templateUrl: './client-portal-settings.component.html',
    styleUrls: ['./client-portal-settings.component.scss', '../../client-portal/client-portal.component.scss']
})
export class ClientPortalSettingsComponent implements OnInit, OnDestroy {

    companyUrl = 'api/v1/companies/' + this.globals.companyId;
    listen = [];
    myForm: FormGroup;

    portalAccess = 0;
    portalOn = false;

    companyId = null;
    isClickedOnce = false;
    balance = 0;
    notifyAmount = 0;
    viberPrice = 0;
    ratingEnabled = false;
    logoVisible = false;
    driverVisible = false;
    canCancel = false;
    canChangeDateTime = false;
    canPickupFromDepot = false;
    canChangeAddress = false;
    viberMessage = '';
    cancelMessage = '';
    thankYouMessage = '';
    minutesShortMsg = '';
    fbLink = '';
    googleLink = '';
    fbLinkEnabled = false;
    googleLinkEnabled = false;

    logoBase64 = '';
    imageHash;

    defaultViberMessage = '';
    defaultCancelMessage = '';
    contactForBalanceAlert = '';
    estimatedTime;

    imageTypeAlert = '';
    randomName = '';
    randomFirstName = '';
    liveTrackingImageBase64 = '';
    defaultThemeColor = '#00aeba'
    liveTrackingColorTheme = {
        color1: '',
        color2: '',
    };
    smsMessageText;
    viberMessageCharactersUsed: number;
    viberMessageHasUnicodeChars: boolean;
    viberMessageCharacterLimit: number;
    viberTotalMessagesNumber: number;

    firstColorPicker;
    secondColorPicker;
    firstColorText = '';
    secondColorText = '';

    deliveryTimeFrame: number;
    deliveryRescheduleDays: number;
    deliveryTimeStart: string;
    deliveryTimeEnd: string;
    deliveryDay1: boolean;
    deliveryDay2: boolean;
    deliveryDay3: boolean;
    deliveryDay4: boolean;
    deliveryDay5: boolean;
    deliveryDay6: boolean;
    deliveryDay7: boolean;
    deliveryTimeFrameTitle: string;
    deliveryTimeFrameMsg: string;
    deliveryDaysTitle: string;
    deliveryDaysMsg: string;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        // private settingsService: SettingsService,
        formBuilder: FormBuilder,
        public imageUtils: ImageUtils,
        private _notificationSvc: LmNotificationService
    ) {
        this.liveTrackingColorTheme.color1 = this.defaultThemeColor;
        this.liveTrackingColorTheme.color2 = this.defaultThemeColor;
        this.firstColorText = this.defaultThemeColor;
        this.secondColorText = this.defaultThemeColor;
        this.myForm = formBuilder.group({
            'company': formBuilder.group({}),
            'companySettings': formBuilder.group({
                'portal_access': [this.portalAccess],
                'portal_access_sw': [this.portalOn],
            }),
            'companyClientPortalSettings': formBuilder.group({
                'fb_link': this.fbLink,
                'google_link': this.googleLink,
                'is_fb_link_enabled': this.fbLinkEnabled,
                'is_google_link_enabled': this.googleLinkEnabled,
                'is_rating_enabled': this.ratingEnabled,
                'is_company_logo_visible': this.logoVisible,
                'is_driver_photo_and_name_visible': this.driverVisible,
                'is_client_cancel_enabled': this.canCancel,
                'is_client_change_datetime_enabled': this.canChangeDateTime,
                'is_client_pickup_from_depot_enabled': this.canPickupFromDepot,
                'is_client_change_address_enabled': this.canChangeAddress,
                'viber_message_template': this.viberMessage,
                'portal_message_template': this.cancelMessage,
                'thank_you_rating_message': this.thankYouMessage,
                'live_tracking_color_theme': this.liveTrackingColorTheme,
                'live_tracking_image_base64': this.liveTrackingImageBase64,
                'live_tracking_reschedule_options': formBuilder.group({
                    'delivery_time_frame': this.deliveryTimeFrame,
                    'delivery_reschedule_days': this.deliveryRescheduleDays,
                    'delivery_time_range': formBuilder.group({
                        'end': this.deliveryTimeEnd,
                        'start': this.deliveryTimeStart
                    }),
                    'delivery_days': formBuilder.group({
                        '1': this.deliveryDay1,
                        '2': this.deliveryDay2,
                        '3': this.deliveryDay3,
                        '4': this.deliveryDay4,
                        '5': this.deliveryDay5,
                        '6': this.deliveryDay6,
                        '7': this.deliveryDay7
                    })
                })
            }),
        });
    }


    handleViberMessage(e) {
        this.viberMessageHasUnicodeChars = this.checkStringCharEncoding(e);
        this.viberMessageCharactersUsed = this.messageLength(e);
        this.viberMessageCharacterLimit = this.viberMessageHasUnicodeChars ? 70 : 160;
        this.viberTotalMessagesNumber = this.provideTotalMessageNumber(this.viberMessageCharactersUsed, this.viberMessageCharacterLimit)
    }

    private checkStringCharEncoding = (str): boolean => /[^\u0000-\u00ff]/.test(str);
    private messageLength = (message): number => (message && !!message.length) ? message.length : 0;
    private provideTotalMessageNumber = (chars, limit): number => Math.ceil(chars / limit);

    addViberBalance() {
        alert(this.contactForBalanceAlert);
    }

    openViberNotifyModal() {

    }

    patchForm() {
        this.myForm.patchValue({
            'company': {},
            'companySettings': {
                'portal_access': this.portalAccess,
                'portal_access_sw': this.portalOn,
            },
            'companyClientPortalSettings': {
                'fb_link': this.fbLink,
                'google_link': this.googleLink,
                'is_fb_link_enabled': this.fbLinkEnabled,
                'is_google_link_enabled': this.googleLinkEnabled,
                'is_rating_enabled': this.ratingEnabled,
                'is_company_logo_visible': this.logoVisible,
                'is_driver_photo_and_name_visible': this.driverVisible,
                'is_client_cancel_enabled': this.canCancel,
                'is_client_change_datetime_enabled': this.canChangeDateTime,
                'is_client_pickup_from_depot_enabled': this.canPickupFromDepot,
                'is_client_change_address_enabled': this.canChangeAddress,
                'viber_message_template': this.viberMessage,
                'portal_message_template': this.cancelMessage,
                'thank_you_rating_message': this.thankYouMessage,
                'live_tracking_color_theme': this.liveTrackingColorTheme,
                'live_tracking_reschedule_options': {
                    'delivery_time_frame': this.deliveryTimeFrame,
                    'delivery_reschedule_days': this.deliveryRescheduleDays,
                    'delivery_time_range': {
                        'end': this.deliveryTimeEnd,
                        'start': this.deliveryTimeStart
                    },
                    'delivery_days': {
                        '1': this.deliveryDay1,
                        '2': this.deliveryDay2,
                        '3': this.deliveryDay3,
                        '4': this.deliveryDay4,
                        '5': this.deliveryDay5,
                        '6': this.deliveryDay6,
                        '7': this.deliveryDay7
                    }
                }
            },
        });
        M.updateTextFields();
    }

    patchLogo() {
        this.myForm.patchValue({
            'companyClientPortalSettings': {
                'is_company_logo_visible': this.logoVisible,
            },
        });
    }

    patchSenderLogo() {
        this.myForm.patchValue({
            'companyClientPortalSettings': {
                'live_tracking_image_base64': this.liveTrackingImageBase64,
            },
        });
    }

    patchTheme() {
        this.liveTrackingColorTheme.color1 = this.firstColorPicker.getColor().toHEXA().toString();
        this.liveTrackingColorTheme.color2 = this.secondColorPicker.getColor().toHEXA().toString();
        this.firstColorText = this.liveTrackingColorTheme.color1;
        this.secondColorText = this.liveTrackingColorTheme.color2;
        this.myForm.patchValue({
            'companyClientPortalSettings': {
                'live_tracking_color_theme': this.liveTrackingColorTheme,
            },
        });
    }

    getShipperLogo(hash: string) {
        this.http.get('api/v1/image-cloud?imageHashKey=' + hash).pipe(take(1)).subscribe(response => { });
    }

    public getFormData(data, portalAccess) {
        this.ratingEnabled = data.is_rating_enabled;
        this.fbLinkEnabled = data.is_fb_link_enabled;
        this.googleLinkEnabled = data.is_google_link_enabled;
        this.logoVisible = data.is_company_logo_visible;
        this.driverVisible = data.is_driver_photo_and_name_visible;
        this.canCancel = data.is_client_cancel_enabled;
        this.canChangeDateTime = data.is_client_change_datetime_enabled;
        this.canPickupFromDepot = data.is_client_pickup_from_depot_enabled;
        this.canChangeAddress = data.is_client_change_address_enabled;
        if (data.live_tracking_color_theme) {
            this.liveTrackingColorTheme = data.live_tracking_color_theme;
            this.firstColorText = this.liveTrackingColorTheme.color1;
            this.secondColorText = this.liveTrackingColorTheme.color2;
            setTimeout(() => {
                this.firstColorPicker.setColor(this.liveTrackingColorTheme.color1);
                this.secondColorPicker.setColor(this.liveTrackingColorTheme.color2);
            }, 100);

        }
        if (data.live_tracking_image_hash) {
            this.getShipperLogo(data.live_tracking_image_hash);
        }

        this.viberMessage = data.viber_message_template;
        this.cancelMessage = data.portal_message_template ? data.portal_message_template : '';
        this.thankYouMessage = data.thank_you_rating_message;
        this.fbLink = data.fb_link;
        this.googleLink = data.google_link;

        this.balance = parseFloat(Number(data.viber_balance).toFixed(2));
        this.viberPrice = data.viber_pay_amount;
        this.portalOn = portalAccess;

        const { live_tracking_reschedule_options: { delivery_days, delivery_time_frame, delivery_time_range: { start, end }, delivery_reschedule_days } } = data;

        this.deliveryTimeFrame = parseInt(delivery_time_frame.toString().replace(/\D/g, ""));
        this.deliveryRescheduleDays = extractInteger(delivery_reschedule_days);
        this.deliveryTimeEnd = end
        this.deliveryTimeStart = start
        this.deliveryDay1 = delivery_days['1'];
        this.deliveryDay2 = delivery_days['2'];
        this.deliveryDay3 = delivery_days['3'];
        this.deliveryDay4 = delivery_days['4'];
        this.deliveryDay5 = delivery_days['5'];
        this.deliveryDay6 = delivery_days['6'];
        this.deliveryDay7 = delivery_days['7'];

        this.resDeliveryDays = delivery_days;
        this.maxDeliveryTimeFrame = parseInt(end.split(':')[0]) - parseInt(start.split(':')[0]);
        this.maxDeliveryRescheduleDays = Object.values(delivery_days).filter(val => val === true).length;

        this.patchForm();
    }

    defaultDeliveryTimeFrame = 3;
    defaultDeliveryRescheduleDays = 5;
    defaultDeliveryStartTime = '08:00';
    defaultDeliveryEndTime = '18:00';

    resDeliveryDays: any;
    maxDeliveryTimeFrame: number;
    maxDeliveryRescheduleDays: number;

    handleDeliveryDay(e) {
        this.submitPortalSettingsForm();
    }

    handleDeliveryStartTime(val) {
        this.handleDeliveryTimeframe(this.defaultDeliveryTimeFrame);

        const start = val ? val : this.defaultDeliveryStartTime;
        this.myForm.patchValue({
            'companyClientPortalSettings': {
                'live_tracking_reschedule_options': {
                    'delivery_time_range': {
                        'start': start
                    }
                }
            },
        });
        this.submitPortalSettingsForm();
    }

    handleDeliveryEndTime(val) {
        this.handleDeliveryTimeframe(this.defaultDeliveryTimeFrame);

        const end = val ? val : this.defaultDeliveryEndTime;
        this.myForm.patchValue({
            'companyClientPortalSettings': {
                'live_tracking_reschedule_options': {
                    'delivery_time_range': {
                        'end': end
                    }
                }
            },
        });
        this.submitPortalSettingsForm();
    }

    handleDeliveryTimeframe(val) {
        setTimeout(() => {

            if (val.length === 0) {
                val = this.defaultDeliveryTimeFrame;
                this._notificationSvc.showWarning('Invalid timeframe', 'Timeframe must an be integer number.');
            }

            if (hasValue(val)) {
                if (val > this.maxDeliveryTimeFrame) {
                    val = this.defaultDeliveryTimeFrame;
                    this._notificationSvc.showWarning('Invalid timeframe', 'Timeframe duration cannot exceed provided delivery time range.');
                }
                if (val < 2) {
                    val = this.defaultDeliveryTimeFrame;
                    this._notificationSvc.showWarning('Invalid timeframe', 'Timeframe duration cannot be less than 2 hours.');
                }
            }
            this.myForm.patchValue({
                'companyClientPortalSettings': {
                    'live_tracking_reschedule_options': {
                        'delivery_time_frame': `PT${val}H`
                    }
                },
            });
            this.submitPortalSettingsForm();

        }, 300);
    }

    handleDeliveryRescheduleDays(val) {
        setTimeout(() => {
            if (val > 30) {
                val = this.defaultDeliveryRescheduleDays;
                this._notificationSvc.showWarning('Invalid reschedule days number', 'Reschedule days duration cannot exceed 30 days.');
            }
            else if (val < 1) {
                val = this.defaultDeliveryRescheduleDays;
                this._notificationSvc.showWarning('Invalid reschedule days number', 'Reschedule days duration cannot be less than 1 day.');
            }
            else {
                this.myForm.patchValue({
                    'companyClientPortalSettings': {
                        'live_tracking_reschedule_options': {
                            'delivery_days': val
                        }
                    }
                });
                this.submitPortalSettingsForm();
            }
        }, 300)
    }

    toggleClientPortal(event) {
        // if we disable general portal option, disable portal deafult as well
        if (event.target.checked) {
            this.portalAccess = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
        } else {
            this.portalAccess = this.globals.portalAccessConstants['NO_ACCESS'];
        }
        this.myForm.patchValue({
            'companySettings': {
                'portal_access': this.portalAccess,
            },
        });
        this.submitPortalSettingsForm();
    }

    toggleAllCancelling(event) {
        // if we disable general cancel option, disable other cancel options as well
        if (!event.target.checked) {
            this.canChangeDateTime = false;
            this.canChangeAddress = false;
            this.canPickupFromDepot = false;
            this.myForm.patchValue({
                'companyClientPortalSettings': {
                    'is_client_change_datetime_enabled': this.canChangeDateTime,
                    'is_client_pickup_from_depot_enabled': this.canPickupFromDepot,
                    'is_client_change_address_enabled': this.canChangeAddress,
                },
            });
        }
        this.submitPortalSettingsForm();
    }

    toggleCancel(event) {
        // if we enable a cancel option and the general cancel is disabled, enable the general cancel as well
        if (event.target.checked && !this.myForm.value.companyClientPortalSettings.is_client_cancel_enabled) {
            this.canCancel = true;
            this.myForm.patchValue({
                'companyClientPortalSettings': {
                    'is_client_cancel_enabled': this.canCancel,
                },
            });
        }
        this.submitPortalSettingsForm();
    }

    enableLogo() {
        if (this.myForm.value.companyClientPortalSettings.is_company_logo_visible) {
            if (this.logoBase64) {
                this.submitPortalSettingsForm();
            } else {
                this.logoVisible = false;
                this.patchLogo();
            }
        } else {
            this.submitPortalSettingsForm();
        }
    }

    // load the img in a canvas and then get the base64 from it to convert from jpeg
    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg|svg);base64,/, "");
    }

    uploadLogo(event) {
        // var file = document.querySelector('input[type=file]');
        var file = event.target;
        let reader = new FileReader();
        if (file['files']) {
            if (file['files'][0]) {
                if (!file['files'][0].name.match(/.(jpg|jpeg|png|svg)$/i)) {
                    alert(this.imageTypeAlert);
                } else {
                    reader.onload = () => {
                        const img = new Image();
                        img.onload = () => {
                            if (event.target.id === 'company-logo-file-input') {
                                this.logoBase64 = this.getBase64Image(img);
                                this.logoVisible = true;
                                this.patchLogo();
                                this.submitPortalSettingsForm(true);
                            } else if (event.target.id === 'sender-logo-file-input') {
                                this.liveTrackingImageBase64 = this.getBase64Image(img);
                                this.patchSenderLogo();
                                this.submitPortalSettingsForm(true);
                            }
                        };
                        img.src = String(reader.result);
                    }
                    reader.readAsDataURL(file['files'][0]);
                }
            }
        }
    }

    clickOnFirstColorPicker() {
        this.firstColorPicker.show();
    }

    clickOnSecondColorPicker() {
        this.secondColorPicker.show();
    }

    submitPortalSettingsForm(postLogo = false, cb?) {
        const data = this.myForm.value;
        if (postLogo && this.logoBase64) {
            data['companyLogo'] = {
                base64: this.logoBase64,
            }
        }
        const myObserver = {
            next: (response) => {
                this.globals.setCompanyData(response);
                this.getFormData(this.globals.portalSettings, this.globals.portalAccess);
                if (cb) cb()
            },
            error: (error) => {
                this.isClickedOnce = false;
            },
            complete: () => {
                this.isClickedOnce = false;
            },
        };
        this.http.put(this.companyUrl, data).subscribe(myObserver);
    }

    setColorPickers() {
        const colorPickerSettings: Pickr.Options = {
            el: '#first-color-picker',
            theme: 'monolith',
            default: this.defaultThemeColor,
            closeOnScroll: true,
            swatches: null,
            components: {
                // Main components
                preview: true,
                opacity: false,
                hue: true,

                // Input / output Options
                interaction: {
                    hex: false,
                    rgba: false,
                    hsla: false,
                    hsva: false,
                    cmyk: false,
                    input: true,
                    clear: false,
                    save: false
                }
            }
        };
        const secondPickerSettings = { ...colorPickerSettings };
        secondPickerSettings.el = "#second-color-picker"

        this.firstColorPicker = Pickr.create(colorPickerSettings);
        this.firstColorPicker.on('hide', instance => {
            this.firstColorPicker.applyColor()
            this.patchTheme();
            this.submitPortalSettingsForm();
        });
        this.secondColorPicker = Pickr.create(secondPickerSettings);
        this.secondColorPicker.on('hide', instance => {
            this.secondColorPicker.applyColor()
            this.patchTheme();
            this.submitPortalSettingsForm();
        });
    }

    getTranslations() {
        this.listen.push(this.translate.get('PORTAL_SETTINGS.CONTACT_FOR_BALANCE').subscribe((res: string) => { this.contactForBalanceAlert = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.DEFAULT_VIBER_MESSAGE').subscribe((res: string) => { this.defaultViberMessage = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.DEFAULT_CANCEL_MESSAGE').subscribe((res: string) => { this.defaultCancelMessage = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.IMAGE_TYPE_ALERT').subscribe((res: string) => { this.imageTypeAlert = res; }));
        this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => { this.minutesShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.RANDOM_NAME').subscribe((res: string) => { this.randomName = res; }));
        this.listen.push(this.translate.get('GENERIC.RANDOM_FIRST_NAME').subscribe((res: string) => { this.randomFirstName = res; }));
        this.listen.push(this.translate.get('CLIENT_PORTAL.ESTIMATED_TIME_IN').subscribe((res: string) => {
            this.estimatedTime = res;
        }));

        this.listen.push(this.translate.get('PORTAL_SETTINGS.RESCHEDULE_INVALID_TIMEFRAME_TITLE').subscribe((res: string) => { this.deliveryTimeFrameTitle = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.RESCHEDULE_INVALID_TIMEFRAME_MSG').subscribe((res: string) => { this.deliveryTimeFrameMsg = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.RESCHEDULE_INVALID_DAYS_TITLE').subscribe((res: string) => { this.deliveryDaysTitle = res; }));
        this.listen.push(this.translate.get('PORTAL_SETTINGS.RESCHEDULE_INVALID_DAYS_MSG').subscribe((res: string) => { this.deliveryDaysMsg = res; }));
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();
        this.setColorPickers();
        this.portalAccess = this.globals.defaultPortalAccess;
        this.getFormData(this.globals.portalSettings, this.globals.portalAccess);

    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.handleViberMessage(this.viberMessage);

            const deliveryTimeEls = document.querySelectorAll('.timepicker');
            const timeInstance = M.Timepicker.init(deliveryTimeEls, {
                twelveHour: false,

            });
        }, 1000);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
