import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AddressService } from '@app/services/address.service';
import { GenericService } from './generic.service';

export interface Vehicle {
  vehicle: {
    company_id: number;
    id: number;
    plate_number: string;
    vehicle_identification_number: number;
    vehicle_info_trim_id: number;
    vehicle_info_trim_selected_year: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  listen = [];

  vehicles: Observable<any>[] = new Array();
  vehiclesLoading = new Array();
  vehicleInputs: Subject<string>[] = new Array();
  driversVehicle = [];
  multipleDriversLabel = '';
  noNameLabel = '';
  noNameConstant = '_NO_NAME';
  statusPerStopPointMap = {};

  payOnDeliveryStatusesPerConstantMap = {};
  payOnDeliveryStatuses = [];

  constructor(
    private http: HttpClient,
    public globals: Globals,
    public translate: TranslateService,
    private addressService: AddressService,
    private genericService: GenericService,
  ) {
    this.getTranslations();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    });
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('DRIVER.MULTIPLE').subscribe((res: string) => { this.multipleDriversLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.payOnDeliveryStatusesPerConstantMap['NONE'] = {
        colorClass: 'status-unset',
        label: res['PAY_AMOUNT_NONE'],
        statusConstant: null,
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatusesPerConstantMap['CUSTOMER'] = {
        colorClass: 'status-unset',
        label: res['PAY_AMOUNT_UNPAID'],
        statusConstant: this.globals.paymentStatusConstants['CUSTOMER'],
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatusesPerConstantMap['DRIVER'] = {
        colorClass: 'status-on-going',
        label: res['PAY_AMOUNT_ON_DRIVER'],
        statusConstant: this.globals.paymentStatusConstants['DRIVER'],
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatusesPerConstantMap['COMPANY'] = {
        colorClass: 'status-on-going',
        label: res['PAY_AMOUNT_RECEIVED'],
        statusConstant: this.globals.paymentStatusConstants['COMPANY'],
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatusesPerConstantMap['COLLABORATOR'] = {
        colorClass: 'status-completed',
        label: res['PAY_AMOUNT_PAID'],
        statusConstant: this.globals.paymentStatusConstants['COLLABORATOR'],
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatusesPerConstantMap['IN_SMART_POINT'] = {
        colorClass: 'status-completed',
        label: res['PAY_AMOUNT_ON_SMART_POINT'],
        statusConstant: this.globals.paymentStatusConstants['IN_SMART_POINT'],
        payOnDeliveryValue: '-',
        payOnDeliveryAmount: 0,
      };
      this.payOnDeliveryStatuses = [
        this.payOnDeliveryStatusesPerConstantMap['CUSTOMER'],
        this.payOnDeliveryStatusesPerConstantMap['DRIVER'],
        this.payOnDeliveryStatusesPerConstantMap['COMPANY'],
        this.payOnDeliveryStatusesPerConstantMap['COLLABORATOR'],
        this.payOnDeliveryStatusesPerConstantMap['IN_SMART_POINT'],
      ];
    }));
  }

  // search through countries
  getCountries(term: string = null): Observable<any[]> {
    const params = `?searchQuery=${term}`;
    return this.http.get('api/v1/country-codes' + params).pipe(
      map(response => {
        return response['items'];
      })
    );
  }

  // get customers and collaborators in company
  getCustomersAndCollaborators(term: string = null, customerLabel: string, collaboratorLabel: string): Observable<any[]> {
    let collaboratorsUrl;
    if (this.globals.isInRoute('newShipmentsView')) {
      collaboratorsUrl = 'api/v1/partner-company-collaborators';
    } else {
      collaboratorsUrl = 'api/v1/company-collaborators';
    }

    let params = `?searchQuery=${term}&hasToIncludeCustomers=true&hasToIncludeInvisibleCollaborators=true&shouldFetchAllCollaborators=true`;

    return this.http.get(collaboratorsUrl + params).pipe(
      map(response => {
        const customerData = response['items']['customers'];
        const collaboratorData = response['items']['company_collaborators'];
        const data = [];
        for (let i = 0; i < collaboratorData.length; i++) {
          if (collaboratorData[i].companyCollaborator) {
            if (collaboratorData[i].companyCollaborator.collaborator) {
              if (collaboratorData[i].companyCollaborator.collaborator.collaboratorData) {
                collaboratorData[i]['label'] = collaboratorData[i]['companyCollaborator']['collaborator']['collaboratorData']['collaborator_name'] + ' (' + collaboratorLabel + ')';
                data.push(collaboratorData[i]);
              }
            }
          }
        }
        for (let i = 0; i < customerData.length; i++) {
          if (customerData[i].customer) {
            if (customerData[i]['customer']['contact_name'] === this.noNameConstant) {
              customerData[i]['customer']['contact_name'] = this.noNameLabel;
            }
            customerData[i]['label'] = customerData[i]['customer']['contact_name'] + ' (' + customerLabel + ')';
            data.push(customerData[i]);
          }
        }

        // update voucher form latest data
        this.genericService.storeLatestFromSearchInVoucher(data);

        return data;
      })
    );
  }

  // get all customers in company
  getCustomers(term: string = null): Observable<any[]> {
    const params = `?searchQuery=${term}`;
    return this.http.get('api/v1/customers' + params).pipe(
      map(response => {
        const data = response['items'];
        for (let i = 0; i < data.length; i++) {
          if (data[i].customer) {
            if (data[i]['customer']['contact_name'] === this.noNameConstant) {
              data[i]['customer']['contact_name'] = this.noNameLabel;
            }
          }
        }
        return data;
      })
    );
  }

  // get all vehicles in company and form their labels to show to the user in a dropdown
  getVehicles(term: string = null): Observable<any[]> {
    const params = `?searchQuery=${term}`;
    return this.http.get('api/internal/v2/vehicle/vehicle-list' + params).pipe(
      map(response => {
        const vehiclesArray = Array.isArray(response['items']) ? response['items'] : [];
        vehiclesArray.forEach(item => {
          let customLabel = item['vehicle']['plate_number'];
          if (item['drivers'].length) {
            if (item['drivers']) {
              if (item['drivers'].length > 1) {
                customLabel += ' - ' + this.multipleDriversLabel;
              } else {
                customLabel += ' - ' + item['drivers'][0].userProfile.name;
              }
            }
          } else if (item['userProfile']['name']) {
            item['customLabel'] += ' - ' + item['userProfile']['name'];
          }
          item['customLabel'] = customLabel;
        });
        return <Vehicle[]>vehiclesArray;
      })
    );
  }

  // get a car brands 
  getMakes(term: string = null): Observable<Vehicle[]> {
    const params = `?searchQuery=${term}`;
    return this.http.get('api/v1/vehicleInfo/makes' + params).pipe(
      map(response => {
        const items = [];
        response['vehicle_info_make']['items'].forEach(make => {
          items.push({
            id: make.vehicleInfoMake.id,
            name: make.vehicleInfoMake.name,
            mode: 'default',
          });
        });
        response['vehicle_info_make_company']['items'].forEach(make => {
          items.push({
            id: make.vehicleInfoMakeCompany.id,
            name: make.vehicleInfoMakeCompany.name,
            mode: 'company',
          });
        });
        items.push({
          id: null,
          name: term,
          mode: 'custom'
        });
        return items;
      })
    );
  }

  // get a car brand's models
  getModels(term: string = null, makeId, makeMode): Observable<Vehicle[]> {
    let makeIdText = 'make-id';
    if (makeMode === 'company') {
      makeIdText = 'make-company-id';
    }
    const params = `?searchQuery=${term}&${makeIdText}=${makeId}`;
    return this.http.get('api/v1/vehicleInfo/models' + params).pipe(
      map(response => {
        const items = [];
        if (response['vehicle_info_model']['items']) {
          response['vehicle_info_model']['items'].forEach(make => {
            items.push({
              id: make.vehicleInfoModel.id,
              name: make.vehicleInfoModel.name,
              mode: 'default',
              years: make.vehicleInfoModel.years
            });
          });
        }
        response['vehicle_info_model_company']['items'].forEach(make => {
          items.push({
            id: make.vehicleInfoModelCompany.id,
            name: make.vehicleInfoModelCompany.name,
            mode: 'company',
          });
        });
        items.push({
          id: null,
          name: term,
          mode: 'custom'
        });
        return items;
      })
    );
  }

  // get a car model's years of production 
  getYears(term: string = null, years): Observable<Vehicle[]> {
    return of(years).pipe(
      map(response => {
        const items = response;
        if (term.length === 4 && !items.includes(Number(term))) {
          items.push(Number(term));
        }
        const filteredItems = [];
        items.forEach(year => {
          if (year.includes(term)) {
            filteredItems.push(year);
          }
        });
        return filteredItems;
      })
    );
  }

  // get addresses and customers suggestions matched to a term and form their labels to show to the user in a dropdown
  getAddressesAndCustomers(term: string = null, id = null): Observable<any[]> {
    if (term !== '') {
      const self = this;
      let params;
      if (this.globals.currentLat && this.globals.currentLon) {
        params = `?searchQuery=${term}&type=0&at=${this.globals.currentLat},${this.globals.currentLon}`;
      } else {
        params = `?searchQuery=${term}&type=0&at=40.643,22.932`;
      }
      return this.http.get('api/v1/search/stopPoint/locations' + params).pipe(
        map(response => {
          let areaLabel = '';
          const data = response['data']['addresses']['items'];
          const customers = response['data']['customers']['items'];
          customers.forEach(element => {
            data.push(element);
          });
          for (let i = 0; i < data.length; i++) {
            if (data[i].customer) {
              if (data[i]['customer']['contact_name'] === self.noNameConstant) {
                data[i]['label'] = self.noNameLabel;
              } else {
                data[i]['label'] = data[i]['customer']['contact_name'];
              }
              if (data[i]['customer']['customer_identification_number']) {
                data[i]['label'] += ' - ' + data[i]['customer']['customer_identification_number']
              }
            } else if (data[i].title) {
              const addressLabel = this.addressService.getAddressLabel(data[i]['address']);
              data[i]['label'] = addressLabel;
            }
          }
          return data;
        })
      );
    } else {
      return of([]);
    }
  }

  // get addresses suggestions matched to a term and form their labels to show to the user in a dropdown
  getAddresses(term: string = ''): Observable<any[]> {
    if (!term) {
      term = ' ';
    }
    const params = `?searchQuery=${term}&type=0&at=40.643,22.932`;
    return this.http.get('api/v1/search/places' + params).pipe(
      map(response => {
        let data = response['items']['items'];
        for (let i = 0; i < data.length; i++) {
          let addressLabel = '';
          if (data[i]['address']) {
            addressLabel = this.addressService.getAddressLabel(data[i]['address']);
          } else if (data[i]['title'] && data[i]['vicinity']) {
            addressLabel = data[i]['title'] + ', ' + data[i]['vicinity'].replace('<br/>', ' ');
          }
          data[i]['label'] = addressLabel;

          // filtering for HERE's bad postalCode in response
          if (data[i]['address']['postalCode'] === " ") {
            data[i]['label'] = data[i]['address']['label'];
          }
        }

        return data;
      })
    );
  }

  // get addresses suggestions matched to a term and form their labels to show to the user in a dropdown
  getAddressesPostalCodes(term: string = ''): Observable<any[]> {
    if (!term) {
      term = ' ';
    }
    const params = `?searchQuery=${term}&type=0&at=40.643,22.932`;
    return this.http.get('api/v1/search/places' + params).pipe(
      map(response => {
        let data = response['items']['items'];
        let indexesToRemove = [];
        for (let i = 0; i < data.length; i++) {
          // add only entries that contain a postal code
          if (data[i]['address']['postalCode']) {
            data[i]['label'] = this.addressService.getPostalAddressLabel(data[i]['address']);
          } else {
            indexesToRemove.push(i);
          }
        }

        if (indexesToRemove.length) {
          indexesToRemove.forEach(indexToRemove => {
            data.splice(indexToRemove, 1);
          });
        }
        data = [...data];

        return data;
      })
    );
  }

  // this had &hasToIncludeInvisibleCollaborators=true
  // get addresses suggestions matched to a term and form their labels to show to the user in a dropdown
  getCollaborators(term: string = ''): Observable<any[]> {
    if (!term) {
      term = ' ';
    }

    let params = `?searchQuery=${term}&shouldFetchAllCollaborators=true`;

    return this.http.get('api/v1/company-collaborators' + params).pipe(
      map(response => {
        const data = response['items'];

        // update voucher form latest data
        this.genericService.storeLatestFromSearchInVoucher(data);

        return data;
      })
    );
  }
}
