import { Injectable, OnInit } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StopPointUtils } from './stop-point-utils';
import { FulfillmentUtils } from './fulfillment-event-utils';

@Injectable({
    providedIn: 'root'
})
export class GridUtils {
    listen = [];

    currency;

    // fulfillment status labels
    statusNotSpecifiedLabel;
    statusUnscheduledLabel;
    statusSmartPointLabel;
    statusSmartPointAbsentLabel;
    statusScheduledLabel;
    statusInProgressDeliverySoonLabel;
    statusScheduledDeliveryLabel;
    statusScheduledPickupLabel;
    statusInProgressPickupSoonLabel;
    statusLoadingLabel;
    statusInProgressLabel;
    statusInProgressDeliveryLabel;
    statusInProgressPickupLabel;
    statusArrivedLabel;
    statusCompletedLabel;
    statusCanceledLabel;
    statusDroppedLabel;
    statusHandedCompleted;
    statusPickupCompleted;
    statusPickupCompletedOverrideDelivery;
    statusHandedCanceled;
    descAgreedShipping;
    descAgreedShippingWrongAddress;
    descDriverDidNotAcceptIt;
    descDriverDidNotAcceptCharge;
    descDriverDidNotAcceptPOD;
    descDidNotAcceptIt;
    descNotThere;
    descPickUpFromDepot;
    descWrongAddress;
    descOtherReason;
    descParcelsNotReady;
    descNotAware;
    descNothingToSend;
    descUnknownReceiverRecipient;

    payAmountUnpaidLabel;
    payAmountReceivedLabel;
    payAmountPaidLabel;
    payAmountNoneLabel;

    overdueLabel;
    paidLabel;
    toPayLabel;
    toBeInvoicedLabel;

    constructor(
        public globals: Globals,
        public translate: TranslateService,
        private dataService: DataService,
        private stopPointUtils: StopPointUtils,
        private fulfillmentUtils: FulfillmentUtils,
    ) { }

    showFulfillmentBasedOnDeliveryStatus(stopPoint, allStopPointsObject, manualPickupPoint = null) {
        let fulfillmentObj;

        // find the stop point that is the delivery (pr set it manually)
        let stopPointDelivery, stopPointPickup;
        stopPointDelivery = stopPoint;
        if (!manualPickupPoint) {
            stopPointPickup = allStopPointsObject[stopPoint.related_stop_point_id];
        } else {
            stopPointPickup = manualPickupPoint;
        }

        // When delivery in those situations we want to display related (pickup) SP's reason
        let stopPointFulfillmentEventReasonsToNotShowDeliveryEvent = [
            this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']].AT_TIME,
            this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['LOADING']].AT_TIME,
            this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_DELIVERY
        ]

        // show delivery status if one of the following fulfillment events is found as the latest even of the delivery stop point
        // otherwise, show pickup fulfillment event
        let showDeliveryStatuses = false;
        if (!stopPointFulfillmentEventReasonsToNotShowDeliveryEvent.includes(stopPointDelivery.fulfillment_events[0].reason)) {
            showDeliveryStatuses = true;
        }

        // (disabledFeatures.oneLineShipments is used to toggle between this and the old logic)
        if (showDeliveryStatuses && !this.globals.disabledFeatures.oneLineShipments) {
            fulfillmentObj = this.getFulfillmentStatus(stopPointDelivery);
        } else {
            fulfillmentObj = this.getFulfillmentStatus(stopPointPickup);
        }

        return fulfillmentObj;
    }

    getFulfillmentStatus(stopPoint) {
        // refresh translations of labels (won't work in ngOnInit because file is not a component)
        this.getTranslations();

        // Voucher status
        let colorClass, statusValue;
        // no systemic status (normal fulfillment events)
        const latestEventCode = stopPoint.fulfillment_events[0].reason;
        switch (latestEventCode) {
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['NOT_SPECIFIED']].AT_TIME: // not specified
                statusValue = this.statusNotSpecifiedLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED']].AT_TIME: // unscheduled
                statusValue = this.statusUnscheduledLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.smartPointFulfillmentConstant: // smart point
                statusValue = this.statusSmartPointLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.pudoFulfillmentConstant: // pudo point
                statusValue = this.statusSmartPointLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.smartPointFulfillmentReasonsStopPoint['FULFILLMENT_REASON_INSERT_TO_PUDO_POINT_RECEIVER_ABSENT']: // pudo point absent
                statusValue = this.statusSmartPointAbsentLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME: // scheduled (legacy)
                statusValue = this.statusScheduledLabel;
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_PICKUP: // scheduled pickup
                statusValue = this.statusScheduledPickupLabel;
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['SCHEDULED']].AT_TIME_DELIVERY: // scheduled delivery
                statusValue = this.statusScheduledDeliveryLabel;
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['LOADING']].AT_TIME: // loading
                statusValue = this.statusLoadingLabel;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].AT_TIME: // in progress
                if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
                    statusValue = this.statusInProgressPickupLabel;
                } else {
                    statusValue = this.statusInProgressDeliveryLabel;
                }
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['IN_PROGRESS']].PENDING: // in progress soon
                if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
                    statusValue = this.statusInProgressPickupSoonLabel;
                } else {
                    statusValue = this.statusInProgressDeliverySoonLabel;
                }
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']].AT_TIME: // arrived
                statusValue = this.statusArrivedLabel;
                colorClass = 'status-on-going';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].AT_TIME: // completed
                if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
                    statusValue = this.statusPickupCompleted;
                } else {
                    statusValue = this.statusCompletedLabel;
                }
                colorClass = 'status-completed';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']].COMPLETED_HANDED: // completed handed
                if (stopPoint.service_type == this.globals.stopPointServiceTypeConstants['PICKUP']) {
                    statusValue = this.statusHandedCompleted;
                } else {
                    statusValue = this.statusCompletedLabel;
                }
                colorClass = 'status-completed';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM: // canceled: custom
                statusValue = `${this.statusCanceledLabel} (${this.descOtherReason})`;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE: // canceled: not there
                statusValue = this.descNotThere;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS: // canceled: wrong address
                statusValue = this.descWrongAddress;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT: // canceled: did not accept it
                statusValue = this.descDidNotAcceptIt;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT: // canceled: pickup from depot
                statusValue = this.descPickUpFromDepot;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING: // canceled: agreed shipping
                statusValue = this.descAgreedShipping;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS: // canceled: agreed shipping w/ wrong address
                statusValue = this.descAgreedShippingWrongAddress;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT: // canceled: agreed shipping w/ wrong address
                statusValue = this.descDriverDidNotAcceptIt;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']: // canceled: parcels not ready
                statusValue = this.descParcelsNotReady;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']: // canceled: parcels not ready
                statusValue = this.descDriverDidNotAcceptCharge;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']: // canceled: parcels not ready
                statusValue = this.descDriverDidNotAcceptPOD;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']: // canceled: parcels not ready
                statusValue = this.descNotAware;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']: // canceled: parcels not ready
                statusValue = this.descNothingToSend;
                colorClass = 'status-canceled';
                break;
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']: // canceled: parcels not ready
                statusValue = this.descUnknownReceiverRecipient;
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CANCELED_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS_HANDED: // cancel handed
            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DRIVER_DID_NOT_ACCEPT_IT_HANDED: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY_HANDED']: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE_HANDED']: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY_HANDED']: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE_HANDED']: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND_HANDED']: // cancel handed
            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT_HANDED']: // cancel handed
                // find the cancel reason
                let isCanceledReasonFound;
                stopPoint.fulfillment_events.forEach(event => {
                    if (String(event.reason).charAt(0) == String(this.globals.stopPointFulfillmentStatusConstants['CANCELED']) && !isCanceledReasonFound) {
                        isCanceledReasonFound = true;
                        switch (event.reason) {
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descAgreedShipping})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].AGREED_SHIPPING_WRONG_ADDRESS:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descAgreedShippingWrongAddress})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].CUSTOM:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descOtherReason})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].DID_NOT_ACCEPT_IT:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descDidNotAcceptIt})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].NOT_THERE:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descNotThere})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].PICKUP_FROM_DEPOT:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descPickUpFromDepot})`;
                                break;
                            case this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['CANCELED']].WRONG_ADDRESS:
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descWrongAddress})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_PARCELS_NOT_READY']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descParcelsNotReady})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_TRANSPORTATION_CHARGE']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descDriverDidNotAcceptCharge})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_DIDNT_ACCEPT_PAY_ON_DELIVERY']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descDriverDidNotAcceptPOD})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOT_AWARE']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descNotAware})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_NOTHING_TO_SEND']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descNothingToSend})`;
                                break;
                            case this.fulfillmentUtils.cancelReasonsObject['FULFILLMENT_REASON_CANCELED_UNKNOWN_SENDER_RECIPIENT']: // canceled: parcels not ready
                                statusValue = `${this.statusHandedCanceled} <br /> (${this.descUnknownReceiverRecipient})`;
                                break;
                            default:
                                statusValue = 'CANCEL CASE ERROR';
                                break;
                        }
                    }
                });

                if (!isCanceledReasonFound) { statusValue = this.statusHandedCanceled; }
                colorClass = 'status-canceled';
                break;
            case this.globals.stopPointFulfillmentEventConstants[9].AT_TIME: // dropped
                statusValue = this.statusDroppedLabel;
                colorClass = 'status-canceled';
                break;
            default:
                statusValue = 'CASE ERROR';
                colorClass = 'status-canceled';
        }

        // systemic status (company-specific fulfillment events -> keeps color classes & alters only label) (overrides the current statusValue)
        statusValue = stopPoint.fulfillment_events[0].systemic_status ?? statusValue;

        return { statusValue: statusValue, colorClass: colorClass };
    }

    getPayAmountStatus(stopPoint) {
        let payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['NONE'];
        if (Number(stopPoint.pay_amount)) {
            const payOnDeliveryValue = Number(stopPoint.pay_amount).toFixed(2) + this.currency;

            if (!isNaN(stopPoint.payment_status)) {
                switch (stopPoint.payment_status) {
                    case this.globals.paymentStatusConstants['CUSTOMER']:
                        payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['CUSTOMER'];
                        break;
                    case this.globals.paymentStatusConstants['DRIVER']:
                        payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['DRIVER'];
                        break;
                    case this.globals.paymentStatusConstants['COMPANY']:
                        payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['COMPANY'];
                        break;
                    case this.globals.paymentStatusConstants['COLLABORATOR']:
                        payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['COLLABORATOR'];
                        break;
                    case this.globals.paymentStatusConstants['IN_SMART_POINT']:
                        payOnDeliveryObject = this.dataService.payOnDeliveryStatusesPerConstantMap['IN_SMART_POINT'];
                        break;
                    default:
                        break;
                }
            }
            payOnDeliveryObject.payOnDeliveryValue = payOnDeliveryValue;
            payOnDeliveryObject.payOnDeliveryAmount = stopPoint.pay_amount;
        }

        return { ...payOnDeliveryObject };
    }

    getPaymentStatus(stopPoint) {
        let paymentStatusValue = '0' + this.currency;
        let chargeClass = 'status-unset';
        let chargeLabel = this.toBeInvoicedLabel;
        const voucherData = stopPoint.voucher;
        if (voucherData && voucherData.pay_price) {
            if (voucherData.paid_by == this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']) {
                if (this.stopPointUtils.getConsignorCollaborator(voucherData.company_voucher_collaborators)) {
                    paymentStatusValue = Number(voucherData.pay_price).toFixed(2) + this.currency;
                }
            } else {
                paymentStatusValue = Number(voucherData.pay_price).toFixed(2) + this.currency;
            }
        }

        if (voucherData && voucherData.invoice_id) {
            chargeClass = 'status-on-going';
            chargeLabel = this.toPayLabel;
            if (voucherData.invoice) {
                if (voucherData.invoice.status) {
                    if (voucherData.invoice.status === this.globals.invoiceStatusConstants['PAID']) {
                        // if paid
                        chargeClass = 'status-completed';
                        chargeLabel = this.paidLabel;
                    } else if (voucherData.invoice.status === this.globals.invoiceStatusConstants['OVERDUE']) {
                        // if expired
                        chargeClass = 'status-canceled';
                        chargeLabel = this.overdueLabel;
                    }
                }
            }
        }

        if (this.globals.accessRole == this.globals.teamMemberTypeConstants['STANDARD']) {
            chargeClass = 'status-unset';
            chargeLabel = '-';
            paymentStatusValue = '';
        }

        return {
            colorClass: chargeClass,
            label: chargeLabel,
            paymentStatusValue: paymentStatusValue
        };
    }

    // get a map with all PoD objects with amount and status for grids to use
    getStatusPerStopPointMap(stopPoints) {
        const statusPerStopPointMap = {};
        stopPoints.forEach(stopPoint => {
            if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
            statusPerStopPointMap[stopPoint.id] = this.getPayAmountStatus(stopPoint);
        });
        return statusPerStopPointMap;
    }

    // call this with the column click event and the extra SP rows selected to get the array of SPs for the put request to update the SP PoD status
    getStopPointsDataToSavePayOnDeliveryStatus(event, selectedRows) {
        let stopPointsToUpdate = [];
        const payOnDeliveryStatus = event.newValue['statusConstant'];
        const stopPointId = event.data.id;
        const projectProblemId = event.data.projectProblemId;
        let stopPointObj = {
            stopPoint: {
                project_problem_id: projectProblemId,
                stopPointId: stopPointId,
                payment_status: payOnDeliveryStatus
            }
        };
        stopPointsToUpdate.push(stopPointObj);

        selectedRows.forEach(row => {
            const payOnDeliveryData = row.payOnDelivery;
            if (Number(payOnDeliveryData.payOnDeliveryAmount) > 0 && row.id !== stopPointId) {
                const stopPointId = row.id;
                const projectProblemId = row.projectProblemId;
                stopPointObj = {
                    stopPoint: {
                        project_problem_id: projectProblemId,
                        stopPointId: stopPointId,
                        payment_status: payOnDeliveryStatus
                    }
                };
                stopPointsToUpdate.push(stopPointObj);
            }
        });
        return stopPointsToUpdate;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.CURRENCY').subscribe((res: string) => { this.currency = res; }));

        // fulfillment events translations
        this.listen.push(this.translate.get('STATUS.NOT_SPECIFIED').subscribe((res: string) => { this.statusNotSpecifiedLabel = res; }));
        this.listen.push(this.translate.get('STATUS.UN_SCHEDULED').subscribe((res: string) => { this.statusUnscheduledLabel = res; }));
        this.listen.push(this.translate.get('CANCEL.SMART_POINT_ACCEPT_IT').subscribe((res: string) => { this.statusSmartPointLabel = res }));
        this.listen.push(this.translate.get('CANCEL.SMART_POINT_ABSENT').subscribe((res: string) => { this.statusSmartPointAbsentLabel = res }));
        this.listen.push(this.translate.get('STATUS.SCHEDULED').subscribe((res: string) => { this.statusScheduledLabel = res; }));
        this.listen.push(this.translate.get('STATUS.SCHEDULED_DELIVERY').subscribe((res: string) => { this.statusScheduledDeliveryLabel = res; }));
        this.listen.push(this.translate.get('STATUS.SCHEDULED_PICKUP').subscribe((res: string) => { this.statusScheduledPickupLabel = res; }));
        this.listen.push(this.translate.get('STATUS.LOADING').subscribe((res: string) => { this.statusLoadingLabel = res; }));
        this.listen.push(this.translate.get('STATUS.IN_PROGRESS').subscribe((res: string) => { this.statusInProgressLabel = res; }));
        this.listen.push(this.translate.get('STATUS.IN_PROGRESS_DELIVERY').subscribe((res: string) => { this.statusInProgressDeliveryLabel = res; }));
        this.listen.push(this.translate.get('STATUS.IN_PROGRESS_PICKUP').subscribe((res: string) => { this.statusInProgressPickupLabel = res; }));
        this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_DELIVERY').subscribe((res: string) => { this.statusInProgressDeliverySoonLabel = res; }));
        this.listen.push(this.translate.get('STATUS.IN_PROGRESS_SOON_PICKUP').subscribe((res: string) => { this.statusInProgressPickupSoonLabel = res; }));
        this.listen.push(this.translate.get('STATUS.ARRIVED').subscribe((res: string) => { this.statusArrivedLabel = res; }));
        this.listen.push(this.translate.get('STATUS.COMPLETED').subscribe((res: string) => { this.statusCompletedLabel = res; }));
        this.listen.push(this.translate.get('STATUS.CANCELED').subscribe((res: string) => { this.statusCanceledLabel = res; }));
        this.listen.push(this.translate.get('STATUS.DROPPED').subscribe((res: string) => { this.statusDroppedLabel = res; }));
        this.listen.push(this.translate.get('STATUS.HANDED_COMPLETED').subscribe((res: string) => { this.statusHandedCompleted = res; }));
        this.listen.push(this.translate.get('STATUS.COMPLETED_PICKUP').subscribe((res: string) => { this.statusPickupCompleted = res; }));
        this.listen.push(this.translate.get('STATUS.STATUS_COMPLETED_PICKUP_OVERRIDE_DELIVERY').subscribe((res: string) => { this.statusPickupCompletedOverrideDelivery = res; }));
        this.listen.push(this.translate.get('STATUS.HANDED_CANCELED').subscribe((res: string) => { this.statusHandedCanceled = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_AGREED_SHIPPING_MSG').subscribe((res: string) => { this.descAgreedShipping = res; }));
        this.listen.push(this.translate.get('CANCEL.AGREED_SHIPPING_WRONG_ADDRESS_MSG').subscribe((res: string) => { this.descAgreedShippingWrongAddress = res; }));
        this.listen.push(this.translate.get('CANCEL.DRIVER_NOT_ACCEPT').subscribe((res: string) => { this.descDriverDidNotAcceptIt = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_DID_NOT_ACCEPT_POD').subscribe((res: string) => { this.descDriverDidNotAcceptPOD = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_DID_NOT_ACCEPT_CHARGE').subscribe((res: string) => { this.descDriverDidNotAcceptCharge = res; }));
        this.listen.push(this.translate.get('CANCEL.PARCELS_NOT_READY').subscribe((res: string) => { this.descParcelsNotReady = res; }));
        this.listen.push(this.translate.get('CANCEL.NOT_AWARE').subscribe((res: string) => { this.descNotAware = res; }));
        this.listen.push(this.translate.get('CANCEL.NOTHING_TO_SEND').subscribe((res: string) => { this.descNothingToSend = res; }));
        this.listen.push(this.translate.get('CANCEL.UNKNOWN_SENDER_RECIPIENT').subscribe((res: string) => { this.descUnknownReceiverRecipient = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_DID_NOT_ACCEPT_MSG').subscribe((res: string) => { this.descDidNotAcceptIt = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_NOT_THERE_MSG').subscribe((res: string) => { this.descNotThere = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_PICKUP_FROM_DEPOT_MSG').subscribe((res: string) => { this.descPickUpFromDepot = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_WRONG_ADDRESS_MSG').subscribe((res: string) => { this.descWrongAddress = res; }));
        this.listen.push(this.translate.get('CANCEL.CANCEL_OTHER_REASON').subscribe((res: string) => { this.descOtherReason = res; }));

        this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_UNPAID').subscribe((res: string) => { this.payAmountUnpaidLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_RECEIVED').subscribe((res: string) => { this.payAmountReceivedLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_PAID').subscribe((res: string) => { this.payAmountPaidLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.PAY_AMOUNT_NONE').subscribe((res: string) => { this.payAmountNoneLabel = res; }));

        this.listen.push(this.translate.get('CUSTOMER_INVOICES.OVERDUE').subscribe((res: string) => { this.overdueLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.PAID').subscribe((res: string) => { this.paidLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_PAY').subscribe((res: string) => { this.toPayLabel = res; }));
        this.listen.push(this.translate.get('CUSTOMER_VOUCHERS.TO_BE_INVOICED').subscribe((res: string) => { this.toBeInvoicedLabel = res; }));
    }
}
