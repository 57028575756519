// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#order-period {
  width: 19vw;
}

#drivers-grid-container {
  position: relative;
}

.drivers-count-container {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 300px;
  height: 50px;
  z-index: 1;
  -webkit-user-select: none;
          user-select: none;
}
.drivers-count-container p {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRyaXZlcnMtZ3JpZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFdBQUE7QUFDSjs7QUFFQTtFQUNJLGtCQUFBO0FBQ0o7O0FBR0E7RUFDSSxrQkFBQTtFQUNBLFVBQUE7RUFDQSxTQUFBO0VBQ0EsWUFBQTtFQUNBLFlBQUE7RUFDQSxVQUFBO0VBQ0EseUJBQUE7VUFBQSxpQkFBQTtBQUFKO0FBRUk7RUFDSSxnQkFBQTtFQUNBLHVCQUFBO0VBQ0EsbUJBQUE7RUFDQSxnQkFBQTtBQUFSIiwiZmlsZSI6ImRyaXZlcnMtZ3JpZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIiNvcmRlci1wZXJpb2Qge1xuICAgIHdpZHRoOiAxOXZ3O1xufVxuXG4jZHJpdmVycy1ncmlkLWNvbnRhaW5lciB7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xufVxuXG4vLyBzdG9wIHBvaW50cyBjb3VudFxuLmRyaXZlcnMtY291bnQtY29udGFpbmVyIHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgbGVmdDogMTBweDtcbiAgICB0b3A6IDEwcHg7XG4gICAgd2lkdGg6IDMwMHB4O1xuICAgIGhlaWdodDogNTBweDtcbiAgICB6LWluZGV4OiAxO1xuICAgIHVzZXItc2VsZWN0OiBub25lO1xuXG4gICAgcCB7XG4gICAgICAgIHRleHQtYWxpZ246IGxlZnQ7XG4gICAgICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgICAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgICAgICBvdmVyZmxvdzogaGlkZGVuO1xuICAgIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/grids/drivers-grid/drivers-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAGA;EACI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;UAAA,iBAAA;AAAJ;AAEI;EACI,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAR;AAEA,4hCAA4hC","sourcesContent":["#order-period {\n    width: 19vw;\n}\n\n#drivers-grid-container {\n    position: relative;\n}\n\n// stop points count\n.drivers-count-container {\n    position: absolute;\n    left: 10px;\n    top: 10px;\n    width: 300px;\n    height: 50px;\n    z-index: 1;\n    user-select: none;\n\n    p {\n        text-align: left;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n        overflow: hidden;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
