import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { ModalGridService } from '@app/services/modal-grid.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { AddressService } from '@app/services/address.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { ModalService } from '@app/services/modal.service';
import * as libphonenumber from 'libphonenumber-js';

declare var H: any;

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, AfterViewInit {
  @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
  @Output() dataLoading = new EventEmitter<string>();
  @Output() updateFormValidity = new EventEmitter<string>();

  listen = [];

  vehicleTypes = [];
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  dayLabels = [];

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();
  selectedAddress: any = <any>[];

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName;

  @ViewChild('ngSelect', { static: false }) ngSelect: NgSelectComponent;
  private platform: any;
  @ViewChild('map', { static: false }) public mapElement: ElementRef;

  isFormValid = false;

  map;
  ui;
  behavior;
  rightClickOnMap;
  draggableMarkerGroup = new H.map.Group();
  draggableMarker = new H.map.Marker({ lat: 40.643, lng: 22.932 });
  positionSet = false;

  myForm: FormGroup;
  emptyFormValues;
  data;
  customerId = null;
  name: String = '';
  companyName: String = '';
  email: String = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  companyId = null;
  identificationNumber = '';
  status = 1;
  isCompany = false;
  entityType = 1;
  deliveryLoad = null;
  pickupLoad = null;
  serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
  selectedServiceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
  durationUnformatted = '02:00';
  durationSeconds = [120];
  duration = '';
  customTimeWindows = [];
  doubleTimeWindow = [];
  selectedTimeWindowId = null;
  timeWindowSingleDefault = [480, 1200];
  timeWindowDoubleDefault = [480, 840, 1020, 1200];
  timeWindowsData = [];
  timeWindowSingle = [];
  timeWindowDouble = [];
  timeWindowDailyValues = {};
  timeWindowUnformattedDefault = ['08:00', '20:00'];
  timeWindowUnformatted = [];
  timeWindowOptions;
  timeWindowOptionsDouble;
  toggleTimeTableText;
  defaultTimezone = moment.tz.guess();
  address: String = '';
  addressType: String = 'houseNumber';
  country = 'GR';
  freeformAddress = '';
  lat: String = '';
  lon: String = '';
  note = '';
  portalAccess = 0;
  portalOn = false;
  portalPlus = false;
  isClickedOnce = false;
  durationOptions;
  selectedTimeWindow;
  recurringEnabled = [];
  recurringDays = [];
  reappear = {};
  priority = '';
  priorityOn = false;

  vehicles = [];
  vehicleLoading = false;
  vehicleInput = new Subject<string>();
  multipleDriversLabel;
  vehicleIds = [];

  portalSettingsConfirmMsg;
  demoDisableRequestMsg;
  noNameLabel = '';
  noNameConstant = '_NO_NAME';

  repeatFrequencyAmount;
  repeatFrequencyType;
  isWeekendEnabled;
  repeatFrequencyDate;
  repeatFrequencyTimezone;

  daysLabel;
  weeksLabel;
  frequencyTypeItems = [];

  isRepetitionEnabled;
  repeatMode; // only used to make the radio button choices unique (is set depending on recurringMode & weeklyMode)
  recurringMode; // 1 = weekly, 2 = manual

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public globals: Globals,
    private dataService: DataService,
    private modalGridService: ModalGridService,
    private modalService: ModalService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private addressService: AddressService,
    private dateTimeCalculatorService: DateTimeCalculatorService,
  ) {
    this.initializeSlider();
    this.platform = this.globals.platform;
    this.myForm = formBuilder.group({
      'customer': formBuilder.group({
        'repetition_enabled': [this.isRepetitionEnabled],
        'repeat_mode': [this.repeatMode],
        'recurring_mode': [this.recurringMode],
        'customer_manual_reappear': formBuilder.group({
          'frequency_amount': [this.repeatFrequencyAmount],
          'frequency_type': [this.repeatFrequencyType],
          'is_weekend_enabled': [this.isWeekendEnabled],
          'start_datetime': [this.repeatFrequencyDate],
          'start_time_zone': [this.repeatFrequencyTimezone],
        }),
        'timeWindows': formBuilder.array([]),
        'recurringDays': formBuilder.array([]),
        'reappear': [this.reappear],
        'time_windows': [this.timeWindowDailyValues],
        'companyTimeWindow': [this.selectedTimeWindow],
        'companyTimeWindowCategoryId': [this.selectedTimeWindowId],
        'customer_identification_number': [this.identificationNumber],
        'id': [this.customerId],
        'contact_name': [this.name, Validators.required],
        'company_id': [this.companyId],
        'company_name': [this.companyName],
        'email': [this.email],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone, Validators.required],
        'duration_seconds': [this.durationSeconds],
        'stop_point_default_duration': [this.duration],
        'entity_status': [this.status],
        'entity_type': [this.entityType],
        'stop_point_default_delivery_load': [this.deliveryLoad, Validators.required],
        'stop_point_default_pickup_load': [this.pickupLoad, Validators.required],
        'stop_point_default_service_type': [this.serviceType],
        'selectedServiceType': [this.selectedServiceType],
        'note': [this.note],
        'portal_access': [this.portalAccess],
        'portalOn': [this.portalOn],
        'portalPlus': [this.portalPlus],
        'vehicle_ids': [this.vehicleIds],
        'priority': [this.priority],
        'priorityOn': [this.priorityOn],
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress],
          'lat': [this.lat],
          'lon': [this.lon],
          'term': [this.selectedAddress],
        }),
      }),
    });
    this.emptyFormValues = this.myForm.value;

    this.listen.push(this.modalService.updateCustomerDateListen().subscribe((date) => {
      this.repeatFrequencyDate = date;
      this.repeatFrequencyTimezone = this.defaultTimezone;

      this.myForm.patchValue({
        'customer': {
          'customer_manual_reappear': {
            'start_datetime': this.repeatFrequencyDate,
            'start_time_zone': this.repeatFrequencyTimezone
          }
        }
      });

      M.updateTextFields();
    }));
  }

  checkFormValidity() {
    this.isFormValid = false;

    const isPhoneValid = this.myForm.value.customer.phoneNumber ? true : false;
    const isNameValid = this.myForm.value.customer.contact_name ? true : false;
    const isDeliveryLoadValid = this.myForm.value.customer.stop_point_default_delivery_load ? true : false;
    const isAddressValid = this.myForm.value.customer.address.value ? true : false;
    const isPickupLoadValid = this.myForm.value.customer.stop_point_default_pickup_load ? true : false;

    if (this.selectedServiceType === this.globals.stopPointServiceTypeConstants['DELIVERY'] && isPhoneValid && isNameValid && isDeliveryLoadValid && isAddressValid) {
      this.isFormValid = true;
    } else if (this.selectedServiceType === this.globals.stopPointServiceTypeConstants['PICKUP'] && isPhoneValid && isNameValid && isPickupLoadValid && isAddressValid) {
      this.isFormValid = true;
    }

    this.updateFormValidity.emit(String(this.isFormValid));
  }

  changeFrequencyType() {
    this.repeatFrequencyType = this.myForm.value.customer.customer_manual_reappear.frequency_type;
  }

  public getCustomerData(id) {
    this.isClickedOnce = true;
    this.http.get('api/v1/customers/' + id).pipe(take(1)).subscribe(response => {
      this.isClickedOnce = false;
      this.positionSet = true;
      this.selectedTimeWindow = {};
      this.data = response['item'];
      const customer = this.data.customer;
      // loadData
      this.setTimeWindows(customer.time_windows);
      this.customerId = customer.id;
      this.name = customer.contact_name;
      if (this.name === this.noNameConstant) {
        this.name = this.noNameLabel;
      }
      this.companyName = customer.company_name;
      this.email = customer.email;

      if (customer.telephone === 'n/a') { customer.telephone = ''; }
      if (customer.telephone) {
        if (customer.telephone.length > 5) {
          const phoneObj = libphonenumber.parsePhoneNumber(customer.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        }
      }

      this.identificationNumber = customer.customer_identification_number;
      this.companyId = customer.company_id;
      this.status = customer.entity_status;
      this.entityType = customer.entity_type;
      this.duration = customer.stop_point_default_duration;
      this.durationSeconds = [moment.duration(this.duration).asSeconds()];
      this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
      this.deliveryLoad = customer.stop_point_default_delivery_load;
      this.pickupLoad = customer.stop_point_default_pickup_load;
      this.serviceType = customer.stop_point_default_service_type;
      this.selectedServiceType = customer.stop_point_default_service_type;

      if (customer.recurring_mode == this.globals.customerRecurringModes['WEEKLY']
        || customer.recurring_mode == this.globals.customerRecurringModes['MANUAL']
      ) {
        this.isRepetitionEnabled = true;
      }

      if (customer.customer_manual_reappear) {
        this.recurringMode = customer.recurring_mode ?? this.globals.customerRecurringModes['WEEKLY'];
        this.repeatFrequencyAmount = customer.customer_manual_reappear.frequency_amount;
        this.repeatFrequencyType = customer.customer_manual_reappear.frequency_type ?? this.frequencyTypeItems[0].value;
        this.isWeekendEnabled = customer.customer_manual_reappear.is_weekend_enabled ?? false;
        this.repeatFrequencyDate = customer.customer_manual_reappear.start_datetime ?? moment().format('DD-MM-YYYY');
        this.repeatFrequencyTimezone = customer.customer_manual_reappear.start_time_zone ?? this.defaultTimezone;
      }

      this.setAddress(customer.address);
      this.showDraggableMarker(this.lat, this.lon);
      this.note = customer.note ? customer.note : '';
      this.portalAccess = customer.portal_access;
      this.setPortalSwitches();
      if (this.portalAccess) {
        this.portalOn = true;
        if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
          this.portalPlus = true;
        }
      }
      this.priority = customer.priority;
      if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
        this.priorityOn = true;
      } else {
        this.priorityOn = false;
      }
      const reappearObject = {};
      if (customer.reappears) {
        customer.reappears.forEach((reappear) => {
          const day = reappear.weekday;
          reappearObject[day] = reappear.reappear;
        });
        this.recurringDays = [];
        this.days.forEach((day, i) => {
          if (reappearObject[day]) {
            this.recurringEnabled[i] = true;
            this.recurringDays.push({
              recurringEnabled: true
            });
            this.myForm.value.customer.recurringDays[i].recurringEnabled = true;
          } else {
            this.recurringEnabled[i] = false;
            this.recurringDays.push({
              recurringEnabled: false
            });
            this.myForm.value.customer.recurringDays[i].recurringEnabled = false;
          }
        });
      }
      if (customer.vehicle_ids) {
        this.vehicleIds = customer.vehicle_ids;
      }

      const timeTable = document.getElementById('time-window-sliders-container');
      timeTable.classList.add('open');
      this.listen.push(this.translate.get('STOP_POINT.CLOSE_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));

      this.patchForm();
      this.setForm();
      M.updateTextFields();
    });
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();
  }

  setTimeWindows(timeWindows) {
    this.days.forEach((day, index) => {

      if (timeWindows[day].length === 1) {
        this.doubleTimeWindow[index] = false;
      } else {
        this.doubleTimeWindow[index] = true;
      }

      let timeWindowDouble = [];
      let timeWindowRange, timeWindowRangeMinutes, timeWindowStartDatetime;
      let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
      let timeWindowStartUnformatted, timeWindowEndUnformatted;
      this.timeWindowUnformatted[index] = [];

      timeWindows[day].forEach(timeWindow => {
        timeWindowRange = timeWindow.time_window_range;
        timeWindowStartDatetime = timeWindow.time_window_start_datetime;
        timeWindowRange = timeWindow.time_window_range;
        timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
        timeWindowStart = moment(timeWindowStartDatetime).format();
        timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
        timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
        timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
        this.timeWindowUnformatted[index].push(timeWindowStartUnformatted);
        this.timeWindowUnformatted[index].push(timeWindowEndUnformatted);
        timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
        timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();

        if (timeWindows[day].length === 1) {
          this.timeWindowSingle[index] = [timeWindowStartMinutes, timeWindowEndMinutes];
          timeWindowDouble = this.timeWindowDoubleDefault;
        } else {
          this.timeWindowSingle[index] = this.timeWindowSingleDefault;
          timeWindowDouble.push(timeWindowStartMinutes);
          timeWindowDouble.push(timeWindowEndMinutes);
        }

      });

      this.timeWindowDouble[index] = timeWindowDouble;

      this.timeWindowsData.push({
        timeWindowSingle: this.timeWindowSingle[index],
        timeWindowDouble: this.timeWindowDouble[index],
        isDouble: this.doubleTimeWindow[index],
      });

    });
  }

  timeWindowSelected() {
    if (this.myForm.value.customer.companyTimeWindow) {
      const timeWindows = this.myForm.value.customer.companyTimeWindow['CompanyTimeWindowCategory']['time_windows'];
      this.setTimeWindows(timeWindows);
    }
  }

  openDateModal() {
    this.modalService.openCustomerDateModal();
  }

  patchAddresses() {
    this.myForm.patchValue({
      'customer': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
  }

  patchPortalValues() {
    this.myForm.patchValue({
      'driver': {
        'portal_access_sw': this.portalAccess,
      },
    });
    M.updateTextFields();
  }

  patchForSubmit() {
    // Finalize recurring mode value (is returned 0 (NONE) if repetition is not enabled!)
    let recurringModeFinalValue;
    this.isRepetitionEnabled ? recurringModeFinalValue = this.recurringMode : recurringModeFinalValue = this.globals.customerRecurringModes['NONE'];

    this.myForm.patchValue({
      'customer': {
        'repetition_enabled': this.isRepetitionEnabled,
        'recurring_mode': recurringModeFinalValue,
        'customer_manual_reappear': {
          'frequency_amount': this.repeatFrequencyAmount,
          'frequency_type': this.repeatFrequencyType ?? this.frequencyTypeItems[0].value,
          'is_weekend_enabled': this.isWeekendEnabled,
          'start_datetime': this.repeatFrequencyDate,
          'start_time_zone': this.repeatFrequencyTimezone,
        },
        'timeWindows': this.timeWindowsData,
        'recurringDays': this.recurringDays,
        'time_windows': this.timeWindowDailyValues,
        'reappear': this.reappear,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'id': this.customerId,
        'contact_name': this.name,
        'entity_type': this.entityType,
        'telephone': this.telephone,
        'company_id': this.companyId,
        'stop_point_default_duration': this.duration,
        'stop_point_default_service_type': this.serviceType,
        'selectedServiceType': this.selectedServiceType,
        'portal_access': this.portalAccess,
        'portalPlus': this.portalPlus,
        'priority': this.priority,
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'term': this.selectedAddress,
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'customer': {
        'repetition_enabled': this.isRepetitionEnabled,
        'recurring_mode': this.recurringMode,
        'customer_manual_reappear': {
          'frequency_amount': this.repeatFrequencyAmount,
          'frequency_type': this.repeatFrequencyType ?? this.frequencyTypeItems[0].value,
          'is_weekend_enabled': this.isWeekendEnabled,
          'start_datetime': this.repeatFrequencyDate,
          'start_time_zone': this.repeatFrequencyTimezone,
        },
        'timeWindows': this.timeWindowsData,
        'recurringDays': this.recurringDays,
        'time_windows': this.timeWindowDailyValues,
        'reappear': this.reappear,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'customer_identification_number': this.identificationNumber,
        'id': this.customerId,
        'contact_name': this.name,
        'company_name': this.companyName ? this.companyName : '',
        'email': this.email,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'duration_seconds': this.durationSeconds,
        'company_id': this.companyId,
        'entity_status': this.status,
        'entity_type': this.entityType,
        'stop_point_default_duration': this.duration,
        'stop_point_default_delivery_load': this.deliveryLoad,
        'stop_point_default_pickup_load': this.pickupLoad,
        'stop_point_default_service_type': this.serviceType,
        'selectedServiceType': this.selectedServiceType,
        'note': this.note,
        'portal_access': this.portalAccess,
        'portalOn': this.portalOn,
        'portalPlus': this.portalPlus,
        'vehicle_ids': this.vehicleIds,
        'priority': this.priority,
        'priorityOn': this.priorityOn,
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });

    M.updateTextFields();

    this.checkFormValidity();
  }

  setForm() {
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('custom-input-customer-form')).value = this.freeformAddress.valueOf();
    }
  }

  resetTimeWindows() {
    this.timeWindowSingle = [];
    this.timeWindowDouble = [];
    this.timeWindowUnformatted = [];
    this.doubleTimeWindow = [];
    this.days.forEach((day, i) => {
      this.recurringEnabled[i] = false;
      this.myForm.value.customer.recurringDays[i].recurringEnabled = false;
      this.timeWindowDailyValues[day] = [];
      this.timeWindowSingle.push(this.timeWindowSingleDefault);
      this.timeWindowDouble.push(this.timeWindowDoubleDefault);
      this.timeWindowUnformatted.push(this.timeWindowUnformattedDefault.slice(0));
      this.doubleTimeWindow.push(false);
    });
    this.timeWindowsData = [];
    this.selectedTimeWindow = {};
    this.selectedTimeWindowId = null;
  }

  resetForm() {
    this.myForm.patchValue(this.emptyFormValues);
    this.isRepetitionEnabled = false;
    this.recurringMode = this.globals.customerRecurringModes['WEEKLY'];
    this.repeatFrequencyAmount = null;
    this.repeatFrequencyType = this.frequencyTypeItems[0].value;
    this.isWeekendEnabled = false;
    this.repeatFrequencyDate = moment().format('DD-MM-YYYY');
    this.repeatFrequencyTimezone = this.defaultTimezone;
    this.reappear = {};
    this.positionSet = false;
    this.customerId = null;
    this.name = '';
    this.companyName = '';
    this.identificationNumber = '';
    this.email = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.companyId = '';
    this.status = 1;
    this.entityType = 1;
    this.durationUnformatted = '02:00';
    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.deliveryLoad = this.globals.defaultLoad;
    this.pickupLoad = null;
    this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
    this.selectedServiceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
    this.freeformAddress = '';
    this.lat = '';
    this.lon = '';
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    this.note = '';
    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.portalOn = false;
    this.portalPlus = false;
    this.vehicleIds = [];
    this.priority = '';
    this.priorityOn = false;
    this.removeDraggableMarker();
    this.patchForm();
    this.setForm();
    (<HTMLInputElement>document.getElementById('custom-input-customer-form')).value = '';
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.resetTimeWindows();
    const timeTable = document.getElementById('time-window-sliders-container');
    if (timeTable.classList.contains('open')) {
      timeTable.classList.remove('open');
      this.listen.push(this.translate.get('STOP_POINT.OPEN_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));
    }
  }

  repetitionToggle() {
    this.isRepetitionEnabled = this.myForm.value.customer.repetition_enabled;

    // default recurring mode when repetition is enabled from the switch
    if (this.isRepetitionEnabled) {
      this.recurringMode = this.globals.customerRecurringModes['WEEKLY'];
      this.myForm.patchValue({
        'customer': {
          'recurring_mode': this.recurringMode
        }
      });
    }
  }

  initializeSlider() {
    this.timeWindowOptions = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 1200],
      connect: [false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.timeWindowOptionsDouble = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 840, 1020, 1200],
      connect: [false, true, false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.durationOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
  }

  convertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.customer.duration_seconds);
    seconds = this.convertToMinute(this.myForm.value.customer.duration_seconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  convertValuesToTime(index) {
    const timeWindows = this.myForm.value.customer.timeWindows;
    let hours = 0, minutes = 0;
    if (this.doubleTimeWindow[index]) {
      timeWindows[index].timeWindowDouble.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[index][i] = this.formatHoursAndMinutes(hours, minutes);
      });
    } else {
      timeWindows[index].timeWindowSingle.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[index][i] = this.formatHoursAndMinutes(hours, minutes);
      });
    }
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) { hours = '0' + hours; }
    if (minutes.toString().length === 1) { minutes = '0' + minutes; }
    return hours + ':' + minutes;
  }

  timeWindowCheckboxClicked(i) {
    if (this.doubleTimeWindow[i]) {
      this.doubleTimeWindow[i] = false;
      this.timeWindowUnformatted[i] = ['08:00', '20:00'];
    } else {
      this.doubleTimeWindow[i] = true;
      this.timeWindowUnformatted[i] = ['08:00', '14:00', '17:00', '20:00'];
    }
  }

  radioButtonChange($event) {
  }

  repeatModeChanged(mode) {
    if (mode == this.globals.customerRecurringModes['WEEKLY']) {
      this.recurringMode = this.globals.customerRecurringModes['WEEKLY'];
    } else {
      this.recurringMode = this.globals.customerRecurringModes['MANUAL'];
    }

    // patch form values
    this.myForm.patchValue({
      'customer': {
        'recurring_mode': this.recurringMode
      }
    });
  }

  initializeSelect() {
    const elements = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elements);
  }

  onAddressChange() {
    if (this.myForm.value.customer.address) {
      const selectedAddress = this.myForm.value.customer.address.term;
      if (selectedAddress) {
        (<HTMLInputElement>document.getElementById('custom-input-customer-form')).value = selectedAddress.label;
        this.lat = selectedAddress.position[0];
        this.lon = selectedAddress.position[1];
        this.freeformAddress = selectedAddress.label;
        this.setAddress(selectedAddress.address);
        this.showDraggableMarker(Number(this.lat), Number(this.lon));
      }
    }
    this.checkFormValidity();
  }

  portalEnabled() {
    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
      alert(this.demoDisableRequestMsg);
    } else {
      if (!this.globals.portalAccess && this.myForm.value.customer.portalOn) {
        if (confirm(this.portalSettingsConfirmMsg)) {
          const settingsRequestObserver = {
            next: (response) => {
              this.globals.getCompanyData();
              this.globals.portalAccess = true;
            },
            error: (error) => {
              console.error(error);
            },
            complete: () => { },
          };
          const data = {
            companySettings: {
              portal_access_sw: true
            }
          };
          this.http.put('api/v1/companies/' + this.globals.companyId, data).pipe(take(1)).subscribe(settingsRequestObserver);
        } else {
          this.portalOn = false;
          this.patchPortalValues();
        }
      }
    }
  }

  setPortalSwitches() {
    if (this.portalAccess === this.globals.portalAccessConstants['NO_ACCESS']) {
      this.portalOn = false;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_NO_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = true;
    }
  }

  onServiceTypeChange() {
    this.selectedServiceType = this.myForm.value.customer.selectedServiceType;
    this.deliveryLoad = 0;
    this.pickupLoad = 0;
    this.myForm.patchValue({
      'customer': {
        'selectedServiceType': this.selectedServiceType,
        'stop_point_default_delivery_load': this.deliveryLoad,
        'stop_point_default_pickup_load': this.pickupLoad
      }
    });
    M.updateTextFields();

    this.checkFormValidity();
  }

  public submitCustomerForm() {
    const formValues = this.myForm.value.customer;
    if (formValues.contact_name) {
      this.name = formValues.contact_name;
      if (formValues.contact_name === this.noNameLabel) {
        this.name = this.noNameConstant;
      }
    } else {
      this.name = this.noNameConstant;
    }

    if (formValues.selectedServiceType) {
      this.serviceType = formValues.selectedServiceType;
    }

    if (formValues.portalOn) {
      if (formValues.portalPlus) {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_WITH_HISTORY'];
      } else {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
      }
    } else {
      this.portalAccess = this.globals.portalAccessConstants['NO_ACCESS'];
    }
    if (formValues.priorityOn) {
      this.priority = this.globals.stopPointPriorityConstants['HIGH'];
    } else {
      this.priority = this.globals.stopPointPriorityConstants['NORMAL'];
    }
    if (formValues.phoneNumber) {
      this.telephone = formValues.countryPrefix + formValues.phoneNumber;
    }
    if (formValues.entity_type === true) {
      this.entityType = 1;
    } else if (formValues.entity_type === false) {
      this.entityType = 0;
    }
    if (!this.positionSet) {
      if (formValues.address.term.customer) {
        this.lat = formValues.address.term.customer.address.lat;
        this.lon = formValues.address.term.customer.address.lon;
        this.freeformAddress = this.addressService.getAddressLabel(formValues.address.term.customer.address);
      } else if (formValues.address.term.position) {
        this.lat = formValues.address.term.position[0];
        this.lon = formValues.address.term.position[1];
        this.freeformAddress = formValues.address.term.label;
      }
    }
    let durationSeconds;
    if (Array.isArray(formValues.duration_seconds)) {
      durationSeconds = formValues.duration_seconds[0];
    } else {
      durationSeconds = formValues.duration_seconds;
    }
    this.durationSeconds = [];
    this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

    if (formValues.selectedVehicles) {
      formValues.selectedVehicles.forEach(vehicle => {
        this.vehicleIds.push(vehicle.vehicle.id);
      });
    }

    this.days.forEach((day, index) => {
      const timeWindowDay = [];
      for (let i = 0; i < this.timeWindowUnformatted[index].length; i = i + 2) {
        const timeWindowStartMoment = moment(this.timeWindowUnformatted[index][i], 'HH:mm');
        const timeWindowEndMoment = moment(this.timeWindowUnformatted[index][i + 1], 'HH:mm');
        const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
        const timeWindowStartDatetimeUnformatted = moment().format('YYYY-DD-MM ') + this.timeWindowUnformatted[index][i];
        const timeWindowStartDatetime = moment(timeWindowStartDatetimeUnformatted, 'YYYY-DD-MM HH:mm').format();
        const timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();
        const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();
        const timeWindowObject = {
          time_window_start_datetime_timezone: this.defaultTimezone,
          time_window_start_datetime: timeWindowStartDatetime,
          time_window_range: timeWindowRange
        };
        timeWindowDay.push(timeWindowObject);
      }
      this.timeWindowDailyValues[day] = timeWindowDay;
    });
    if (formValues.companyTimeWindow) {
      if (formValues.companyTimeWindow['CompanyTimeWindowCategory']) {
        this.selectedTimeWindowId = formValues.companyTimeWindow['CompanyTimeWindowCategory']['id'];
      }
    }
    this.days.forEach((day, i) => {
      if (this.myForm.value.customer.recurringDays[i].recurringEnabled) {
        this.reappear[day] = {
          reappear: true
        };
      } else {
        this.reappear[day] = {
          reappear: false
        };
      }
    });

    // check to set recurring mode to 0
    // if (!this.isRepetitionEnabled) {
    //   this.recurringMode = this.globals.customerRecurringModes['NONE'];
    // }

    // manual recurring mode
    this.recurringMode = formValues.recurring_mode;
    this.repeatFrequencyAmount = formValues.customer_manual_reappear.frequency_amount;
    this.repeatFrequencyType = formValues.customer_manual_reappear.frequency_type;
    this.isWeekendEnabled = formValues.customer_manual_reappear.is_weekend_enabled;
    this.repeatFrequencyDate = formValues.customer_manual_reappear.start_datetime ?? moment().format('DD-MM-YYYY');
    this.repeatFrequencyTimezone = formValues.customer_manual_reappear.start_time_zone ?? this.defaultTimezone;

    this.patchForSubmit();
    this.setForm();
    const myObserver = {
      next: (response) => {
        this.modalGridService.updateCustomerGrid();
      },
      error: (error) => {
        console.error(error);
        this.isClickedOnce = false;
      },
      complete: () => {
        this.isClickedOnce = false;
        this.resetForm();
        this.modalGridService.closeRightModal();
      },
    };

    if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
      alert(this.demoDisableRequestMsg);
      this.isClickedOnce = false;
    } else {
      if (this.customerId) {
        if (formValues.phoneNumber.toString().length === 10) {
          this.http.put('api/v1/customers/' + this.customerId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        } else {
          if (confirm('Phone number is not 10 digits. Continue?')) {
            this.http.put('api/v1/customers/' + this.customerId, JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
          } else { }
        }
      } else {
        if (formValues.phoneNumber.toString().length === 10) {
          this.http.post('api/v1/customers', JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
        } else {
          if (confirm('Phone number is not 10 digits. Continue?')) {
            this.http.post('api/v1/customers', JSON.stringify(this.myForm.value)).pipe(take(1)).subscribe(myObserver);
          } else { }
        }
      }
    }
  }

  getClass(id: String) {
    const select = document.querySelector('#' + id);
    if (select.classList.contains('ng-select-opened') || this.selectedAddress.address) {
      return 'active';
    } else {
      return '';
    }
  }

  inputFocusOut() {
    if (!this.myForm.value.customer.address.term.timeZone) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  inputAddress() {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'customer': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });
    this.ngSelect.filter((<HTMLInputElement>document.getElementById('custom-input-customer-form')).value);
  }

  toggleTimeTable() {
    const timeTable = document.getElementById('time-window-sliders-container');
    if (timeTable.classList.contains('open')) {
      timeTable.classList.remove('open');
      this.listen.push(this.translate.get('STOP_POINT.OPEN_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));
    } else {
      timeTable.classList.add('open');
      this.listen.push(this.translate.get('STOP_POINT.CLOSE_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));
    }
  }

  centerToMarker(bounds) {
    const newBounds = new H.geo.Rect(bounds.getTop(), bounds.getLeft() - 0.005, bounds.getBottom(), bounds.getRight() + 0.015);
    this.map.getViewModel().setLookAtData({ bounds: newBounds }, true);
  }

  showDraggableMarker(lat, lng) {
    this.draggableMarker.setGeometry({ lat: lat, lng: lng });
    this.draggableMarker.draggable = true;
    this.draggableMarkerGroup.addObjects([this.draggableMarker]);

    const bounds = this.draggableMarkerGroup.getBoundingBox();
    this.centerToMarker(bounds);

    // disable the default draggability of the underlying map
    // when starting to drag a marker object:
    // this.map.addEventListener('dragstart', (ev) => {
    //     const target = ev.target;
    //     if (target instanceof H.map.Marker) {
    //         this.behavior.disable();
    //     }
    // }, false);
    this.map.addEventListener('dragstart', (ev) => {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        const targetPosition = this.map.geoToScreen(target.getGeometry());
        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
        this.behavior.disable();
      }
    }, false);

    // re-enable the default draggability of the underlying map
    // when dragging has completed
    this.map.addEventListener('dragend', (ev) => {
      const target = ev.target;
      if (target instanceof H.map.Marker) {
        this.behavior.enable();
        this.positionSet = true;
        this.lat = target.getGeometry()['lat'];
        this.lon = target.getGeometry()['lng'];
        this.myForm.patchValue({
          'customer': {
            'address': {
              'lat': this.lat,
              'lon': this.lon,
            },
          },
        });
      }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    // this.map.addEventListener('drag', (ev) => {
    //     const target = ev.target,
    //         pointer = ev.currentPointer;
    //     if (target instanceof H.map.Marker) {
    //         target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY));
    //     }
    // }, false);
    this.map.addEventListener('drag', (ev) => {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(this.map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
      }
    }, false);
  }

  removeDraggableMarker() {
    if (this.draggableMarkerGroup.contains(this.draggableMarker)) {
      this.draggableMarkerGroup.removeObject(this.draggableMarker);
    }
  }

  initMap() {
    const depotLat = this.globals.currentLat;
    const depotLon = this.globals.currentLon;
    const defaultLayers = this.platform.createDefaultLayers();
    this.map = new H.Map(
      this.mapElement.nativeElement,
      defaultLayers.vector.normal.map,
      {
        zoom: 12,
        center: { lat: depotLat, lng: depotLon }
      }
    );
    var provider = this.map.getBaseLayer().getProvider();
    var style = new H.map.Style('/assets/lastmilyAssets/light-final.yaml', 'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    const mapEvents = new H.mapevents.MapEvents(this.map);
    this.rightClickOnMap = (event) => {
      const coords = this.map.screenToGeo(event.viewportX, event.viewportY);
      this.positionSet = true;
      this.lat = coords.lat;
      this.lon = coords.lng;
      this.dataLoading.emit('true');
      this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
        this.dataLoading.emit('false');
        if (location['data']['addresses']['items'].length) {
          this.setAddress(location['data']['addresses']['items'][0]['address']);
          this.checkFormValidity();
          // this.freeformAddress = location['data']['addresses']['items']['View'][0]['Result'][0]['Location']['Address']['Label'];
          // this.country = location['data']['addresses']['items']['View'][0]['Result'][0]['Location']['Address']['Country'];
        } else {
          this.freeformAddress = 'Address';
          this.country = 'GR';
        }
        (<HTMLInputElement>document.getElementById('custom-input-customer-form')).value = this.freeformAddress;
      });
      this.showDraggableMarker(Number(coords.lat), Number(coords.lng));
    };
    this.map.addEventListener('contextmenu', this.rightClickOnMap);
    // Instantiate the default behavior, providing the mapEvents object:
    this.behavior = new H.mapevents.Behavior(mapEvents);
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    const mapSettings = this.ui.getControl('mapsettings');
    mapSettings.setAlignment('top-left');
    this.map.addObject(this.draggableMarkerGroup);
  }

  setVehicleTypes() {
    const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
    const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
    const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
    const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
    const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
    const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

    this.vehicleTypes = [
      {
        index: 0,
        image: bicycle,
      },
      {
        index: 1,
        image: scooter,
      },
      {
        index: 2,
        image: car,
      },
      {
        index: 3,
        image: van,
      },
      {
        index: 4,
        image: largeVan,
      },
      {
        index: 5,
        image: truck,
      }
    ];
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT.OPEN_WEEK_TIME_TABLE').subscribe((res: string) => { this.toggleTimeTableText = res; }));

    this.listen.push(this.translate.get('GENERIC.SUNDAY_INITIAL').subscribe((res: string) => { this.dayLabels[0] = res; }));
    this.listen.push(this.translate.get('GENERIC.MONDAY_INITIAL').subscribe((res: string) => { this.dayLabels[1] = res; }));
    this.listen.push(this.translate.get('GENERIC.TUESDAY_INITIAL').subscribe((res: string) => { this.dayLabels[2] = res; }));
    this.listen.push(this.translate.get('GENERIC.WEDNESDAY_INITIAL').subscribe((res: string) => { this.dayLabels[3] = res; }));
    this.listen.push(this.translate.get('GENERIC.THURSDAY_INITIAL').subscribe((res: string) => { this.dayLabels[4] = res; }));
    this.listen.push(this.translate.get('GENERIC.FRIDAY_INITIAL').subscribe((res: string) => { this.dayLabels[5] = res; }));
    this.listen.push(this.translate.get('GENERIC.SATURDAY_INITIAL').subscribe((res: string) => { this.dayLabels[6] = res; }));
    this.listen.push(this.translate.get('DRIVER.MULTIPLE').subscribe((res: string) => { this.multipleDriversLabel = res; }));
    this.listen.push(this.translate.get('ALERTS.PORTAL_SETTINGS_DISABLED').subscribe((res: string) => { this.portalSettingsConfirmMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('ALERTS.DEMO_REQUEST_NOT_POSSIBLE').subscribe((res: string) => { this.demoDisableRequestMsg = res; }));
    this.listen.push(this.translate.get('CUSTOMER.DAYS').subscribe((res: string) => { this.daysLabel = res; }));
    this.listen.push(this.translate.get('CUSTOMER.WEEKS').subscribe((res: string) => { this.weeksLabel = res; }));

    setTimeout(() => {
      this.setVehicleTypes();
      const vehiclesDataRefreshIntervalId = setInterval(() => {
        if (this.globals.vehiclesDataDone) {
          clearInterval(vehiclesDataRefreshIntervalId);
          this.vehicles = this.globals.vehiclesArray;
        }
      }, 200);
    }, 300);
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    // recurring dropdown items (days, weeks)
    this.frequencyTypeItems = [
      {
        label: this.daysLabel,
        value: this.globals.customerFrequencyTypes['DAYS']
      },
      {
        label: this.weeksLabel,
        value: this.globals.customerFrequencyTypes['WEEKS']
      },
    ];

    this.days.forEach(day => {
      this.timeWindowDailyValues[day] = [];
      this.timeWindowSingle.push(this.timeWindowSingleDefault);
      this.timeWindowDouble.push(this.timeWindowDoubleDefault);
      this.timeWindowUnformatted.push(this.timeWindowUnformattedDefault.slice(0));
      this.doubleTimeWindow.push(false);
      this.myForm.controls['customer']['controls']['timeWindows'].push(this.formBuilder.group({
        timeWindowSingle: [this.timeWindowSingleDefault],
        timeWindowDouble: [this.timeWindowDoubleDefault],
        isDouble: [false],
      }));
      this.myForm.controls['customer']['controls']['recurringDays'].push(this.formBuilder.group({
        recurringEnabled: [false],
      }));
    });

    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddressesAndCustomers(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );

    const timeWindowsDataRefreshIntervalId = setInterval(() => {
      if (this.globals.timeWindowsDataDone) {
        clearInterval(timeWindowsDataRefreshIntervalId);
        this.customTimeWindows = this.globals.customTimeWindows;
      }
    }, 200);

    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.myForm.patchValue({
      'customer': {
        'stop_point_default_duration': this.duration,
        'duration_seconds': this.durationSeconds,
      }
    });

    this.deliveryLoad = this.globals.defaultLoad;
    this.countryPrefix = this.globals.defaultCountryCode;
    this.myForm.patchValue({
      'customer': {
        'countryPrefix': this.countryPrefix,
        'stop_point_default_delivery_load': this.deliveryLoad,
      }
    });

    M.updateTextFields();

    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.setPortalSwitches();
    this.patchPortalValues();
  }

  public ngAfterViewInit() { }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
