import { ModalService } from '@app/services/modal.service';
import { GenericService } from '@app/services/generic.service';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import * as moment from 'moment-timezone';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GridsService } from '@app/services/grids.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { take } from 'rxjs/operators';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { PaymentsOverviewComponent } from '@app/payments-overview/payments-overview.component';
import { GridUtils } from '@app/utils/grid-utils';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { GenericUtils } from '@app/utils/generic-utils';

@Component({
    selector: 'app-driver-report-modal',
    templateUrl: './driver-report-modal.component.html',
    styleUrls: ['./driver-report-modal.component.scss']
})
export class DriverReportModalComponent implements OnInit, OnDestroy {
    // @Output() cancelChangeStopPointFulfillmentStatus = new EventEmitter<string>();
    @ViewChild(PaymentsOverviewComponent, { static: false }) paymentsOverviewComponent: PaymentsOverviewComponent;

    updateStopPointUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/stop-points/STOP_POINT_ID';
    listen = [];
    isModalOpen = false;
    isClickedOnce = false;
    projectProblemId = null;
    id;
    date;

    driverId = null;
    driverName = '';
    // date = '';
    totalRating = 0;
    completedPoints = 0;
    totalPoints = 0;
    pointsPercentage = 0;
    duration = '-';
    durationDifferenceMinutes = 0;
    durationDifferenceClass = '';
    durationDifference = '';
    distance = 0;
    owedAmount = 0;
    amounts = {};
    driverPayments = [];
    isWarehouseHandedChecked;
    isWarehouseHandedCheckboxDisabled;
    isPayOnDeliveryReturnedChecked;
    handedStopPointIds = [];
    stopPointsInRepository = [];

    // driversReportArray = [];
    canceledGridArray = [];
    delaysGridArray = [];

    canceledColumnDefs;
    delaysColumnDefs;
    gridDelaysApi;
    gridCancelledApi;
    gridDelaysColumnApi;
    gridCancelledColumnApi;
    domLayout = 'autoHeight';
    driversDataArray = [];
    delaysRowData: any;
    canceledRowData: any;
    headerHeight = 30;
    rowHeight = 50;

    driverOfTheDayMsg = '';
    totalDelayMsg = ''
    typeTitle = '';
    whereTitle = '';
    timeTitle = '';
    nameTitle = '';
    detailsTitle = '';
    statusTitle = '';
    loadTitle = '';
    startDelayText = '';
    hoursShortMsg = '';
    minutesShortMsg = '';
    secondsShortMsg = '';
    kilometersShortMsg = '';
    litersText = '';
    copyLabel = '';

    isDelaysBoxMarkedOpen;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        public globals: Globals,
        public gridsService: GridsService,
        public stopPointUtils: StopPointUtils,
        public gridUtils: GridUtils,
        private viewProjectProblemService: ViewProjectProblemService,
        private projectProblemDataService: ProjectProblemDataService,
        private dateTimeCalculatorService: DateTimeCalculatorService,
        private genericService: GenericService,
        public modalService: ModalService,
        public genericUtils: GenericUtils,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        // after sp update, update the sps in canceled & shipments sps grid
        this.listen.push(this.viewProjectProblemService.updateStopPointsGridListen().subscribe(() => {
            if (this.isModalOpen) {
                this.canceledGridArray = [];
                this.emptyModal();
                this.getStats(this.id, this.date);
                this.modalService.updateReportShipmentsModal(); // just closed the modal for now
            }
        }));

        this.listen.push(this.genericService.listenOpenDriverReportModal().subscribe(data => {
            // project view
            if (this.globals.isInRoute('projectView')) {
                this.projectProblemDataService.projectProblemId = this.projectProblemId;
            }
            // everywhere else in the project
            else {
                this.projectProblemDataService.projectProblemId = data['projectProblemId'];
                this.projectProblemId = data['projectProblemId'];
            }

            this.projectProblemDataService.loadRouteSettings().subscribe(routeSettingsResponse => {
                this.projectProblemDataService.setRouteSettings(routeSettingsResponse['items']);

                // we need solution data to create sequence for driver report...
                const solutionUrl = this.projectProblemDataService.solutionUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
                const allStopPointsUrl = this.projectProblemDataService.allStopPointsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId) + '?pageSize=-1';
                this.http.get(solutionUrl).pipe(take(1)).subscribe(solutionRes => {
                    this.http.get(allStopPointsUrl).pipe(take(1)).subscribe(spsRes => {
                        this.projectProblemDataService.projectProblemBasicVoucherServices = spsRes['items']['basic_voucher_services'];
                        this.projectProblemDataService.projectProblemChargeCategories = spsRes['items']['charge_categories'];

                        this.projectProblemDataService.loadDrivers().pipe(take(1)).subscribe(driversResponse => {
                            if (driversResponse['items']) {
                                this.projectProblemDataService.setDrivers(driversResponse['items']);
                                this.projectProblemDataService.stopPointsArray = spsRes['items']['stopPoints'];
                                spsRes['items']['stopPoints'].forEach((stopPoint, index) => {
                                    this.projectProblemDataService.stopPoints[stopPoint['id']] = stopPoint;
                                    this.projectProblemDataService.stopPointIndexInArray[stopPoint['id']] = index;
                                });
                                this.projectProblemDataService.solutionData = solutionRes['items'];
                                this.projectProblemDataService.setSolutionData();
                                this.changeDetectorRef.detectChanges();
                                this.loadAndOpen(data.driverId, data.departureTime, data.projectProblemId);
                            }
                        });
                    });
                });
            });
        }));
    }

    getStats(id, date) {
        // let params = '?pageSize=1';
        // params += '&page=0';
        // params += '&ids=' + id;
        // params += '&date=' + moment(date).utc().format();
        // params += '&days=1';
        // params += '&projectProblemId=' + this.projectProblemId;

        let newParams = '?daysBeforeUntilDateCount=1';
        newParams += '&untilDateTime=' + moment(date).utc().format();
        newParams += '&projectProblemId=' + this.projectProblemId;
        newParams += '&driverId=' + id;
        newParams += '&retrieve_type=on-demand';

        if (this.gridDelaysApi) {
            this.gridDelaysApi.showLoadingOverlay();
        }

        if (this.gridCancelledApi) {
            this.gridCancelledApi.showLoadingOverlay();
        }

        // this.http.get('api/v1/stats/driver-stats' + params).pipe(take(1)).subscribe(response => {
        this.http.get('api/internal/v1/statistics/driver-statistics' + newParams).pipe(take(1)).subscribe(response => {
            if (response['items']) {
                if (response['items'].length) {
                    const driver = response['items'][0];

                    this.driverId = id;
                    this.driverName = driver.name;

                    // disable warehouse handed checkbox if it is loaded checked
                    if (driver.are_stop_points_returned_to_warehouse) {
                        this.isWarehouseHandedChecked = driver.are_stop_points_returned_to_warehouse;
                        this.isWarehouseHandedCheckboxDisabled = true;
                    } else {
                        this.isWarehouseHandedCheckboxDisabled = false;
                    }

                    this.isPayOnDeliveryReturnedChecked = driver.is_pay_on_delivery_returned;
                    let rating = driver.total_rating;
                    if (rating > 5) { rating = 5; }
                    this.totalRating = parseFloat((rating).toFixed(2));
                    this.completedPoints = driver.completed_shipments;
                    this.totalPoints = driver.total_shipments;
                    this.distance = driver.total_driven_distance;
                    const totalDurationMinutes = moment.duration(driver.average_stop_over_duration_per_shipment, 'minutes').asMinutes();
                    const durationString = this.dateTimeCalculatorService.calculateTotalDuration(totalDurationMinutes);
                    this.duration = durationString.replace('MINUTES_MSG', this.minutesShortMsg).replace('HOURS_MSG', this.hoursShortMsg);
                    this.durationDifferenceMinutes = moment.duration(driver.total_driving_delay, 'minutes').asMinutes();
                    this.durationDifference = '';
                    if (this.durationDifferenceMinutes > 0) {
                        this.durationDifference += '+';
                        this.durationDifferenceClass = 'red-letters';
                    } else {
                        this.durationDifference += '-';
                        this.durationDifferenceClass = 'green-letters';
                    }
                    const durationDifferenceString = this.dateTimeCalculatorService.calculateTotalDuration(this.durationDifferenceMinutes);
                    this.durationDifference += durationDifferenceString.replace('MINUTES_MSG', this.minutesShortMsg).replace('HOURS_MSG', this.hoursShortMsg);

                    const routeSettingId = this.projectProblemDataService.driversToRouteSettingIds[id];
                    const sequence = this.projectProblemDataService.sequenceArrayPerRouteSettingId[routeSettingId];

                    if (moment.duration(driver.total_departure_delay, 'hours').asHours() > 0) {
                        const startDelayString = this.dateTimeCalculatorService.calculateDelayInHoursAndMinutes(driver.total_departure_delay);
                        const startDelay = startDelayString.replace('MINUTES_MSG', this.minutesShortMsg).replace('HOURS_MSG', this.hoursShortMsg);
                        if (startDelay) {
                            let delaysGridObject = {
                                type: 'start',
                                where: 'start',
                                time: startDelay
                            };
                            this.delaysGridArray.push(delaysGridObject);
                        }
                    }

                    if (sequence) {
                        sequence.forEach(stopPointId => {
                            const stopPointData = this.projectProblemDataService.stopPoints[stopPointId];
                            const stopPointIndex = this.projectProblemDataService.stopPointIndexInArray[stopPointId];
                            let fullStopPointData = this.projectProblemDataService.stopPointsArray[stopPointIndex];
                            
                            // barcode
                            let barcodeValue;
                            if (fullStopPointData.stopPoint) { fullStopPointData = fullStopPointData.stopPoint; }
                            if (fullStopPointData.service_type == this.globals.stopPointServiceTypeConstants['PICKUP'] && fullStopPointData.relatedStopPoint) {
                                barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(fullStopPointData.relatedStopPoint);
                            } else {
                                barcodeValue = this.stopPointUtils.getBarcodeOrVoucherHash(fullStopPointData);
                            }
                            
                            if (stopPointData) {
                                if (stopPointData['related_to'] === this.globals.stopPointRelatedToConstants['SELF']) {
                                    const fulfillmentStatus = stopPointData['fulfillment_status'];
                                    // operator can set stop points as "handed" only if the stop point is canceled OR if the stop point is a completed pickup that is NOT part of a "same day delivery" shipment
                                    const relatedStopPointData = this.projectProblemDataService.stopPoints[fullStopPointData?.related_stop_point_id]
                                    const pickup = this.stopPointUtils.getPickupFromTwoStopPoints(fullStopPointData, relatedStopPointData);
                                    if (
                                        // delivery without pickup behaviour
                                        (fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['CANCELED'] && !pickup) ||
                                        // delivery with completed pickup behaviour
                                        (fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['CANCELED'] && this.stopPointUtils.isDelivery(stopPointData['service_type']) && this.stopPointUtils.isCompleted(pickup?.fulfillment_events[0]?.reason))
                                        // pickup default behaviour
                                        || (this.stopPointUtils.isPickup(stopPointData['service_type']) && this.stopPointUtils.isCompleted(stopPointData['fulfillment_events'][0]['reason'])) && !relatedStopPointData) {
                                        let canceledGridObject = {
                                            name: stopPointData['contact_name'],
                                            copyLabel: this.copyLabel,
                                            details: {
                                                barcode: barcodeValue ?? '-',
                                                serial: stopPointData['serial_number'] ? stopPointData['serial_number'] : ''
                                            },
                                            status: this.gridUtils.getFulfillmentStatus(stopPointData),
                                            load: this.stopPointUtils.getStopPointVolumeWeightColumnData(stopPointData),
                                            handedDatetime: stopPointData['warehouse_handed_datetime'],
                                            id: stopPointId,
                                        };
                                        this.canceledGridArray.push(canceledGridObject);
                                    } else if (fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['COMPLETED']) {
                                        const sequence = this.projectProblemDataService.stopPointSolutionData[stopPointId]['sequence'];
                                        const routeDelay = this.calculateRouteDelay(stopPointData);
                                        if (routeDelay && sequence) {
                                            let delaysGridObject = {
                                                type: 'route',
                                                where: sequence,
                                                time: routeDelay,
                                                id: stopPointId,
                                            };
                                            this.delaysGridArray.push(delaysGridObject);
                                        }

                                        const durationDelay = this.calculateDurationDelay(stopPointData);
                                        if (durationDelay && sequence) {
                                            let type = 'load';
                                            if (stopPointData['service_type'] === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
                                                type = 'unload';
                                            }
                                            let delaysGridObject = {
                                                type: type,
                                                where: sequence,
                                                time: durationDelay,
                                                id: stopPointId,
                                            };
                                            this.delaysGridArray.push(delaysGridObject);
                                        }
                                    }

                                }
                            }
                        });
                    }

                    this.pointsPercentage = 0;
                    if (this.totalPoints > 0) {
                        this.pointsPercentage = Math.round((this.completedPoints / this.totalPoints) * 100);
                    }
                    this.distance = Math.round(this.distance / 1000)
                    this.calculateAmountOwed();
                    this.delaysRowData = of(this.delaysGridArray);
                    this.canceledRowData = of(this.canceledGridArray);
                    this.selectRowsWithHandedDatetime();
                } else {
                    const noData = { noDataText: 'No data' }
                    this.delaysGridArray.push(noData);
                    this.canceledGridArray.push(noData);
                    this.delaysRowData = of(this.delaysGridArray);
                    this.canceledRowData = of(this.canceledGridArray);
                }
            } else {
                console.error('Report response not containing items');
            }
            this.gridDelaysApi.hideOverlay();
            this.gridCancelledApi.hideOverlay();

            this.changeDetectorRef.detectChanges(); // force on-push changes
        });
    }

    selectRowsWithHandedDatetime() {
        const self = this;
        setTimeout(function () {
            self.gridCancelledApi.forEachNode(function (node, index) {
                if (node.data.handedDatetime) {
                    node.setSelected(true);
                } else {
                    node.setSelected(false);
                }
            });
        }, 100);
    }

    calculateRouteDelay(stopPointData) {
        let actualArrivalTime, routeDelay = '';
        stopPointData['fulfillment_events'].forEach(event => {
            if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                actualArrivalTime = event.fulfillment_datetime;
            }
        });
        if (actualArrivalTime && stopPointData['solution']) {
            const scheduledArrivalTime = stopPointData['solution']['latest_estimated_arrival_datetime'];
            const scheduledArrivalTimeMoment = moment(scheduledArrivalTime);
            const actualArrivalTimeMoment = moment(actualArrivalTime);
            const fulfillmentTimeDifference = Math.round(moment.duration(actualArrivalTimeMoment.diff(scheduledArrivalTimeMoment)).asMinutes());
            if (fulfillmentTimeDifference > 0) {
                const routeDelayString = this.dateTimeCalculatorService.calculateTotalDuration(fulfillmentTimeDifference);
                routeDelay = routeDelayString.replace('MINUTES_MSG', this.minutesShortMsg).replace('HOURS_MSG', this.hoursShortMsg);
            }
        }
        return routeDelay;
    }

    calculateDurationDelay(stopPointData) {
        let actualArrivalTime, actualCompletedTime, actualDuration, calculatedDuration, durationDelay = '';
        calculatedDuration = Math.round(moment.duration(stopPointData['duration']).asMinutes());
        stopPointData['fulfillment_events'].forEach(event => {
            if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['ARRIVED']]['AT_TIME']) {
                actualArrivalTime = event.fulfillment_datetime;
            } else if (event.reason === this.globals.stopPointFulfillmentEventConstants[this.globals.stopPointFulfillmentStatusConstants['COMPLETED']]['AT_TIME']) {
                actualCompletedTime = event.fulfillment_datetime;
            }
        });
        if (actualArrivalTime && actualCompletedTime && calculatedDuration) {
            const actualArrivalTimeMoment = moment(actualArrivalTime);
            const actualCompletedTimeMoment = moment(actualCompletedTime);
            actualDuration = Math.round(moment.duration(actualCompletedTimeMoment.diff(actualArrivalTimeMoment)).asMinutes());
            if ((actualDuration - calculatedDuration) > 0) {
                durationDelay = Number(actualDuration - calculatedDuration) + this.minutesShortMsg;
            }
        }
        return durationDelay;
    }

    calculateAmountOwed() {
        let amount = 0;
        let cardPayAmount = 0, cashPayAmount = 0, bankPayAmount = 0, chequePayAmount = 0, chequeDayPayAmount = 0, canceledPayAmount = 0;
        let cashRecipientTotalAmount = 0, cardRecipientTotalAmount = 0, bankRecipientTotalAmount = 0, chequeRecipientTotalAmount = 0, chequeDayRecipientTotalAmount = 0;
        let repository = this.projectProblemDataService.drivers[this.driverId]['driver']['repository'] ?? 0;
        const collaboratorAmounts = {};
        this.stopPointsInRepository = [];

        this.projectProblemDataService.stopPointsArray.forEach(stopPoint => {
            if (stopPoint.stopPoint) { stopPoint = stopPoint.stopPoint; }
            let validatedCollaborator = this.stopPointUtils.checkVoucherCollaboratorValidity(stopPoint);

            // get collaborator & initialize that collaborator's amounts if they don't already exist inside collaborator amounts
            let collaboratorName;
            if (validatedCollaborator.isValid) {
                collaboratorName = validatedCollaborator.collaborator.collaboratorData.collaborator_name;
                if (!collaboratorAmounts[collaboratorName]) {
                    collaboratorAmounts[collaboratorName] = {
                        cardPayAmount: 0,
                        cashPayAmount: 0,
                        bankPayAmount: 0,
                        chequePayAmount: 0,
                        canceledPayAmount: 0,
                    }
                }
            }

            if (stopPoint.solution?.driver) {
                if (stopPoint.solution.driver.id === this.driverId) {
                    // each stop point has 2 charges: the POD and the recipient charge
                    // each will be taken into account ONLY if it has an amount > 0 & its payment method is valid (meaning it's not NO_PAYMENT)
                    // for non-voucher stop points, we assume it can have only a POD amount
                    let stopPointPayAmount = Number(stopPoint.pay_amount);

                    // first we check if the stopPoint is completed but it's not delivered in a smart point (if it's delivered in a smart point we skip the payment because the driver doesn't have the money)
                    if (this.stopPointUtils.isCompleted(stopPoint.fulfillment_events[0].reason) && !stopPoint.smartPoint) {
                        this.stopPointsInRepository.push(stopPoint);
                        this.stopPointsInRepository = [...this.stopPointsInRepository]; // all completed stop points are stored here, so we can later mark them as having their payments collected

                        // if stop point has a pay_amount > 0 & valid POD payment method, categorize that pay amount based on that POD payment method
                        if (stopPointPayAmount > 0 && stopPoint['executed_pay_on_delivery_payment_method'] !== this.globals.paymentOptionsConstants['NO_PAYMENT']) {
                            console.warn('Added pay_amount of ' + String(stopPoint['pay_amount']) + ' from stop point: ' + String(stopPoint.id) + ' with payment method ' + String(stopPoint.executed_pay_on_delivery_payment_method) + ' [COMPLETED]');

                            this.driverPayments.push({ ...stopPoint, podAmount: stopPointPayAmount });
                            this.driverPayments = [...this.driverPayments];
                            if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']) {
                                cashPayAmount += stopPointPayAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['cashPayAmount'] += stopPointPayAmount;
                                }
                            } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                                cardPayAmount += stopPointPayAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['cardPayAmount'] += stopPointPayAmount;
                                }
                            } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['BANK_DEPOSIT']) {
                                bankPayAmount += stopPointPayAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['bankPayAmount'] += stopPointPayAmount;
                                }
                            } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['CHEQUE']) {
                                chequePayAmount += stopPointPayAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['chequePayAmount'] += stopPointPayAmount;
                                }
                            } else if (stopPoint['executed_pay_on_delivery_payment_method'] === this.globals.paymentOptionsConstants['DAY_CHEQUE']) {
                                chequeDayPayAmount += stopPointPayAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['chequePayAmount'] += stopPointPayAmount;
                                }
                            }
                        }

                        // if stop point has a voucher, a recipient charge > 0 & a valid FARE payment method, categorize its recipient charge payment based on that FARE payment method
                        // also, if the stop point is not a delivery w/o pickup that has a charge on sender!
                        const isDeliveryWithoutPickupWithSenderCharge = (this.stopPointUtils.isDelivery(stopPoint.service_type) && !stopPoint.related_stop_point_id && stopPoint?.voucher?.paid_by == this.globals.stopPointCollaboratorTypesConstants['SENDER']) ? true : false;
                        const courierFareAmount = Number(stopPoint.pay_amount_courier_fare);
                        if (courierFareAmount > 0 && stopPoint['executed_payment_method_courier_fare'] !== this.globals.paymentOptionsConstants['NO_PAYMENT'] && !isDeliveryWithoutPickupWithSenderCharge) {
                            console.warn('Added recipient charge of ' + String(courierFareAmount) + ' from stop point: ' + String(stopPoint.id) + ' with fare payment method ' + String(stopPoint.executed_payment_method_courier_fare) + ' [COMPLETED]');

                            this.driverPayments.push({ ...stopPoint, recipientChargeAmount: courierFareAmount });
                            this.driverPayments = [...this.driverPayments];
                            if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']) {
                                cashRecipientTotalAmount += courierFareAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['cashPayAmount'] += courierFareAmount;
                                }
                            } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                                cardRecipientTotalAmount += courierFareAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['cardPayAmount'] += courierFareAmount;
                                }
                            } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['BANK_DEPOSIT']) {
                                bankRecipientTotalAmount += courierFareAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['bankPayAmount'] += courierFareAmount;
                                }
                            } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['CHEQUE']) {
                                chequeRecipientTotalAmount += courierFareAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['chequePayAmount'] += courierFareAmount;
                                }
                            } else if (stopPoint['executed_payment_method_courier_fare'] === this.globals.paymentOptionsConstants['DAY_CHEQUE']) {
                                chequeDayRecipientTotalAmount += courierFareAmount;
                                if (validatedCollaborator.isValid) {
                                    collaboratorAmounts[collaboratorName]['chequePayAmount'] += courierFareAmount;
                                }
                            }
                        }
                    }
                }
            }
        });

        const collaboratorAmountsArray = [];
        Object.keys(collaboratorAmounts).forEach(collaboratorName => {
            collaboratorAmountsArray.push({
                name: collaboratorName,
                data: {
                    cashPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['cashPayAmount']).toFixed(2)),
                    cardPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['cardPayAmount']).toFixed(2)),
                    bankPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['bankPayAmount']).toFixed(2)),
                    chequePayAmount: Number(Number(collaboratorAmounts[collaboratorName]['chequePayAmount']).toFixed(2)),
                    canceledPayAmount: Number(Number(collaboratorAmounts[collaboratorName]['canceledPayAmount']).toFixed(2))
                }
            });
        });

        // get collaborators totals
        let cashTotalAmount = 0, cardTotalAmount = 0, bankTotalAmount = 0, chequeTotalAmount = 0;
        collaboratorAmountsArray.forEach(collaborator => {
            cashTotalAmount += collaborator['data']['cashPayAmount'];
            cardTotalAmount += collaborator['data']['cardPayAmount'];
            bankTotalAmount += collaborator['data']['bankPayAmount'];
            chequeTotalAmount += collaborator['data']['chequePayAmount'];
        });

        const amounts = {
            repository: Number(repository).toFixed(2),
            cashPayAmount: Number(cashPayAmount).toFixed(2),
            cardPayAmount: Number(cardPayAmount).toFixed(2),
            cashRecipientAmount: Number(cashRecipientTotalAmount).toFixed(2),
            cardRecipientAmount: Number(cardRecipientTotalAmount).toFixed(2),
            cashTotal: Number(cashPayAmount + repository + cashRecipientTotalAmount).toFixed(2),
            cardTotal: Number(cardPayAmount + cardRecipientTotalAmount).toFixed(2),
            bankTotal: Number(bankPayAmount + bankRecipientTotalAmount).toFixed(2),
            chequeTotal: Number(chequePayAmount + chequeRecipientTotalAmount + chequeDayPayAmount + chequeDayRecipientTotalAmount).toFixed(2),
            bankPayAmount: Number(bankPayAmount).toFixed(2),
            bankRecipientAmount: Number(bankRecipientTotalAmount).toFixed(2),
            chequeCombinedPayAmount: Number((chequePayAmount + chequeDayPayAmount)).toFixed(2),
            chequeCombinedRecipientAmount: Number((chequeRecipientTotalAmount + chequeDayRecipientTotalAmount).toFixed(2)),
            chequePayAmount: Number(chequePayAmount).toFixed(2),
            chequeRecipientAmount: Number(chequeRecipientTotalAmount).toFixed(2),
            chequeDayPayAmount: Number(chequeDayPayAmount).toFixed(2),
            chequeDayRecipientAmount: Number(chequeDayRecipientTotalAmount).toFixed(2),
            canceledPayAmount: Number(canceledPayAmount).toFixed(2),
            collaboratorAmounts: collaboratorAmounts,
            collaboratorAmountsArray: collaboratorAmountsArray
        };

        console.log(amounts);

        this.amounts = amounts;
        this.paymentsOverviewComponent.amounts = this.amounts;
        this.paymentsOverviewComponent.driverName = this.driverName;
        this.driverPayments = [...this.driverPayments];
        this.paymentsOverviewComponent.driverPayments = [...this.driverPayments];
        this.paymentsOverviewComponent.overviewType = 'driverReport';
        this.owedAmount = Number(Number(Number(amounts['cashTotal']) + Number(amounts['cardTotal']) + Number(amounts['bankTotal']) + Number(amounts['chequeTotal'])).toFixed(2));
    }

    onCancelledGridReady(params) {
        this.gridCancelledApi = params.api;
        this.gridCancelledColumnApi = params.columnApi;
    }

    onDelaysGridReady(params) {
        this.gridDelaysApi = params.api;
        this.gridDelaysColumnApi = params.columnApi;
    }

    rowClicked(event) {
        // this.viewProjectProblemService.openStopModal(event.data.id, event.data.projectProblemId);
    }

    getContextMenuItems(params) {
        let rowData = params.node.data;
        let result = [
            {
                name: rowData.copyLabel,
                cssClasses: ['grid-context-menu-item'],
                action: () => {
                    navigator.clipboard.writeText(rowData.details.barcode);
                },
                disabled: rowData?.details.barcode ? false : true
            }
        ];

        return result;
    }

    receivedChecked() {
        if (this.isPayOnDeliveryReturnedChecked) {
            this.isPayOnDeliveryReturnedChecked = false;
        } else {
            this.isPayOnDeliveryReturnedChecked = true;
        }
    }

    handedCheckboxSet() {
        if (this.isWarehouseHandedChecked) {
            this.isWarehouseHandedChecked = false;
            this.gridCancelledApi.forEachNode((row, index) => {
                this.handedStopPointIds = [];
                this.gridCancelledApi.getRowNode(row.id).selectThisNode(false);
            });
        } else {
            this.isWarehouseHandedChecked = true;
            this.gridCancelledApi.forEachNode((row, index) => {
                this.handedStopPointIds.push(row.data.id);
                this.gridCancelledApi.getRowNode(row.id).selectThisNode(true);
            });
        }
    }

    loadAndOpen(id, date, projectProblemId) {
        this.projectProblemId = projectProblemId;
        this.id = id;
        this.date = date;
        this.openModal();
        setTimeout(() => {
            this.getStats(id, date);
            this.setDelaysBox(false);
        }, 100);
    }

    saveAndClose() {
        const selectedRows = this.getSelectedRows();
        const idsHanded = [];
        const idsToHandedDatetime = {};
        selectedRows.forEach(row => {
            idsHanded.push(row.id);
            idsToHandedDatetime[row.id] = row.handedDatetime;
        });
        const handedData = {
            stopPoints: []
        };
        idsHanded.forEach(id => {
            const handedDatetime = idsToHandedDatetime[id] ? idsToHandedDatetime[id] : moment().utc().format().replace('Z', '+00:00');
            const stopPointData = {
                stopPoint: {
                    stopPointId: id,
                    project_problem_id: this.projectProblemId,
                    warehouse_handed: {
                        warehouse_handed_datetime: handedDatetime
                    }
                },
            };
            handedData.stopPoints.push(stopPointData);
        });
        const self = this;
        // setTimeout(function () {
        self.gridCancelledApi.forEachNode(function (node, index) {
            if (!node.isSelected() && node.data.handedDatetime) {
                const stopPointData = {
                    stopPoint: {
                        stopPointId: node.data.id,
                        project_problem_id: self.projectProblemId,
                        warehouse_handed: {
                            warehouse_handed_datetime: null
                        }
                    },
                };
                handedData.stopPoints.push(stopPointData);
            }
        });
        if (handedData.stopPoints.length) {
            self.http.put('api/v1/stop-points', handedData).pipe(take(1)).subscribe(response => {
                if (response['items']) {
                    response['items'].forEach(stopPointResponseData => {
                        self.projectProblemDataService.updateSingleStopPoint(stopPointResponseData);
                    });
                }
                // self.closeModal();
            });
        }

        // send received payments
        const payOnDeliveryReturnedObj = {
            stopPoints: [],
            postFinishStats: [
                {
                    driver_id: this.driverId,
                    projectProblemId: this.projectProblemId,
                    is_pay_on_delivery_returned: this.isPayOnDeliveryReturnedChecked,
                    are_stop_points_returned_to_warehouse: this.isWarehouseHandedChecked
                }
            ]
        };

        let paymentStatusValue = this.globals.paymentStatusConstants;
        if (this.isPayOnDeliveryReturnedChecked) {
            paymentStatusValue = this.globals.paymentStatusConstants['COMPANY'];
        } else {
            paymentStatusValue = this.globals.paymentStatusConstants['DRIVER'];
        }

        this.stopPointsInRepository.forEach(stopPoint => {
            payOnDeliveryReturnedObj.stopPoints.push({
                stopPoint: {
                    project_problem_id: this.projectProblemId,
                    stopPointId: stopPoint.id,
                    payment_status: paymentStatusValue
                }
            });
        });

        self.http.put('api/v1/stop-points', payOnDeliveryReturnedObj).pipe(take(1)).subscribe(response => {
            // self.closeModal();
        });


        self.closeModal();
        // }, 100);
    }

    openModal() {
        this.isModalOpen = true;
        this.genericService.isInDriverReportModal = true;
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();
            const modal = document.querySelector('#driver-report-modal');
            const modalBackground = document.querySelector('#driver-report-modal-background');
            modal.classList.remove('closed');
            modal.classList.add('open');
            modalBackground.classList.remove('hidden');
        }, 50);
    }

    closeModal() {
        this.genericService.isInDriverReportModal = true;
        const modal = document.querySelector('#driver-report-modal');
        const modalBackground = document.querySelector('#driver-report-modal-background');
        modal.classList.add('closed');
        modal.classList.remove('open');
        modalBackground.classList.add('hidden');
        this.emptyModal();

        setTimeout(() => {
            this.isModalOpen = false;
        }, 1000);
    }

    emptyModal() {
        this.delaysGridArray = [];
        this.canceledGridArray = [];
        // these are commented because this causes the grid to empty and mot be able to refill after
        // this.delaysRowData = of(this.delaysGridArray);
        // this.canceledRowData = of(this.canceledGridArray);

        this.driverId = null;
        this.driverName = null;
        this.totalRating = 0;
        this.completedPoints = 0;
        this.totalPoints = 0;
        this.pointsPercentage = 0;
        this.duration = '-';
        this.distance = 0;
        this.owedAmount = 0;
        this.amounts = {};
        this.durationDifference = '';
        this.isDelaysBoxMarkedOpen = false;
        this.isPayOnDeliveryReturnedChecked = false;
        this.isWarehouseHandedChecked = false;
        this.isWarehouseHandedCheckboxDisabled = false;
        this.driverPayments = [];
        this.setDelaysBox(false);
    }

    setDelaysBox(open) {
        if (!this.isDelaysBoxMarkedOpen) {
            const delaysHoverBox = document.getElementById('delays-hover-box')
            if (delaysHoverBox) {
                if (open) {
                    delaysHoverBox.style.display = 'block';
                } else {
                    delaysHoverBox.style.display = 'none';
                }
            }
        }
    }

    markOpenDelaysBox() {
        const delaysHoverBox = document.getElementById('delays-hover-box')
        if (delaysHoverBox) {
            if (this.isDelaysBoxMarkedOpen) {
                this.isDelaysBoxMarkedOpen = false;
                delaysHoverBox.style.display = 'none';
            } else {
                this.isDelaysBoxMarkedOpen = true;
                delaysHoverBox.style.display = 'block';
            }
        }
    }

    getSelectedRows() {
        return this.gridCancelledApi.getSelectedRows();
    }

    // export warehouse (aka. canceled) stop points to xls
    exportToXls() {
        if (this.canceledGridArray) {
            if (this.canceledGridArray.length) {
                const excelParams = {
                    processCellCallback: formattingFunction,
                    allColumns: true,
                    fileName: this.genericUtils.removeDotsFromString('lastmily_canceled_' + this.driverName.replace(' ', '_') + '_' + moment().format('D-M-YYYY'))
                };
                function formattingFunction(params) {
                    if (params.value) {
                        if (params.value.name || params.value.name === '') {
                            return params.value.name;
                        } else if (params.value.load || params.value.weight) {
                            let label = '';
                            if (params.value.load) {
                                label += params.value.load + 'lt ';
                            }
                            if (params.value.weight) {
                                label += params.value.weight + 'kg ';
                            }
                            return label;
                        } else if (params.value.statusValue) {
                            return params.value.statusValue.replace('<br />', ''); // fix for line break in xls cell
                        } else if (params.value.barcode || params.value.serial) {
                            let barcode, serial, barcodeAndSerial;
                            if (params.value.barcode) {
                                barcode = params.value.barcode;
                            }
                            if (params.value.serial) {
                                serial = params.value.serial;
                            }

                            if (barcode && serial) {
                                barcodeAndSerial = String(barcode) + '/' + String(serial);
                            } else if (barcode && !serial) {
                                barcodeAndSerial = String(barcode);
                            } else if (!barcode && serial) {
                                barcodeAndSerial = String(serial);
                            }
                            return barcodeAndSerial;
                        } else {
                            return params.value;
                        }
                    }
                }
                this.gridCancelledApi.exportDataAsExcel(excelParams);
            }
        }
    }


    // renderers
    typeRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            let icon = '';
            if (params.getValue() === 'start') {
                icon = '<i class="fas fa-home"></i>';
            } else if (params.getValue() === 'route') {
                icon = '<i class="fas fa-road"></i>';
            } else if (params.getValue() === 'load') {
                icon = '<i class="fas fa-arrow-down"></i>';
            } else if (params.getValue() === 'unload') {
                icon = '<i class="fas fa-arrow-up"></i>';
            }
            columnObject += '<div class="light-grey-letters padding-left-5px font-size-1-1em">' + icon + '</div>';
        }
        return columnObject;
    }

    whereRenderer(params) {
        let columnObject = '';
        if (params.getValue() && params.data.type) {
            let content = '';
            if (params.data.type === 'start') {
                content = this.startDelayText;
            } else if (params.data.type === 'route') {
                content += params.getValue();
                content += ' <span class="light-grey-letters margin-5px"><i class="fas fa-arrow-right"></i></span> ';
                content += Number(Number(params.getValue()) + 1);
            } else {
                content = params.getValue();
            }
            columnObject += '<div class="">' + content + '</div>';
        }
        return columnObject;
    }

    detailsRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="double-cell">' + params.getValue().barcode + '</div>';
            columnObject += '<div class="double-cell">' + params.getValue().serial + '</div>';
        }
        return columnObject;
    }

    canceledRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="voucher-status-container">' + '<div class="single-cell standard-width ' + params.getValue().colorClass + '">' + params.getValue().statusValue + '</div>' + '</div>';
        }
        return columnObject;
    }

    nameRenderer(params) {
        let columnObject = '';
        if (params.getValue()) {
            columnObject += '<div class="grid-wrap-text" title="' + params.getValue() + '">' + params.getValue() + '</div>';
        }
        return columnObject;
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.TYPE').subscribe((res: string) => { this.typeTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.WHERE').subscribe((res: string) => { this.whereTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.TIME').subscribe((res: string) => { this.timeTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.DETAILS').subscribe((res: string) => { this.detailsTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.REASON').subscribe((res: string) => { this.statusTitle = res; }));
        this.listen.push(this.translate.get('STOP_POINT.VOLUME_WEIGHT').subscribe((res: string) => { this.loadTitle = res; }));
        this.listen.push(this.translate.get('REPORTS.START_DELAY').subscribe((res: string) => { this.startDelayText = res; }));
        this.listen.push(this.translate.get('REPORTS.TOTAL_DELAY').subscribe((res: string) => { this.totalDelayMsg = res; }));
        this.listen.push(this.translate.get('REPORTS.DRIVER_OF_THE_DAY').subscribe((res: string) => { this.driverOfTheDayMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.HOURS_SHORT').subscribe((res: string) => { this.hoursShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.MINUTES_SHORT').subscribe((res: string) => { this.minutesShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.SECONDS_SHORT').subscribe((res: string) => { this.secondsShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.KM').subscribe((res: string) => { this.kilometersShortMsg = res; }));
        this.listen.push(this.translate.get('GENERIC.LITERS').subscribe((res: string) => { this.litersText = res; }));
        this.listen.push(this.translate.get('STOP_POINT.COPY_BARCODE').subscribe((res: string) => { this.copyLabel = res; }));

        this.delaysColumnDefs = [
            {
                headerName: this.typeTitle,
                field: 'type',
                cellRenderer: this.typeRenderer,
                sortable: false,
                width: this.gridsService.widthCalculator(10)
            },
            {
                headerName: this.whereTitle,
                field: 'where',
                cellRenderer: this.whereRenderer.bind(this),
                sortable: false,
                width: this.gridsService.widthCalculator(18)
            },
            {
                headerName: this.timeTitle,
                field: 'time',
                sortable: false,
                width: this.gridsService.widthCalculator(10)
            },
        ];

        this.canceledColumnDefs = [
            {
                headerName: this.nameTitle,
                cellRenderer: this.nameRenderer,
                field: 'name',
                width: this.gridsService.widthCalculator(10),
                checkboxSelection: true,
            },
            {
                headerName: this.detailsTitle,
                field: 'details',
                sortable: false,
                cellRenderer: this.detailsRenderer,
                width: this.gridsService.widthCalculator(13)
            },
            {
                headerName: this.statusTitle,
                field: 'status',
                sortable: false,
                cellRenderer: this.canceledRenderer.bind(this),
                width: this.gridsService.widthCalculator(17)
            },
            {
                headerName: this.loadTitle,
                field: 'load',
                sortable: false,
                cellRenderer: this.gridsService.volumeWeightCountRenderer,
                width: this.gridsService.widthCalculator(10)
            },
        ];
    }

    ngOnInit() {
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));

        // hide delays hover box after delay so that the grid's width calculations are applied normally
        setTimeout(() => {
            this.setDelaysBox(false);
        }, 1000);

        this.getTranslations();
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}
