import { DepotUtils } from '@app/utils/depot-utils';
import { Component, ViewChild, OnInit, OnDestroy, ElementRef, Injector, ViewContainerRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '@app/services/modal.service';
import { Globals } from '@app/services/globals';
import { ModalGridService } from '@app/services/modal-grid.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { ChartService } from '@app/services/chart.service';
import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, tap } from 'rxjs/operators';
import { DriversGridGuidedTourComponent } from './drivers-grid-guided-tour/drivers-grid-guided-tour.component';
import { GridsService } from '@app/services/grids.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsComponent } from '@app/svg-icons/svg-icons.component';
import { LmNotificationService } from '@app/core/services/notification.service';
import { DriversModalComponent } from '@app/drivers/drivers-modal/drivers-modal.component';
import { LmDriversService } from '@app/api/services/drivers.service';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartOptions
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );

@Component({
    selector: 'app-drivers-grid',
    templateUrl: './drivers-grid.component.html',
    styleUrls: ['./../grids.scss', './drivers-grid.component.scss'],
})
export class DriversGridComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DriversGridGuidedTourComponent, { static: false }) driversGridGuidedTourComponent: DriversGridGuidedTourComponent;
    @ViewChild(SvgIconsComponent, { static: false }) svgIconsComponent: SvgIconsComponent;
    @ViewChild('statsContainer', { static: false }) statsContainer;
    @ViewChild('gridContainer', { static: false }) gridContainer;
    @ViewChild('openChartButton', { static: false }) openChartButton;
    @ViewChild('closeChartButton', { static: false }) closeChartButton;
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    driversModal = DriversModalComponent;

    ngAfterViewInit(): void {
        // setTimeout(_=> this.newButtonClicked(), 500)
    }

    listen = [];

    columnDefs;
    gridApi;
    gridColumnApi;
    domLayout = 'autoHeight';
    driversDataArray = [];
    rowData: any;

    searchTextChanged = new Subject<string>();
    searchString: String = '';
    vehicleTypes = [];

    lastWeekLabel;
    lastMonthLabel;
    lastThreeMonthsLabel;
    lastSixMonthsLabel;
    lastYearLabel;
    bicycleLabel = '';
    scooterLabel = '';
    carLabel = '';
    vanLabel = '';
    largeVanLabel = '';
    truckLabel = '';
    allDepotsLabel;
    driversTranslations;

    nameTitle;
    vehicleTitle;
    stopsTitle;
    kmTitle;
    behaviourTitle;
    vehicleTypeTitle;

    ratingLabel;

    chart;
    chartData = {};
    driversNames = [];
    driversShortNames = [];
    rating = [];
    time = [];
    timeLabels = [];
    totalPoints = [];
    averagePoints = [];
    totalWorkingHours = [];
    totalWorkingHoursLabels = [];
    averageWorkingHours = [];
    averageWorkingHoursLabels = [];
    totalDistance = [];
    totalDistanceLabels = [];
    averageDistance = [];
    averageDistanceLabels = [];

    dataLoading = false;
    pageSize = 10;
    currentChartPage = 0;
    firstPage = true;
    lastPage = false;
    driversIds = '';
    charts = [];
    selectedChart;
    selectedDepot;
    periods = [];
    depots = [];
    selectedPeriod;

    chartExpanded = true;
    chartDisabled = false;
    totalDriversCount;

    constructor(
        public translate: TranslateService,
        private http: HttpClient,
        private modalService: ModalService,
        public globals: Globals,
        private modalGridService: ModalGridService,
        private chartService: ChartService,
        public gridsService: GridsService,
        private sanitizer: DomSanitizer,
        private depotUtils: DepotUtils,
        private notificationSvc: LmNotificationService,
        private _injector: Injector,
        private _vcr: ViewContainerRef,
        private driversService: LmDriversService,
    ) {
        this.listen.push(this.modalGridService.listenUpdateDriverGrid().subscribe((response) => {
            this.setDriversGridData();
        }));
    }

    vehicleRenderer(params) {
        let columnObject = '';
        let make = '';
        let model = '';
        if (params.getValue()) {
            if (params.getValue().make) {
                make = params.getValue().make;
            }
            if (params.getValue().model) {
                model = params.getValue().model;
            }
            const makeAndModel = make + ' - ' + model;
            columnObject += '<div class="double-cell bold-letters standard-width" title="' + makeAndModel + '">' + makeAndModel + '</div>';
            if (params.getValue().plate_number) {
                columnObject += '<div class="double-cell">' + params.getValue().plate_number + '</div>';
            }
        }
        return columnObject;
    }

    vehicleTypeRenderer(params) {
        let columnObject = '';
        columnObject += '<div class="display-flex"><div>';
        if (params.getValue() !== null) {
            if (this.vehicleTypes[params.getValue()]) {
                columnObject += '<div class="vehicle-icon-cell">' + this.vehicleTypes[params.getValue()].image + '</div>';
                columnObject += '<div class="vehicle-cell">' + this.vehicleTypes[params.getValue()].typeName + '</div>';
            }
        } else {
            columnObject += '<div>-</div>';
        }
        columnObject += '</div>';
        return columnObject;
    }

    getDataForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'rating':
                data = this.rating;
                break;
            case 'time':
                data = this.time;
                break;
            case 'average-points':
                data = this.averagePoints;
                break;
            case 'total-points':
                data = this.totalPoints;
                break;
            case 'average-hours':
                data = this.averageWorkingHours;
                break;
            case 'total-hours':
                data = this.totalWorkingHours;
                break;
            case 'average-distance':
                data = this.averageDistance;
                break;
            case 'total-distance':
                data = this.totalDistance;
                break;
        }
        return data;
    }

    getLabelsForChart() {
        let data = [];
        switch (this.selectedChart.value) {
            case 'rating':
                data = this.rating;
                break;
            case 'time':
                data = this.timeLabels;
                break;
            case 'average-points':
                data = this.averagePoints;
                break;
            case 'total-points':
                data = this.totalPoints;
                break;
            case 'average-hours':
                data = this.averageWorkingHoursLabels;
                break;
            case 'total-hours':
                data = this.totalWorkingHoursLabels;
                break;
            case 'average-distance':
                data = this.averageDistanceLabels;
                break;
            case 'total-distance':
                data = this.totalDistanceLabels;
                break;
        }
        return data;
    }

    initChart() {
        const ctx = <HTMLCanvasElement>document.getElementById('drivers-chart');
        const data = this.getDataForChart();
        if (this.chart) { this.chart.destroy(); }
        const chartOptions:ChartOptions = JSON.parse(JSON.stringify(this.chartService.chartOptions));
        if (this.selectedChart.value === 'rating') {
            // chartOptions.scales.yAxes[0].ticks['suggestedMin'] = 0;
            // chartOptions.scales.yAxes[0].ticks['suggestedMax'] = 5;
            // chartOptions.scales.yAxes[0].ticks['stepSize'] = 1;
        }
        const driversNames = this.driversNames;
        const labels = this.getLabelsForChart();
        const totalWorkingHoursLabels = this.totalWorkingHoursLabels;
        chartOptions['tooltips'] = {
            callbacks: {
                title: function (tooltipItem, tooltipData) {
                    return driversNames[tooltipItem[0].index];
                },
                label: function (tooltipItem, tooltipData) {
                    return labels[tooltipItem.index];
                }
            }
        };
        if (ctx) {
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.driversShortNames,
                    datasets: [{
                        barPercentage: 0.9,
                        barThickness: 'flex',
                        maxBarThickness: 150,
                        minBarLength: 2,
                        data: data,
                        backgroundColor: (() => {
                            const backgroundColours = [];
                            for (let i = 0; i < data.length; ++i) {
                                backgroundColours.push(this.chartService.chartColours[i % this.chartService.chartColours.length]);
                            }
                            return backgroundColours;
                        })()
                    }]
                },
                options: chartOptions
            });
        }
    }

    openChart() {
        this.chartExpanded = true;
        this.statsContainer.nativeElement.classList.add('expanded');
        this.gridContainer.nativeElement.classList.add('reduced');
        this.openChartButton.nativeElement.classList.add('active');
        this.closeChartButton.nativeElement.classList.remove('active');
    }

    closeChart() {
        this.chartExpanded = false;
        this.statsContainer.nativeElement.classList.remove('expanded');
        this.gridContainer.nativeElement.classList.remove('reduced');
        this.openChartButton.nativeElement.classList.remove('active');
        this.closeChartButton.nativeElement.classList.add('active');
    }

    emptyChartArrays() {
        this.driversNames = [];
        this.driversShortNames = [];
        this.time = [];
        this.timeLabels = [];
        this.rating = [];
        this.totalPoints = [];
        this.totalDistance = [];
        this.totalDistanceLabels = [];
        this.totalWorkingHours = [];
        this.totalWorkingHoursLabels = [];
        this.averagePoints = [];
        this.averageDistance = [];
        this.averageDistanceLabels = [];
        this.averageWorkingHours = [];
        this.averageWorkingHoursLabels = [];
    }

    getFirstWord(str) {
        return str.split(' ')[0];
    }

    loadNextChartPage() {
        if (!this.lastPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage++;
            this.getChartData();
        }
    }

    loadPreviousChartPage() {
        if (!this.firstPage && !this.dataLoading) {
            this.dataLoading = true;
            this.currentChartPage--;
            this.getChartData();
        }
    }

    displayDummyChartValues() {
        this.driversShortNames = [];
        for (let i = 0; i < 10; i++) {
            this.driversShortNames.push('');
        }
        this.rating = [5, 4.8, 4.8, 4.3, 4, 3.8, 3.7, 3.3, 3, 3];
        this.totalPoints.push(15, 12, 16, 17, 13, 20, 21, 9, 12, 15);
        this.totalDistance.push(12, 23, 13, 11, 14, 21, 19, 20, 17, 20);
        this.totalWorkingHours.push(8, 6, 8, 4, 8, 6, 7, 6, 8, 6);
        this.time.push(8, 6, 8, 4, 8, 6, 7, 6, 8, 6);
        this.averagePoints.push(15, 12, 16, 17, 13, 20, 21, 9, 12, 15);
        this.averageDistance.push(12, 23, 13, 11, 14, 21, 19, 20, 17, 20);
        this.averageWorkingHours.push(8, 6, 8, 4, 8, 6, 7, 6, 8, 6);
        this.chartDisabled = true;
    }

    emptyGrid() {
        this.driversDataArray = [{ noDataText: 'No data' }];
        this.rowData = of(this.driversDataArray);
    }

    onDepotChanged() {
        this.chartPeriodChange();
        this.setDriversGridData();
    }

    checkIfAllZero(): Boolean {
        let allZero = true;
        this.rating.forEach(element => {
            if (element !== 0) {
                allZero = false;
            }
        });
        return allZero;
    }

    getChartData() {
        this.dataLoading = true;
        // let params = '?pageSize=' + this.pageSize;
        // params += '&page=' + this.currentChartPage;
        // params += '&ids=' + this.driversIds;
        // params += '&date=' + moment().utc().format();
        // params += '&days=' + this.selectedPeriod.value;
        if (this.currentChartPage === 0) {
            this.firstPage = true;
        } else {
            this.firstPage = false;
        }

        let groupByValue = 'daily';
        if (this.selectedPeriod.value == 7 || this.selectedPeriod.value == 30) {
            groupByValue = 'daily';
        } else {
            groupByValue = 'monthly';
        }
        let newParams = '?daysBeforeUntilDateCount=' + this.selectedPeriod.value;
        newParams += '&untilDateTime=' + moment().utc().format() + '&groupBy=' + groupByValue;

        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            newParams += `&depotId=${this.selectedDepot.companyDepot.id}`;
        }

        // this.http.get('api/v1/stats/driver-stats' + params).pipe(take(1)).subscribe(response => {
        this.http.get('api/internal/v1/statistics/driver-statistics' + newParams).pipe(take(1)).subscribe(response => {
            this.emptyChartArrays();
            this.dataLoading = false;
            const data = response['items'];
            if (Object.keys(data).length) {
                if (Object.keys(data).length < this.pageSize) {
                    this.lastPage = true;
                } else if (Object.keys(data).length === this.pageSize) {
                    this.lastPage = false;
                }
                Object.keys(data).forEach(id => {
                    // if (!this.driversIdsArray.includes(id)) {
                    //     this.driversIdsArray.push(id);
                    //     if (this.driversIds !== '') { this.driversIds += ','; }
                    //     this.driversIds += id;
                    // }
                    if (data[id].total_shipments || data[id].total_shipments === 0) {
                        this.chartData[id] = data[id];
                        this.driversNames.push(data[id].name);
                        if (data[id].name) {
                            this.driversShortNames.push(this.getFirstWord(data[id].name));
                        }
                        if (data[id].total_rating > 5) {
                            this.rating.push(5);
                        } else {
                            this.rating.push(data[id].total_rating);
                        }

                        this.timeLabels.push(this.calculateHoursAndMinutesString(data[id].total_driving_delay));
                        this.time.push(moment.duration(data[id].total_driving_delay).asHours());

                        this.totalPoints.push(data[id].total_shipments);
                        this.totalDistance.push(Math.round(data[id].total_driven_distance / 1000));
                        this.totalDistanceLabels.push(Math.round(data[id].total_driven_distance / 1000) + 'km');

                        this.totalWorkingHoursLabels.push(this.calculateHoursAndMinutesString(data[id].average_stop_over_duration_per_shipment));
                        this.totalWorkingHours.push(moment.duration(data[id].average_stop_over_duration_per_shipment).asHours());

                        const completedRoutes = data[id].completed_routes;
                        if (completedRoutes) {
                            this.averagePoints.push(Math.round(data[id].total_shipments / data[id].completed_routes));

                            this.averageDistance.push(Math.round((data[id].total_driven_distance / 1000) / data[id].completed_routes));
                            this.averageDistanceLabels.push(Math.round((data[id].total_driven_distance / 1000) / data[id].completed_routes) + 'km');

                            const averageWorkingHours = moment.duration(data[id].average_stop_over_duration_per_shipment).asHours() / completedRoutes;
                            this.averageWorkingHours.push(averageWorkingHours);
                            const hours = averageWorkingHours > 0 ? Math.floor(averageWorkingHours) : Math.ceil(averageWorkingHours);
                            const minutesUnformatted = averageWorkingHours > 0 ? averageWorkingHours - hours : hours - averageWorkingHours;
                            const minutes = Math.floor(moment.duration(minutesUnformatted, 'hours').asMinutes());
                            const averageWorkingHoursLabel = hours + 'h ' + minutes + 'm';
                            this.averageWorkingHoursLabels.push(averageWorkingHoursLabel);
                        } else {
                            this.averagePoints.push(0);
                            this.averageDistance.push(0);
                            this.averageWorkingHours.push(0);
                            this.averageWorkingHoursLabels.push(0);
                        }
                    }
                });

                this.chartDisabled = false;

                if (this.checkIfAllZero()) {
                    this.displayDummyChartValues();
                }
            } else {
                this.lastPage = true;
                this.displayDummyChartValues();
            }
            this.initChart();
        });
    }

    calculateHoursAndMinutesString(duration) {
        const durationHours = moment.duration(duration).asHours();
        let durationHoursRound = 0;
        let durationMinutes = 0;
        if (durationHours > 0) {
            durationHoursRound = Math.floor(moment.duration(duration).asHours());
            durationMinutes = moment.duration((durationHours - durationHoursRound), 'hours').asMinutes();
        } else {
            durationHoursRound = Math.ceil(moment.duration(duration).asHours());
            durationMinutes = moment.duration((durationHoursRound - durationHours), 'hours').asMinutes();
        }
        return durationHoursRound + 'h ' + Math.round(durationMinutes) + 'm';
    }

    chartTypeChange() {
        this.initChart();
    }

    chartPeriodChange() {
        this.currentChartPage = 0;
        this.firstPage = true;
        this.lastPage = false;
        this.emptyChartArrays();
        this.getChartData();
    }

    public setDriversGridData() {
        this.emptyGrid();
        const self = this;
        let params = `?searchQuery=${this.searchString}&arePickersEnabled=true`;
        if (this.selectedDepot?.companyDepot?.id && this.selectedDepot?.companyDepot?.id != -1) {
            params += '&depotId=' + String(this.selectedDepot.companyDepot.id);
        }

        this.http.get('api/internal/v2/drivers' + params)
            .pipe(take(1))
            .subscribe(response => {
                self.driversDataArray = [];
                let make, model, gridObject = {};
                this.driversIds = '';
                this.totalDriversCount = response['items'].length;
                if (response['items'].length) {
                    response['items'].forEach(driverData => {
                        make = '';
                        model = '';
                        const driver = driverData.driver;
                        const userProfile = driverData.userProfile;
                        if (this.driversIds !== '') { this.driversIds += ','; }
                        this.driversIds += driver.id;
                        if (driverData.vehicleInfoMake.name) {
                            make = driverData.vehicleInfoMake.name;
                        } else if (driverData.vehicleInfoMakeCompany) {
                            if (driverData.vehicleInfoMakeCompany.name) {
                                make = driverData.vehicleInfoMakeCompany.name;
                            }
                        }
                        if (driverData.vehicleInfoModel.name) {
                            model = driverData.vehicleInfoModel.name;
                        } else if (driverData.vehicleInfoModelCompany.name) {
                            model = driverData.vehicleInfoModelCompany.name;
                        }
                        gridObject = {
                            id: driver.id,
                            name: {
                                name: userProfile.name,
                                phone: userProfile.telephone
                            },
                            vehicle: {
                                make: make,
                                model: model,
                                plate_number: driverData.vehicle ? driverData.vehicle.plate_number : '',
                            },
                            vehicle_type: driverData.vehicle ? driverData.vehicle.vehicle_type : null,
                        };
                        self.driversDataArray.push(gridObject);
                    });
                } else {
                    const noData = { noDataText: 'No data' }
                    self.driversDataArray.push(noData);
                }
                self.rowData = of(self.driversDataArray);
                this.getChartData();
            },
                (error) => {
                    console.error(error);
                });
    }

    getDriver(id:number){
        return this.driversService.apiDriversGet({ id: id.toString() })
    }

    openDriversModal() {
        const data = {
            globals: this.globals, 
            modalGridService: this.modalGridService,
            id: null, 
            translations: this.driversTranslations,
            values: {}
        }
        this.notificationSvc
        .showDialog<boolean>({ componentType: this.driversModal, injector: this._injector, vcr: this._vcr, data: data})
        .subscribe();
    }

    newButtonClicked(): void {
        this.openDriversModal();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    private transformDriverData(res){
        return res
    }

    rowClicked(event) {
        if (!event.data.noDataText) {            
            const data = {
                globals: this.globals, 
                modalGridService: this.modalGridService,
                id: event.data.id, 
                translations:this.driversTranslations,
                values: {}
            }
            this.getDriver(event.data.id)
            .pipe(
                tap(res => data.values = this.transformDriverData(res['item'])),
                tap(_=> this.notificationSvc.showDialog<boolean>({ componentType: this.driversModal, injector: this._injector, data: data })),
            ).subscribe()
        }
    }

    startTour() {
        this.driversGridGuidedTourComponent.startGuide();
    }

    search($event) {
        this.searchTextChanged.next($event);
    }

    setVehicleTypes() {
        const bicycle = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle0);
        const scooter = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle1);
        const car = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle2);
        const van = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle3);
        const largeVan = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle4);
        const truck = this.sanitizer.bypassSecurityTrustHtml(this.svgIconsComponent.vehicle5);

        this.vehicleTypes = [
            {
                index: 0,
                image: bicycle,
                typeName: this.bicycleLabel
            },
            {
                index: 1,
                image: scooter,
                typeName: this.scooterLabel
            },
            {
                index: 2,
                image: car,
                typeName: this.carLabel
            },
            {
                index: 3,
                image: van,
                typeName: this.vanLabel
            },
            {
                index: 4,
                image: largeVan,
                typeName: this.largeVanLabel
            },
            {
                index: 5,
                image: truck,
                typeName: this.truckLabel
            }
        ];
    }

    getTranslations() {
        this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
        this.listen.push(this.translate.get('PROJECT.RATING').subscribe((res: string) => { this.ratingLabel = res; }));
        this.listen.push(this.translate.get('DRIVER.VEHICLE_DETAILS').subscribe((res: string) => { this.vehicleTitle = res; }));
        this.listen.push(this.translate.get('DRIVER.VEHICLE_TYPE').subscribe((res: string) => { this.vehicleTypeTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.STOPS').subscribe((res: string) => { this.stopsTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.KM').subscribe((res: string) => { this.kmTitle = res; }));
        this.listen.push(this.translate.get('GENERIC.BEHAVIOUR').subscribe((res: string) => { this.behaviourTitle = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.BICYCLE').subscribe((res: string) => { this.bicycleLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.SCOOTER').subscribe((res: string) => { this.scooterLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.CAR').subscribe((res: string) => { this.carLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.VAN').subscribe((res: string) => { this.vanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.LARGE_VAN').subscribe((res: string) => { this.largeVanLabel = res; }));
        this.listen.push(this.translate.get('VEHICLE_TYPES.TRUCK').subscribe((res: string) => { this.truckLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_WEEK').subscribe((res: string) => { this.lastWeekLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_MONTH').subscribe((res: string) => { this.lastMonthLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_THREE_MONTHS').subscribe((res: string) => { this.lastThreeMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_SIX_MONTHS').subscribe((res: string) => { this.lastSixMonthsLabel = res; }));
        this.listen.push(this.translate.get('GENERIC.LAST_YEAR').subscribe((res: string) => { this.lastYearLabel = res; }));
        this.listen.push(this.translate.get('DRIVER').subscribe((res: string) => { this.driversTranslations = res; }));

        this.depots = this.depotUtils.getDepotsWithAllDepotsOption();
        this.selectedDepot = this.depots[0];
        this.charts = [
            {
                label: this.ratingLabel,
                value: 'rating',
            },
            {
                label: 'Time (hours)',
                value: 'time',
            },
            {
                label: 'Average points',
                value: 'average-points',
            },
            {
                label: 'Total points',
                value: 'total-points',
            },
            {
                label: 'Average working hours',
                value: 'average-hours',
            },
            {
                label: 'Total working hours',
                value: 'total-hours',
            },
            {
                label: 'Average distance (km)',
                value: 'average-distance',
            },
            {
                label: 'Total distance',
                value: 'total-distance',
            },
        ];
        this.chartService.setupPeriodsTranslations(this.lastWeekLabel, this.lastMonthLabel, this.lastThreeMonthsLabel, this.lastSixMonthsLabel, this.lastYearLabel);
        this.periods = [...this.chartService.periods];
        this.selectedChart = this.charts[0];
        this.selectedPeriod = this.periods[1];

        this.columnDefs = [
            {
                headerName: this.nameTitle,
                field: 'name',
                cellRenderer: this.gridsService.nameRenderer,
                width: this.gridsService.widthCalculator(40)
            },
            {
                headerName: this.vehicleTypeTitle,
                field: 'vehicle_type',
                sortable: false,
                cellRenderer: this.vehicleTypeRenderer.bind(this),
                width: this.gridsService.widthCalculator(30)
            },
            {
                headerName: this.vehicleTitle,
                field: 'vehicle',
                cellRenderer: this.vehicleRenderer,
                width: this.gridsService.widthCalculator(30)
            },
        ];
        this.setDriversGridData();
    }

    ngOnInit(): void {
        setTimeout(() => {
            let firstTime = true;
            this.getTranslations();
            this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                if (!firstTime) {
                    this.getTranslations();
                }
                firstTime = false;
            }));
            this.setVehicleTypes();
        }, 300);

        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((text: string) => {
                this.setDriversGridData();
            });
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }
}

interface EventObject {
    event: string;
    value: any;
}
