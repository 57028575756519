import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { GridsService } from '@app/services/grids.service';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';

@Component({
  selector: 'stop-points-cc-handle-grid',
  templateUrl: './stop-points-cc-handle-grid.component.html',
  styleUrls: ['./stop-points-cc-handle-grid.component.scss']
})
export class StopPointsCCHandleGridComponent implements OnInit {

  listen = [];

  columnDefs;
  gridApi;
  gridColumnApi;
  domLayout = 'autoHeight';
  stopPointsDataArray = [];
  rowData: any;

  nameTitle;
  barcodeTitle;
  updateTitle;
  noNameLabel = '';
  newMsg;

  stopPointCCHandleOptions;

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public globals: Globals,
    private viewProjectProblemService: ViewProjectProblemService,
    public gridsService: GridsService,
    public stopPointUtils: StopPointUtils,
    public projectProblemDataService: ProjectProblemDataService,
  ) { }

  rowClicked(event) {
    this.viewProjectProblemService.openStopModal(
      event.data.id,
    );
  }

  getStopsGridData() {
    const url = 'api/internal/v2/warehouse/stop-point-issue?depotId=' + this.projectProblemDataService.projectDepotId;

    this.http.get(url).pipe(take(1)).subscribe(response => {
      if (response['items']?.length) {
        const arrayData = this.setGridData(response['items']);
        this.rowData = of(arrayData);
      } else this.rowData = of([]);

    }, error => {
    });
  }

  setGridData(stopPointsArray) {
    this.stopPointsDataArray = [];
    let stopPoint, gridObject = {};
    stopPointsArray.forEach(stopPoint => {
      let name = stopPoint.contact_name;
      if (name === '_NO_NAME') {
        name = this.noNameLabel;
      }

      let isNew = stopPoint.stop_point_cc_review == this.globals.stopPointCCReview['JUST_ADDED'];
      gridObject = {
        id: stopPoint.stop_point_id,
        name: name,
        barcode: stopPoint.barcode,
        update: {
          text: this.stopPointCCHandleOptions.find(option => option.value === stopPoint.stop_point_cc_handle)?.label || "",
          new: isNew,
        },
        fullData: stopPoint,
      };
      this.stopPointsDataArray.push(gridObject);
    });
    return this.stopPointsDataArray;
  }

  updateRenderer(params) {
    let columnObject = '';
    if (params.getValue()) {
      columnObject += params.getValue().text;
      // columnObject += '<div class="text-center">' + params.getValue().text + '</div>';
      if (params.getValue().new) {
        columnObject += '<div class="grid-new-line-tag">' + this.newMsg + '</div>';
      }
    }
    return columnObject;
  }

  resetGrid() {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getTranslations() {
    this.listen.push(this.translate.get('GENERIC.NAME').subscribe((res: string) => { this.nameTitle = res; }));
    this.listen.push(this.translate.get('GENERIC.BARCODE').subscribe((res: string) => { this.barcodeTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.UPDATE').subscribe((res: string) => { this.updateTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.NEW').subscribe((res: string) => { this.newMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.stopPointCCHandleOptions = [
        {
          value: this.globals.stopPointCCHandle.DEFAULT,
          label: res['NONE']
        },
        {
          value: this.globals.stopPointCCHandle.URGENT,
          label: res['URGENT']
        },
        {
          value: this.globals.stopPointCCHandle.PAUSED,
          label: res['PAUSE']
        },
        {
          value: this.globals.stopPointCCHandle.OTHER_DAY,
          label: res['OTHER_DAY']
        },
      ];
    }));

    this.columnDefs = [
      {
        headerName: this.nameTitle,
        field: 'name',
        width: this.gridsService.widthCalculator(20)
      },
      {
        headerName: this.barcodeTitle,
        field: 'barcode',
        width: this.gridsService.widthCalculator(15)
      },
      {
        headerName: this.updateTitle,
        field: 'update',
        cellRenderer: this.updateRenderer.bind(this),
        width: this.gridsService.widthCalculator(15)
      },
    ];
  }

  ngOnInit() {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
