import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StopPointsCCHandleGridComponent } from '@app/grids/stop-points-cc-handle-grid/stop-points-cc-handle-grid.component';

@Component({
  selector: 'app-stop-points-cc-handle-modal-dummy',
  templateUrl: './stop-points-cc-handle-modal-dummy.component.html',
  styleUrls: ['./stop-points-cc-handle-modal-dummy.component.scss'],
})
export class StopPointsCCHandleDummyComponent implements OnInit {

  @ViewChild(StopPointsCCHandleGridComponent, { static: false }) stopPointsCCHandleGridComponent: StopPointsCCHandleGridComponent;
  
  constructor(
    public router: Router,
  ) { }

  @Input() isModalOpen: Boolean;

  @Output() closeButtonPressedEvent = new EventEmitter<string>();

  closeButtonPressed() {
    this.closeButtonPressedEvent.emit('');
  }

  ngOnInit() {
  }

}
