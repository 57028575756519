import { ModalService } from './../../services/modal.service';
import { Component, OnInit, ViewChild, Injectable, OnDestroy, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { StopsModalGridComponent } from '@app/grids/stops-modal-grid/stops-modal-grid.component';
import * as moment from 'moment-timezone';
import { Observable, Subject, concat, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, take } from 'rxjs/operators';
import { DataService } from '@app/services/data.service';
import { StopPointPickupServiceService } from '@app/services/stop-point-pickup-service.service';
import { ProjectProblemDataService } from '@app/services/project-problem-data.service';
import { DraggableMarkerService } from '@app/services/draggableMarker.service';
import { ViewProjectProblemService } from '@app/services/viewProjectProblem.service';
import { AddressService } from '@app/services/address.service';
import { StopPointService } from '@app/services/stop-point.service';
import { Globals } from '@app/services/globals';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DateTimeCalculatorService } from '@app/services/date-time-calculator.service';
import { GenericService } from '@app/services/generic.service';
import * as libphonenumber from 'libphonenumber-js';
import { DepotUtils } from '@app/utils/depot-utils';
import { FormErrorsUtils } from '@app/utils/form-errors-utils';
import { LmNotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-stop-form',
  templateUrl: './stop-form.component.html',
  // styleUrls: ['./stop-form.component.scss']
  styleUrls: ['./stop-form.component.scss', '../../../styles/base/verona-form-migration.scss']
})

@Injectable()
export class StopFormComponent implements OnInit, OnDestroy {

  @ViewChild(StopsModalGridComponent, { static: false }) stopsModalGridComponent: StopsModalGridComponent;
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;
  @Output() toggleModal = new EventEmitter<string>();
  @Output() expandModal = new EventEmitter<string>();
  @Output() updateFormValidity = new EventEmitter<string>();

  isFormValid = false;

  loadStopsUrl = 'api/v1/project/problems/PROJECT_PROBLEM_ID/project-problem-stop-point-loaded';
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  myForm: FormGroup;
  errors = [];
  data;
  isClickedOnce = false;
  projectProblemId = null;
  stopPointProjectProblemId = null;
  projectProblemEntityStatus;
  projectProblemDepartureDatetime;
  projectProblemDayOfWeek;
  stopPointId = null;
  companyId = null;
  address = null;
  serviceTypeRadioButtonSelected = 'delivery';
  serviceTypeSelected = 'delivery';
  noDeliverySelected = false;
  isThroughHub = false;
  serviceType = 1;
  contactName: String = '';
  countryPrefix = '';
  phoneNumber = '';
  telephone = '';
  doubleTimeWindow = false;
  selectedTimeWindow = null;
  selectedTimeWindowId = null;
  customTimeWindows = [];
  timeWindows = [];
  timeWindow = [480, 1200];
  timeWindowDouble = [480, 840, 1020, 1200];
  timeWindowUnformatted = ['08:00', '20:00'];
  durationUnformatted = '02:00';
  durationSeconds = [120];
  duration = '';
  weight = null;
  load = null;
  deliverySourceModelName = '';
  deliverySourceModelId = null;
  pickupDestinationModelName = '';
  pickupDestinationModelId = null;
  final = false;
  entityStatus = 1;
  entityStatusEnabled = true;
  notes = '';
  payAmount = '';
  priority = '';
  priorityOn = false;
  customerEntityType = null;
  addressTerm: String = '';
  freeformAddress = '';
  country = 'GR';
  lat = '';
  lon = '';
  email = '';
  timeZone = '';
  portalAccess = 0;
  portalOn = false;
  portalPlus = false;
  previousAgreedShipping = {};
  agreedShipping = [];
  agreedShippingDate = '';
  agreedShippingDateChanged = false;
  fulfillmentStatus = null;
  fulfillmentEvent = {};
  relatedCustomerId = null;
  timeWindowOptions;
  timeWindowOptionsDouble;
  timeWindowIds = [];
  durationOptions;
  positionSet = false;
  customInput;
  listen = [];
  selectedDepotItem;
  depots = [];
  timeWindowErrorMsg;
  barcode = '';
  orderId = '';
  loaded = false;
  selectedDrivers = [];
  drivers = [];
  selectedPartnerItem = null;
  partners = [];
  addressAutofillInterval;

  items = [];
  isUserStillChangingWeight; // used to trigger changes for volume when changing the weight if volume was empty

  requestedPayOnDeliveryPaymentMethod = null;
  executedPayOnDeliveryPaymentMethod = null;
  chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');
  paymentOptions = [];
  creditCardLabel = '';
  payOnDeliveryLabel = '';
  cashLabel = '';
  bankDepositLabel = '';
  chequeLabel: string;
  chequeDayLabel: string;
  smartPointTooManyItemsText;
  canChangePaymentMethod = true;

  pickupAndDelivery = false;

  inWarehouse = false;
  inGeneralWarehouse = false;

  addresses: Observable<any>;
  addressesLoading = false;
  addressInput = new Subject<string>();

  width = null;
  length = null;
  height = null;
  massAndDimensions = [];

  countryCode = '';
  state = '';
  county = '';
  city = '';
  district = '';
  street = '';
  street2 = '';
  houseNumber = '';
  postalCode = '';
  isPlace = false;
  placeName = '';

  selectedAddress: any = <any>[];

  stopPointSavedLabel;
  noNameLabel = '';
  changeUnavailableEditTitle;
  noNameConstant = '_NO_NAME';

  public stopFormLabels = {
    phone: 'Phone'
  };

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    public router: Router,
    private dataService: DataService,
    private stopPointPickupService: StopPointPickupServiceService,
    private draggableMarkerService: DraggableMarkerService,
    private viewProjectProblemService: ViewProjectProblemService,
    private projectProblemDataService: ProjectProblemDataService,
    private addressService: AddressService,
    private stopPointService: StopPointService,
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    public globals: Globals,
    private dateTimeCalculatorService: DateTimeCalculatorService,
    private genericService: GenericService,
    private depotUtils: DepotUtils,
    private formErrorsUtils: FormErrorsUtils,
    private _notificationSvc: LmNotificationService,
  ) {
    this.initializeSlider();
    this.listen.push(this.stopPointPickupService.loadDeliveryFormListen().subscribe((id) => {
      this.expandModal.emit('true');
    }));
    this.listen.push(this.draggableMarkerService.markerDragListen().subscribe((response) => {
      if (!response.forSameDayDelivery) {
        this.positionSet = true;
        this.lat = response.lat;
        this.lon = response.lng;
        if (this.selectedAddress.label) {
          this.freeformAddress = this.selectedAddress.label;
        }
        this.patchAddresses();
      }
    }));
    // get submitted date for day cheque
    this.listen.push(this.modalService.submitDateListen().subscribe((data) => {
      if (data.customMode == 'podChequeDay') {
        this.chequeCollectionDatePayOnDelivery = moment(data.date).format('YYYY-MM-DD');
        this.myForm.patchValue({
          'stopPoint': {
            'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
          }
        });
      }
      M.updateTextFields();
    }));
    this.myForm = formBuilder.group({
      'company_partner': [this.selectedPartnerItem],
      'stopPoint': formBuilder.group({
        'withVoucher': false,
        'id': [this.stopPointId],
        'project_problem_id': [this.stopPointProjectProblemId],
        'stopPointId': [this.stopPointId],
        'service_type': [this.serviceType],
        'serviceTypeRadioButtonSelected': [this.serviceTypeRadioButtonSelected],
        'no_delivery': [this.noDeliverySelected],
        'isThroughHub': [this.isThroughHub],
        'contact_name': [this.contactName],
        'pay_amount': [this.payAmount],
        'requested_pay_on_delivery_payment_method': [this.requestedPayOnDeliveryPaymentMethod],
        'executed_pay_on_delivery_payment_method': [this.executedPayOnDeliveryPaymentMethod],
        'cheque_collection_date_pay_on_delivery': [this.chequeCollectionDatePayOnDelivery],
        'countryPrefix': [this.countryPrefix],
        'phoneNumber': [this.phoneNumber],
        'telephone': [this.telephone],
        'companyTimeWindow': [this.selectedTimeWindow],
        'companyTimeWindowCategoryId': [this.selectedTimeWindowId],
        'doubleTimeWindow': [this.doubleTimeWindow],
        'time_windows': [this.timeWindows],
        'time_window_unformatted': [this.timeWindow],
        'time_window_unformatted2': [this.timeWindowDouble],
        'duration_seconds': [this.durationSeconds],
        'duration': [this.duration],
        'weight': [this.weight],
        'load': [this.load],
        'final': [this.final],
        'entity_status': [this.entityStatus],
        'entity_status_enabled': [this.entityStatusEnabled],
        'customerEntityType': [this.customerEntityType],
        'relatedCustomerId': [this.relatedCustomerId],
        'note': [this.notes],
        'priority': [this.priority],
        'priorityOn': [this.priorityOn],
        'depot': [this.selectedDepotItem],
        'portal_access': [this.portalAccess],
        'portalOn': [this.portalOn],
        'portalPlus': [this.portalPlus],
        'agreedShippingDate': [this.agreedShippingDate],
        'agreed_shipping': [this.agreedShipping],
        'fulfillment_status': [this.fulfillmentStatus],
        'fulfillment_event': [this.fulfillmentEvent],
        'barcode': [this.barcode],
        'order_id': [this.orderId],
        'loaded': [this.loaded],
        'selectedDrivers': [{ value: this.selectedDrivers, disabled: this.stopPointId ? false : true }],
        'deliverySources': formBuilder.group({
          'modelName': [this.deliverySourceModelName],
          'modelId': [this.deliverySourceModelId],
        }),
        'pickupDestinations': formBuilder.group({
          'modelName': [this.pickupDestinationModelName],
          'modelId': [this.pickupDestinationModelId],
        }),
        'address': formBuilder.group({
          'countryCode': [this.countryCode],
          'state': [this.state],
          'county': [this.county],
          'city': [this.city],
          'district': [this.district],
          'street': [this.street],
          'street2': [this.street2],
          'houseNumber': [this.houseNumber],
          'postalCode': [this.postalCode],
          'isPlace': [this.isPlace],
          'placeName': [this.placeName],
          'value': [this.freeformAddress, Validators.required],
          'lat': [this.lat],
          'lon': [this.lon],
          'term': [this.selectedAddress],
        }),
        'mass_and_dimensions': [this.massAndDimensions],
        'mass_and_dimensions_data': formBuilder.array([]),
      }),
      'customer': formBuilder.group({
        'email': [this.email],
        'timeZone': [this.timeZone],
        'portal_access': [this.portalAccess],
      }),
    });
    setTimeout(() => {
      this.addItemToStopPoint(null);
    }, 200);
  }

  // update volume of [index] package whenever the first three values are changed
  updateVolumeAndWeight(index) {
    const width = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].width);
    const height = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].height);
    const length = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].length);

    if (width && height && length) {
      const volumeValue = width * height * length / 1000;
      const weightValue = volumeValue * this.globals.volumeDenominatorConstant;

      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
        'mass': weightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update volume & volumetric weight of [index] package whenever the first three values are changed
  updateVolumeAndVolumetricWeight(index) {
    const width = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].width);
    const height = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].height);
    const length = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].length);

    if (width && height && length) {
      const volumeValue = width * height * length / 1000;
      const volumetricWeightValue = volumeValue / this.globals.volumeDenominatorConstant;

      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
        'weight_volumetric': volumetricWeightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update weight of [index] package whenever the volume is changed
  updateWeightFromVolume(index) {
    const volume = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].volume);

    if (volume) {
      const weightValue = volume / this.globals.volumeDenominatorConstant;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'mass': weightValue.toFixed(3),
      });
    }
    this.weightsAndLoadsCompare();
  }

  // update volume of [index] package whenever the weight is changed (happens only if volume is empty/0!)
  // keep updating volume if user hasn't focused out of weight (used for weight of more than 1 digit)
  updateVolumeFromWeight(index) {
    const mass = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].mass);
    const volume = Number(this.myForm.value.stopPoint.mass_and_dimensions_data[index].volume);

    if ((mass && !volume) || (mass && this.isUserStillChangingWeight)) {
      this.isUserStillChangingWeight = true;
      const volumeValue = mass * this.globals.volumeDenominatorConstant;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.at(index).patchValue({
        'volume': volumeValue.toFixed(3),
      });
    }

    this.weightsAndLoadsCompare();
  }
  stopUpdatingVolumeFromWeight() {
    this.isUserStillChangingWeight = false;
  }

  // compare weights sum with total weight
  weightsAndLoadsCompare() {
    this.weight = Number(this.myForm.controls.stopPoint.value['weight']);
    this.load = Number(this.myForm.controls.stopPoint.value['load']);

    // set weight & load to 0 if it is undefined/null
    if (!this.weight) {
      this.weight = 0;
    }
    if (!this.load) {
      this.load = 0;
    }

    if (this.weight || this.weight === 0) {
      let weightSum = 0;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.value.forEach(data => {
        let currMass = Number(data.mass) ?? 0;
        let currVolWeight = Number(data.weight_volumetric) ?? 0;
        let weightToCalculate = currMass > currVolWeight ? currMass : currVolWeight;

        for (let i = 0; i < data.count; i++) {
          weightSum += weightToCalculate;
        }
      });

      if (weightSum) {
        this.weight = weightSum.toFixed(2);
      }
      this.patchWeightAndLoad();
    }

    if (this.load || this.load === 0) {
      let loadSum = 0;
      this.myForm.controls.stopPoint['controls'].mass_and_dimensions_data.value.forEach(data => {
        for (let i = 0; i < data.count; i++) {
          loadSum += Number(data.volume);
        }
      });

      if (loadSum) {
        this.load = loadSum.toFixed(2);
      }
      this.patchWeightAndLoad();
    }
  }

  patchWeightAndLoad() {
    this.myForm.patchValue({
      'stopPoint': {
        'mass_and_dimensions': this.massAndDimensions,
        'weight': this.weight,
        'load': this.load,
      },
    });

    M.updateTextFields();
  }

  addItemToStopPoint(data) {
    if (!data) {
      data = {
        height: null,
        length: null,
        width: null,
        mass: null,
        weightVolumetric: null,
        parcels_info: [],
        count: 1,
        id: null
      }
    }
    const parcelsInfo = data.parcels_info;
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].push(this.formBuilder.group({
      height: data.height,
      length: data.length,
      width: data.width,
      volume: data.volume,
      mass: data.mass,
      weight_volumetric: data.weightVolumetric,
      count: data.count,
      parcels_info: parcelsInfo,
      id: data.id
    }));
    this.items.push(data);
    this.items = [...this.items];

    setTimeout(() => {
      M.updateTextFields();
    }, 100);
  }

  removeMassAndDimensionsItem(massAndDimensionIndex) {
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].removeAt(massAndDimensionIndex);
    this.items.splice(massAndDimensionIndex, 1);
    document.getElementById('mass-and-dimensions-item-' + massAndDimensionIndex).remove();

    this.weightsAndLoadsCompare();
  }

  onDepotChange(event) {
    this.selectedDepotItem = event;
    this.myForm.patchValue({
      'stopPoint': {
        'depot': this.selectedDepotItem
      }
    });
  }

  openVoucherForm() {
    this.toggleModal.emit('close');
    setTimeout(() => {
      this.viewProjectProblemService.openStopFormModal(false);
    }, 1000);
  }

  createStopFromMapClick(data) {
    if (!data.forSameDayDelivery) {
      if (!data.helperOpen) {
        this.positionSet = true;
        this.lat = data.lat;
        this.lon = data.lng;
        this.isClickedOnce = true;
        this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
          this.isClickedOnce = false;
          if (location['data']['addresses']['items']) {
            if (location['data']['addresses']['items'].length) {
              this.setAddress(location['data']['addresses']['items'][0]['address']);
              this.updateFormValidity.emit('true');
            } else {
              this.freeformAddress = 'Address';
              this.country = 'GR';
            }
          }
          if (<HTMLInputElement>document.getElementById('custom-input')) {
            (<HTMLInputElement>document.getElementById('custom-input')).value = this.freeformAddress;
          }
        });
        this.draggableMarkerService.addDraggableMarker(Number(data.lat), Number(data.lng));
      }
    }
  }

  setAddressFromMapClick(data) {
    this.positionSet = true;
    this.lat = data.lat;
    this.lon = data.lng;
    this.isClickedOnce = true;
    this.http.get(`api/v1/search/reverse-locations?searchQuery=${this.lat},${this.lon}`).pipe(take(1)).subscribe(location => {
      this.isClickedOnce = false;
      if (location['data']['addresses']['items']) {
        if (location['data']['addresses']['items'].length) {
          this.setAddress(location['data']['addresses']['items'][0]['address']);
        } else {
          this.freeformAddress = 'Address';
          this.country = 'GR';
        }
      }
      if (<HTMLInputElement>document.getElementById('custom-input')) {
        (<HTMLInputElement>document.getElementById('custom-input')).value = this.freeformAddress;
      }
      this.updateFormValidity.emit('true');
    });

  }

  public setStopFormData(projectProblemId, projectProblemDepartureDatetime, entityStatus) {
    this.projectProblemId = projectProblemId;
    this.projectProblemDepartureDatetime = projectProblemDepartureDatetime;
    this.projectProblemDayOfWeek = this.days[moment(projectProblemDepartureDatetime).day()];
    if (entityStatus) {
      this.entityStatus = entityStatus;
    }

    let depotId = this.globals.depotId;
    if (this.globals.isInRoute('projectView')) {
      depotId = this.projectProblemDataService.projectData.company_depot_id;
    }
    this.selectedDepotItem = {
      companyDepot: {
        id: depotId,
      },
      name: this.depotUtils.getDepotName(this.globals.depots[depotId].companyDepot)
    };
    this.setForm();
  }

  public getFormData(stopPointId, projectProblemId = null) {

    if (!this.projectProblemId) {
      this.inGeneralWarehouse = true;
      document.getElementById('stop-form-modal').classList.add('one-bar-height');
    }

    if (!projectProblemId) {
      this.inWarehouse = true;
    }

    this.stopPointService.getStopPoint(stopPointId, projectProblemId).pipe(take(1)).subscribe(response => {
      this.data = response['item'];
      const stopPoint = this.data.stopPoint;
      // loadData
      // TODO change projectProblem for optimization
      if (this.data.projectProblem) {
        this.projectProblemDepartureDatetime = this.data.projectProblem.departure_datetime;
      }
      this.stopPointId = stopPoint.id;
      this.serviceType = stopPoint.service_type;
      this.stopPointProjectProblemId = stopPoint.project_problem_id ?? null;
      if (this.serviceType === this.globals.stopPointServiceTypeConstants['PICKUP']) {
        this.serviceTypeRadioButtonSelected = 'pickup';
        this.noDeliverySelected = false;
      } else if (this.serviceType === this.globals.stopPointServiceTypeConstants['DELIVERY']) {
        this.serviceTypeRadioButtonSelected = 'delivery';
      }
      this.contactName = stopPoint.contact_name;
      this.payAmount = stopPoint.pay_amount;
      this.canChangePaymentMethod = stopPoint.can_change_payment_method ?? true;

      // requested method must fetch its value from executed instead of requested if requested is not NO_PAYMENT!
      this.requestedPayOnDeliveryPaymentMethod = stopPoint.requested_pay_on_delivery_payment_method;
      if (stopPoint.executed_pay_on_delivery_payment_method != this.globals.paymentOptionsConstants['NO_PAYMENT']) {
        this.requestedPayOnDeliveryPaymentMethod = stopPoint.executed_pay_on_delivery_payment_method;
      }

      // load dates for cheques
      if (stopPoint.cheque_collection_date_pay_on_delivery) {
        this.chequeCollectionDatePayOnDelivery = stopPoint.cheque_collection_date_pay_on_delivery;
      }

      this.barcode = stopPoint.barcode;
      this.orderId = stopPoint.order_id;
      if (this.contactName === this.noNameConstant) {
        this.contactName = this.noNameLabel;
      }

      if (stopPoint.telephone === 'n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone === '+30n/a') { stopPoint.telephone = ''; }
      if (stopPoint.telephone) {
        if (stopPoint.telephone.length > 5 && this.globals.demoState !== this.globals.companyDemoStateConstants['IN_PROGRESS']) {
          const phoneObj = libphonenumber.parsePhoneNumber(stopPoint.telephone);
          this.phoneNumber = phoneObj.nationalNumber;
          this.countryPrefix = '+' + phoneObj.countryCallingCode;
        } else if (this.globals.demoState === this.globals.companyDemoStateConstants['IN_PROGRESS']) {
          // if we are in demo, the phone number from be has no country code 
          // this causes libphonenumber to have an error saying this is not a valid phone number
          // in demo we don't use libphonenumber to get the number and country code separately 
          // if we edit the SP in demo, the form will add '+30' to the random phone number
          this.phoneNumber = stopPoint.telephone;
        }
      }

      this.priority = stopPoint.priority;
      if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
        this.priorityOn = true;
      } else {
        this.priorityOn = false;
      }

      // load depot from warehouse id of sp
      const depot = this.depotUtils.getFirstDepotWithWarehouseId(stopPoint.warehouse_id);
      this.selectedDepotItem = {
        companyDepot: {
          id: depot.companyDepot.id,
        },
        name: this.depotUtils.getDepotName(depot.companyDepot)
      };

      this.timeWindows = stopPoint.time_windows;
      this.setTimeWindows(this.projectProblemDepartureDatetime);

      this.duration = stopPoint.duration;
      this.durationSeconds = [moment.duration(this.duration).asSeconds()];
      this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

      this.weight = stopPoint.weight;
      this.load = stopPoint.load;
      this.entityStatus = stopPoint.entity_status;
      this.entityStatusEnabled = stopPoint.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE'] ? true : false;
      this.fulfillmentStatus = stopPoint.fulfillment_status;

      this.items = [];
      this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].controls = [];
      setTimeout(() => {
        if (stopPoint.mass_and_dimensions) {
          stopPoint.mass_and_dimensions.forEach(item => {
            const mass = item.mass ? Number(item.mass) : null;
            const weightVolumetric = item.weightVolumetric ? Number(item.weightVolumetric) : null;
            const height = item.height ? Number(item.height) : null;
            const length = item.length ? Number(item.length) : null;
            const width = item.width ? Number(item.width) : null;
            const count = item.count ? Number(item.count) : 1;
            const id = item.id ? item.id : null;
  
            const volumeFromDimensions = width * height * length / (this.globals.volumeDenominatorConstant * 1000);
            const volumeFromMass = mass / this.globals.volumeDenominatorConstant;
            const volumeValue = volumeFromMass > volumeFromDimensions ? volumeFromMass : volumeFromDimensions;
  
            if (count) {
              const itemData = {
                height: height ? height.toFixed(2) : null,
                length: length ? length.toFixed(2) : null,
                width: width ? width.toFixed(2) : null,
                volume: volumeValue ? volumeValue.toFixed(2) : null,
                mass: mass ? mass.toFixed(2) : null,
                weightVolumetric: weightVolumetric ? weightVolumetric.toFixed(2) : null,
                count: count,
                parcels_info: item.parcels_info,
                id: id
              }
              this.addItemToStopPoint(itemData);
            }
          });
        }
      }, 100);

      if (stopPoint.agreed_shipping) {
        if (stopPoint.agreed_shipping[0]) {
          this.previousAgreedShipping = stopPoint.agreed_shipping[0];
          if (stopPoint.agreed_shipping[0].date) {
            this.agreedShippingDate = moment(stopPoint.agreed_shipping[0].date, 'YYYY-MM-DD').format('DD MMM YYYY');
          }
        }
      }

      if (stopPoint.note) {
        this.notes = stopPoint.note;
      }

      // this is the delivery of a next day p&d in warehouse
      if (!stopPoint.deliverySources.length && !stopPoint.pickupDestinations.length) {
        if (this.data.relatedStopPoint) {
          const pickupStopId = this.data.relatedStopPoint.id;
          const pickupProblemId = this.data.relatedStopPoint.project_problem_id;
          this.stopPointPickupService.loadDeliveryForm(stopPointId, projectProblemId);
          this.getFormData(pickupStopId, pickupProblemId);
        }
      }

      if (stopPoint.deliverySources.length) {
        this.deliverySourceModelName = stopPoint.deliverySources[0].modelName;
        this.deliverySourceModelId = stopPoint.deliverySources[0].modelId;
        if (this.deliverySourceModelName === this.globals.companyDepotModelName) {
          this.serviceTypeRadioButtonSelected = 'delivery';
          // this is the delivery of a next day p&d
          if (this.data.relatedStopPoint) {
            const pickupStopId = this.data.relatedStopPoint.id;
            const pickupProblemId = this.data.relatedStopPoint.project_problem_id;
            this.stopPointPickupService.loadDeliveryForm(stopPointId, projectProblemId);
            this.getFormData(pickupStopId, pickupProblemId);
          } else {
            this.serviceTypeSelected = 'delivery';
            this.serviceTypeRadioButtonSelected = 'delivery';
            this.noDeliverySelected = true;
          }
        } else {
          // load same day delivery form
          const pickupStopId = this.deliverySourceModelId;
          const pickupProblemId = this.data.relatedStopPoint?.project_problem_id ?? null;
          this.stopPointPickupService.loadDeliveryForm(stopPointId, projectProblemId);
          this.getFormData(pickupStopId, pickupProblemId);
        }
      }
      if (stopPoint.pickupDestinations.length) {
        let pricelistServiceType = stopPoint.pricelist_service_type;
        this.pickupDestinationModelName = stopPoint.pickupDestinations[0].modelName;
        this.pickupDestinationModelId = stopPoint.pickupDestinations[0].modelId;
        if (this.pickupDestinationModelName === this.globals.companyDepotModelName) {
          this.serviceTypeRadioButtonSelected = 'pickup';
          // load next day delivery if there is one
          if (this.data.relatedStopPoint) {
            this.serviceTypeSelected = 'both';
            this.noDeliverySelected = false;
            if (pricelistServiceType == this.globals.priceListServicesEnum.ThroughHub.key) {
              this.serviceTypeSelected = 'sameDay';
              this.serviceTypeRadioButtonSelected = 'sameDay';
              this.isThroughHub = true;
            }
            this.stopPointPickupService.loadDeliveryForm(this.data.relatedStopPoint.id, null);
          } else {
            this.serviceTypeSelected = 'pickup';
            this.noDeliverySelected = true;
          }
        } else {
          // load same day delivery form
          if (pricelistServiceType == this.globals.priceListServicesEnum.Express.key) {
            this.serviceTypeSelected = 'sameDay';
            this.serviceTypeRadioButtonSelected = 'sameDay';
          } else {
            this.serviceTypeSelected = 'both';
            this.serviceTypeRadioButtonSelected = 'pickup';
            this.noDeliverySelected = false;
          }
          const deliveryStopId = this.pickupDestinationModelId;
          this.stopPointPickupService.loadDeliveryForm(deliveryStopId, projectProblemId);
        }
      }

      // const loadedCheckbox = document.getElementById('stop-point-modal-loaded-checkbox') as HTMLInputElement;
      // if (loadedCheckbox) {
      if (this.projectProblemDataService.loadedStops[this.stopPointId]) {
        this.loaded = true;
        // loadedCheckbox.checked = true;
      } else {
        this.loaded = false;
        // loadedCheckbox.checked = false;
      }
      // }

      const data = this.projectProblemDataService.manualModifiedRouteItems;
      if (data) {
        if (data[this.stopPointId]) {
          const routeIds = data[this.stopPointId];
          this.selectedDrivers = [];
          routeIds.forEach(routeId => {
            if (this.projectProblemDataService.routeSettingsById[routeId]) {
              const driverId = this.projectProblemDataService.routeSettingsById[routeId]['driver']['id'];
              const driver = this.projectProblemDataService.drivers[driverId];
              this.selectedDrivers.push(driver);
            }
          });
        }
      }

      this.portalAccess = stopPoint.portal_access ? stopPoint.portal_access : this.globals.portalAccessConstants['NO_ACCESS'];
      this.setPortalSwitches();
      this.setAddress(stopPoint.address);
      this.positionSet = true;
      this.updateFormValidity.emit('true');
      this.setForm();
    });
  }

  setAddress(address) {
    this.freeformAddress = this.addressService.getAddressLabel(address);
    this.placeName = this.addressService.getAddressPlace(address);
    if (address.countryCode) {
      this.countryCode = address.countryCode;
    } else {
      this.countryCode = '';
    }
    if (address.state) {
      this.state = address.state;
    } else {
      this.state = '';
    }
    if (address.county) {
      this.county = address.county;
    } else {
      this.county = '';
    }
    if (address.city) {
      this.city = address.city;
    } else {
      this.city = '';
    }
    if (address.district) {
      this.district = address.district;
    } else {
      this.district = '';
    }
    if (address.street) {
      this.street = address.street;
    } else {
      this.street = '';
    }
    if (address.street2) {
      this.street2 = address.street2;
    } else {
      this.street2 = '';
    }
    if (address.houseNumber) {
      this.houseNumber = address.houseNumber;
    } else {
      this.houseNumber = '';
    }
    if (address.postalCode) {
      this.postalCode = address.postalCode;
    } else {
      this.postalCode = '';
    }
    if (address.isPlace) {
      this.isPlace = address.isPlace;
    } else {
      this.isPlace = false;
    }
    if (address.lat) {
      this.lat = address.lat;
    }
    if (address.lon) {
      this.lon = address.lon;
    }
    this.patchAddresses();
  }

  setTimeWindows(projectProblemDepartureDatetime) {
    this.timeWindowUnformatted = [];
    if (this.timeWindows.length === 1) {
      this.doubleTimeWindow = false;
    } else {
      this.doubleTimeWindow = true;
    }
    const timeWindowDouble = [];
    let timeWindowRange, timeWindowRangeMinutes;
    let timeWindowStart, timeWindowEnd, timeWindowStartMinutes, timeWindowEndMinutes;
    let timeWindowStartUnformatted, timeWindowEndUnformatted;
    this.timeWindows.forEach(timeWindow => {
      this.timeWindowIds.push(timeWindow.id);
      timeWindowRange = timeWindow.time_window_range;
      timeWindowRangeMinutes = moment.duration(timeWindowRange).asMinutes();
      if (timeWindow.time_window_start_datetime) {
        timeWindowStart = moment(timeWindow.time_window_start_datetime).format();
      } else if (timeWindow.start) {
        timeWindowStart = moment(timeWindow.start, 'HH:mm:SS').format();
      }
      timeWindowEnd = moment(timeWindowStart).add(timeWindowRangeMinutes, 'minutes').format();
      timeWindowStartUnformatted = moment(timeWindowStart).format('HH:mm');
      timeWindowEndUnformatted = moment(timeWindowEnd).format('HH:mm');
      this.timeWindowUnformatted.push(timeWindowStartUnformatted);
      this.timeWindowUnformatted.push(timeWindowEndUnformatted);
      timeWindowStartMinutes = moment.duration(timeWindowStartUnformatted).asMinutes();
      timeWindowEndMinutes = moment.duration(timeWindowEndUnformatted).asMinutes();
      if (this.timeWindows.length === 1) {
        this.timeWindow = [timeWindowStartMinutes, timeWindowEndMinutes];
      } else {
        timeWindowDouble.push(timeWindowStartMinutes);
        timeWindowDouble.push(timeWindowEndMinutes);
      }
    });
    this.timeWindowDouble = timeWindowDouble.length ? timeWindowDouble : this.timeWindowDouble;
    this.myForm.patchValue({
      'stopPoint': {
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
      }
    });
  }

  timeWindowSelected() {
    if (this.myForm.value.stopPoint.companyTimeWindow) {
      this.timeWindows = this.myForm.value.stopPoint.companyTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
      this.setTimeWindows(this.projectProblemDepartureDatetime);
    }
  }

  getPricelistServiceType() {
    let pricelistServiceType = null; 
    if (this.serviceTypeRadioButtonSelected == 'sameDay') {
      pricelistServiceType = this.globals.priceListServicesEnum.Express.key;
      if (this.myForm.value.stopPoint.isThroughHub) {
        pricelistServiceType = this.globals.priceListServicesEnum.ThroughHub.key;
      }
    }
    return pricelistServiceType;
  }

  patchAddresses() {
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
        },
      },
    });
  }

  patchForm() {
    this.myForm.patchValue({
      'stopPoint': {
        'contact_name': this.contactName,
        'service_type': this.serviceType,
        'entity_status': this.entityStatus,
        'depot': this.selectedDepotItem,
        'telephone': this.telephone,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'time_windows': this.timeWindows,
        'duration': this.duration,
        'priority': this.priority,
        'portal_access': this.portalAccess,
        'portalOn': this.portalOn,
        'portalPlus': this.portalPlus,
        'countryPrefix': this.countryPrefix,
        'load': this.load,
        'weight': this.weight,
        'agreed_shipping': this.agreedShipping,
        'fulfillment_status': this.fulfillmentStatus,
        'fulfillment_event': this.fulfillmentEvent,
        'mass_and_dimensions': this.massAndDimensions,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
        },
        'pickupDestinations': {
          'modelName': this.pickupDestinationModelName,
          'modelId': this.pickupDestinationModelId,
        },
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('custom-input')).value = this.freeformAddress.valueOf();
    }

    // disable depot selection in project view and in edit
    if (this.globals.isInRoute('projectView') || this.stopPointId) {
      this.myForm.controls['stopPoint']['controls']['depot'].disable();
    }
  }

  setForm() {
    this.myForm.setValue({
      'company_partner': this.selectedPartnerItem,
      'stopPoint': {
        'withVoucher': false,
        'project_problem_id': this.stopPointProjectProblemId,
        'id': this.stopPointId,
        'stopPointId': this.stopPointId,
        'service_type': this.serviceType,
        'serviceTypeRadioButtonSelected': this.serviceTypeRadioButtonSelected,
        'no_delivery': this.noDeliverySelected,
        'isThroughHub': this.isThroughHub,
        'depot': this.selectedDepotItem,
        'contact_name': this.contactName,
        'pay_amount': this.payAmount,
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery,
        // 'email': this.email,
        'countryPrefix': this.countryPrefix,
        'phoneNumber': this.phoneNumber,
        'telephone': this.telephone,
        'companyTimeWindow': this.selectedTimeWindow,
        'companyTimeWindowCategoryId': this.selectedTimeWindowId,
        'doubleTimeWindow': this.doubleTimeWindow,
        'time_windows': this.timeWindows,
        'time_window_unformatted': this.timeWindow,
        'time_window_unformatted2': this.timeWindowDouble,
        'duration_seconds': this.durationSeconds,
        'duration': this.duration,
        'weight': this.weight,
        'load': this.load,
        'final': this.final,
        'entity_status': this.entityStatus,
        'entity_status_enabled': this.entityStatusEnabled,
        'customerEntityType': this.customerEntityType,
        'relatedCustomerId': this.relatedCustomerId,
        'note': this.notes,
        'priority': this.priority,
        'priorityOn': this.priorityOn,
        'portal_access': this.portalAccess,
        'portalOn': this.portalOn,
        'portalPlus': this.portalPlus,
        'agreedShippingDate': this.agreedShippingDate,
        'agreed_shipping': this.agreedShipping,
        'fulfillment_status': this.fulfillmentStatus,
        'fulfillment_event': this.fulfillmentEvent,
        'barcode': this.barcode,
        'order_id': this.orderId,
        'loaded': this.loaded,
        'selectedDrivers': this.selectedDrivers,
        'deliverySources': {
          'modelName': this.deliverySourceModelName,
          'modelId': this.deliverySourceModelId,
        },
        'pickupDestinations': {
          'modelName': this.pickupDestinationModelName,
          'modelId': this.pickupDestinationModelId,
        },
        'address': {
          'countryCode': this.countryCode,
          'state': this.state,
          'county': this.county,
          'city': this.city,
          'district': this.district,
          'street': this.street,
          'street2': this.street2,
          'houseNumber': this.houseNumber,
          'postalCode': this.postalCode,
          'isPlace': this.isPlace,
          'placeName': this.placeName,
          'value': this.freeformAddress,
          'lat': this.lat,
          'lon': this.lon,
          'term': this.selectedAddress,
        },
        'mass_and_dimensions': this.massAndDimensions,
        'mass_and_dimensions_data': [],
      },
      'customer': {
        'email': this.email,
        'timeZone': this.timeZone,
        'portal_access': this.portalAccess,
      },
    });
    M.updateTextFields();
    this.selectedAddress = {
      label: this.freeformAddress,
      position: [this.lat, this.lon],
    };
    if (this.freeformAddress) {
      (<HTMLInputElement>document.getElementById('custom-input')).value = this.freeformAddress.valueOf();
    }

    // disable depot selection in project view
    if (this.globals.isInRoute('projectView') || this.stopPointId) {
      this.myForm.controls['stopPoint']['controls']['depot'].disable();
    }
  }

  resetForm() {
    this.errors = [];
    this.stopPointProjectProblemId = null;
    this.stopPointId = null;
    this.serviceType = 1;
    this.serviceTypeRadioButtonSelected = 'delivery';
    this.serviceTypeSelected = 'delivery';
    this.contactName = '';
    this.countryPrefix = this.globals.defaultCountryCode;
    this.phoneNumber = '';
    this.telephone = '';
    this.doubleTimeWindow = false;
    this.selectedPartnerItem = null;
    this.timeWindows = [];
    this.timeWindow = [480, 1200];
    this.timeWindowDouble = [480, 840, 1020, 1200];
    this.timeWindowUnformatted = ['08:00', '20:00'];
    this.timeWindowIds = [];
    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.weight = null;
    this.load = null;
    this.deliverySourceModelName = '';
    this.deliverySourceModelId = null;
    this.pickupDestinationModelName = '';
    this.pickupDestinationModelId = null;
    this.final = false;
    this.entityStatusEnabled = true;
    this.entityStatus = 1;
    this.notes = '';
    this.priority = '';
    this.priorityOn = false;
    this.freeformAddress = '';
    this.country = 'GR';
    this.lat = '';
    this.lon = '';
    this.countryCode = '';
    this.state = '';
    this.county = '';
    this.city = '';
    this.district = '';
    this.street = '';
    this.street2 = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.isPlace = false;
    this.placeName = '';
    this.customerEntityType = null;
    this.relatedCustomerId = null;
    this.email = '';
    this.payAmount = '';
    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.executedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');
    this.previousAgreedShipping = {};
    this.agreedShipping = [];
    this.agreedShippingDate = '';
    this.barcode = '';
    this.orderId = '';
    this.width = null;
    this.length = null;
    this.height = null;
    this.massAndDimensions = [];
    this.myForm.controls['stopPoint']['controls']['mass_and_dimensions_data'].controls = [];
    this.items = [];
    this.selectedDrivers = [];
    this.isThroughHub = false;
    this.agreedShippingDateChanged = false;
    this.inGeneralWarehouse = false;
    this.inWarehouse = false;
    this.fulfillmentStatus = null;
    this.fulfillmentEvent = {};
    this.timeZone = moment.tz.guess();
    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.setPortalSwitches();
    this.relatedCustomerId = null;
    this.positionSet = false;
    this.selectedTimeWindow = null;
    this.selectedTimeWindowId = null;
    this.canChangePaymentMethod = true;
    this.ngSelect.filter('');
    (<HTMLInputElement>document.getElementById('custom-input')).value = '';
    this.setForm();
    this.myForm.markAsUntouched();
    this.myForm.markAsPristine();
    this.draggableMarkerService.sameDayDeliveryMode(false);
    const dateElement = document.querySelector('#agreed-shipping-date');
    const dateInstances = M.Datepicker.init(dateElement);
    dateInstances.setDate(new Date(''));

    // re-enable depot selection on reset
    this.myForm.controls['stopPoint']['controls']['depot'].enable();
    this.addItemToStopPoint(null);
  }

  setPortalSwitches() {
    if (this.portalAccess === this.globals.portalAccessConstants['NO_ACCESS']) {
      this.portalOn = false;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_NO_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = false;
    } else if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
      this.portalOn = true;
      this.portalPlus = true;
    }
  }

  portalEnabled() {
    if (!this.globals.portalAccess && this.myForm.value.stopPoint.portalOn) {
      this.viewProjectProblemService.enableSettingsPortal();
    }
    if (this.stopPointId && this.projectProblemDataService.solutionData && this.myForm.value.stopPoint.portalOn) {
      const solutionInfoByRouteSettingIdByStopPointId = this.projectProblemDataService.solutionData['solutionInfoByRouteSettingIdByStopPointId'];
      if (this.stopPointId && solutionInfoByRouteSettingIdByStopPointId) {
        Object.keys(solutionInfoByRouteSettingIdByStopPointId).forEach(routeSettingId => {
          const solutionInfoByRouteSettingId = solutionInfoByRouteSettingIdByStopPointId[routeSettingId];
          if (solutionInfoByRouteSettingId[this.stopPointId]) {
            const driver = this.projectProblemDataService.routeSettingIdsToDrivers[routeSettingId];
            if (driver) {
              if (driver.id) {
                const driverId = driver.id;
                const driverData = this.projectProblemDataService.drivers[driverId];
                if (!driverData.driver.portal_access_sw) {
                  this.viewProjectProblemService.enableDriverPortal(driverId);
                }
              }
            }
          }
        });
      }
    }
  }

  portalHistoryEnabled() {
  }

  loadAssignedDrivers(data) {
    if (this.stopPointId) {
      if (data.route) {
        const routeIds = data.route[this.stopPointId];
        this.selectedDrivers = [];
        routeIds.forEach(routeId => {
          if (this.projectProblemDataService.routeSettingsById[routeId]) {
            const driverId = this.projectProblemDataService.routeSettingsById[routeId]['driver']['id'];
            const driver = this.projectProblemDataService.drivers[driverId];
            this.selectedDrivers.push(driver);
          }
        });
        this.myForm.patchValue({
          'stopPoint': {
            'selectedDrivers': this.selectedDrivers,
          }
        })
      }
    }
  }

  assignToDrivers() {
    if (this.stopPointId) {
      this.viewProjectProblemService.moveStopPoints([this.stopPointId]);
    }
  }

  changeLoadedStatus() {
    const checkbox = document.getElementById('stop-point-form-loaded-checkbox') as HTMLInputElement;
    if (checkbox.checked) {
      this.viewProjectProblemService.loadStopPoints([this.stopPointId]);
    } else {
      const loadStopsUrl = this.loadStopsUrl.replace('PROJECT_PROBLEM_ID', this.projectProblemId);
      const myObserver = {
        next: (response) => {
          if (response) {
            this.projectProblemDataService.loadedStops = response['items'];
          }
        },
        error: (error) => { },
        complete: () => { },
      };

      this.http.delete(loadStopsUrl + '?stopPointIds=' + this.stopPointId).pipe(take(1)).subscribe(myObserver);
    }
  }

  customerEntityTypeChanged(value) {
    if (value === 'null') {
      this.customerEntityType = null;
    } else {
      this.customerEntityType = Number(value);
      const container = document.querySelector('.stop-form-modal .side-modal-body.open');
      if (container) {
        setTimeout(() => {
          container.scrollTo(0, container.scrollHeight);
        }, 100);
      }
    }
    this.myForm.patchValue({
      'stopPoint': {
        'customerEntityType': this.customerEntityType,
      }
    });
  }

  initializeSlider() {
    this.timeWindowOptions = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 1200],
      connect: [false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.timeWindowOptionsDouble = {
      margin: 30, // how many minutes between start and stop
      step: 15,
      start: [480, 840, 1020, 1200],
      connect: [false, true, false, true, false],
      range: {
        min: 0,
        max: 1439
      },
    };
    this.durationOptions = {
      // margin: 15, // how many minutes between start and stop
      step: 15,
      start: [120],
      connect: 'lower',
      range: {
        min: 0,
        max: 1800
      },
    };
  }

  convertSecondsToTime() {
    let minutes = 0, seconds = 0;
    minutes = this.convertToHour(this.myForm.value.stopPoint.duration_seconds);
    seconds = this.convertToMinute(this.myForm.value.stopPoint.duration_seconds, minutes);
    this.durationUnformatted = this.formatHoursAndMinutes(minutes, seconds);
  }

  convertValuesToTime() {
    let hours = 0, minutes = 0;
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.myForm.value.stopPoint.time_window_unformatted2.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    } else {
      this.myForm.value.stopPoint.time_window_unformatted.forEach((timeWindow, i) => {
        hours = this.convertToHour(timeWindow);
        minutes = this.convertToMinute(timeWindow, hours);
        this.timeWindowUnformatted[i] = this.formatHoursAndMinutes(hours, minutes);
      });
    }
  }

  convertToHour(value) {
    return Math.floor(value / 60);
  }

  convertToMinute(value, hour) {
    return value - hour * 60;
  }

  formatHoursAndMinutes(hours, minutes) {
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    if (minutes.toString().length === 1) {
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
  }

  // calls a service to determine if the stop point is for pickup or not to set the right same day delivery
  radioButtonChange(event) {
    this.serviceTypeRadioButtonSelected = event.target.value;
    if (this.serviceTypeRadioButtonSelected === 'sameDay') {
      this.serviceTypeSelected = 'sameDay';
      this.pickupAndDelivery = true;
      this.expandModal.emit('true');
      this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
      this.draggableMarkerService.sameDayDeliveryMode(true);
    } else if (this.serviceTypeRadioButtonSelected === 'pickup') {
      if (this.noDeliverySelected) {
        this.serviceTypeSelected = 'pickup';
        this.pickupAndDelivery = false;
        this.expandModal.emit('false');
        this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
        this.draggableMarkerService.sameDayDeliveryMode(false);
      } else {
        this.serviceTypeSelected = 'both';
        this.pickupAndDelivery = true;
        this.expandModal.emit('true');
        this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
        this.draggableMarkerService.sameDayDeliveryMode(true);
      }
    } else if (this.serviceTypeRadioButtonSelected === 'delivery') {
      this.serviceTypeSelected = 'delivery';
      this.pickupAndDelivery = false;
      this.expandModal.emit('false');
      this.serviceType = this.globals.stopPointServiceTypeConstants['DELIVERY'];
      this.draggableMarkerService.sameDayDeliveryMode(false);
    }
    this.myForm.patchValue({
      'stopPoint': {
        'service_type': this.serviceType,
      }
    });

    this.updatePayAmountField();
    M.updateTextFields();
    this.updateFormValidity.emit('true');
  }

  pricelistServiceTypeChange(event) {
    this.noDeliverySelected = event.target.checked;
    if (this.noDeliverySelected) {
      this.serviceTypeSelected = 'pickup';
      this.pickupAndDelivery = false;
      this.expandModal.emit('false');
      this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
      this.draggableMarkerService.sameDayDeliveryMode(false);
    } else {
      this.serviceTypeSelected = 'both';
      this.pickupAndDelivery = true;
      this.expandModal.emit('true');
      this.serviceType = this.globals.stopPointServiceTypeConstants['PICKUP'];
      this.draggableMarkerService.sameDayDeliveryMode(true);
    }

    this.updateFormValidity.emit('true');
  }

  updatePayAmountField() {
    // clear pay_amount when delivery exists
    if ((this.serviceTypeSelected == 'pickup' || this.serviceTypeSelected == 'both') && !this.noDeliverySelected) {
      this.payAmount = '';
      this.myForm.patchValue({
        'stopPoint': {
          'pay_amount': this.payAmount
        }
      });
    }
  }

  submitButtonPressed(cancelConfirmed = 'notSet') {
    // re-enable depot selection on submit
    this.myForm.controls['stopPoint']['controls']['depot'].enable();

    this.isClickedOnce = true;
    // skip confirm if:
    // confirm was shown OR
    // sp is unscheduled OR
    // sp is newly added (so unscheduled)
    // agreedShippingDateChanged field was not changed OR
    // we are in the warehouse grid
    if (
      cancelConfirmed !== 'notSet' ||
      this.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED'] ||
      !this.stopPointId ||
      !this.agreedShippingDateChanged ||
      this.inGeneralWarehouse ||
      !this.projectProblemId
    ) {
      this.submitStopForm(cancelConfirmed).pipe(take(1)).subscribe(data => {
        this.isClickedOnce = false;
        this.genericService.newShipmentsGenerateGridData();
        const stopFormValues = this.myForm.value.stopPoint;
        if (this.serviceTypeSelected === 'sameDay' || this.serviceTypeSelected === 'both') {
          let pickupId = data['items'][0]['stopPoint']['id'];
          data['items'].forEach(responseStopPointData => {
            if (responseStopPointData['stopPoint']['service_type'] == this.globals.stopPointServiceTypeConstants['PICKUP']) {
              pickupId = responseStopPointData['stopPoint']['id'];
            }
          });
          this.stopPointPickupService.submitDeliveryForm(pickupId, stopFormValues.load, this.getPricelistServiceType());
        }
        if (this.projectProblemId) {
          this.viewProjectProblemService.updateProjectProblemStatus();
          data['items'].forEach(stopPointResponseData => {
            if (stopPointResponseData.stopPoint.project_problem_id == this.projectProblemId) {
              this.projectProblemDataService.addStopPoint(stopPointResponseData);
            }
          });
        } else { }
        this.viewProjectProblemService.updateWarehouseGrid();
        this.modalService.updateStopPointsGrid();
        this._notificationSvc.showSuccess('', this.stopPointSavedLabel);
        if (this.serviceTypeSelected !== 'sameDay' && this.serviceTypeSelected !== 'both') {
          this.toggleModal.emit('close');
        }
      }, error => {
        if (error.error?.SMART_POINT_ERROR == this.globals.smartPointErrorCodeEnum['SMART_POINT_ERROR_TOO_MANY_ITEMS']) {
          alert(this.smartPointTooManyItemsText);
          this._notificationSvc.showWarning('', this.smartPointTooManyItemsText);
        } else this.formErrorsUtils.checkResponseForErrorCodes(error);
        this.isClickedOnce = false;
        const err = error.error.errors;
        const errors = this.errors;

        const keyify = (obj, prefix = '') =>
          Object.keys(obj).reduce((res, el) => {
            if (obj[el]) {
              if (obj[el]['0'] && typeof obj[el]['0'] === 'string') {
                errors.push(obj[el]['0']);
              }
              if (obj[el]['0'] && typeof obj[el]['0'] === 'object') {
                if (obj[el]['0']['load']) {
                  errors.push(obj[el]['0']['load']['0']);
                }
              }
            }
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === 'object' && obj[el] !== null) {
              return [...res, ...keyify(obj[el], prefix + el + '.')];
            } else {
              return [...res, prefix + el];
            }
          }, []);
        const output = keyify(error);
        this.errors = errors;
      });
    } else {
      this.viewProjectProblemService.throwCancelConfirm();
    }
  }

  prepareForSubmit(cancelConfirmed) {
    const stopFormValues = this.myForm.value.stopPoint;

    if (stopFormValues.contact_name) {
      this.contactName = stopFormValues.contact_name;
      if (stopFormValues.contact_name === this.noNameLabel) {
        this.contactName = this.noNameConstant;
      }
    } else {
      this.contactName = this.noNameConstant;
    }

    if (stopFormValues.weight !== '') {
      this.weight = Number(stopFormValues.weight);
    }

    if (stopFormValues.load !== '') {
      this.load = Number(stopFormValues.load);
    }

    if (stopFormValues.priorityOn) {
      this.priority = this.globals.stopPointPriorityConstants['HIGH'];
    } else {
      this.priority = this.globals.stopPointPriorityConstants['NORMAL'];
    }
    if (stopFormValues.portalOn) {
      if (stopFormValues.portalPlus) {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_WITH_HISTORY'];
      } else {
        this.portalAccess = this.globals.portalAccessConstants['ACCESS_NO_HISTORY'];
      }
    } else {
      this.portalAccess = this.globals.portalAccessConstants['NO_ACCESS'];
    }
    if (stopFormValues.phoneNumber) {
      this.telephone = stopFormValues.countryPrefix + stopFormValues.phoneNumber;
    }
    if (stopFormValues.entity_status_enabled) {
      this.entityStatus = this.globals.stopPointEntityStatusConstants['ACTIVE'];
    } else {
      this.entityStatus = this.globals.stopPointEntityStatusConstants['DISABLED'];
    }
    if (stopFormValues.mass_and_dimensions_data.length) {
      this.massAndDimensions = [];
      stopFormValues.mass_and_dimensions_data.forEach((item, index) => {
        item.parcels_info = this.items[index]['parcels_info'];
        this.massAndDimensions.push(item);
      });
      this.myForm.patchValue({
        'stopPoint': {
          'mass_and_dimensions': this.massAndDimensions
        }
      });
    }

    if (stopFormValues.serviceTypeRadioButtonSelected === 'delivery') {
      this.deliverySourceModelName = this.globals.companyDepotModelName;
      if (stopFormValues.depot) {
        this.deliverySourceModelId = stopFormValues.depot.companyDepot.id;
      } else {
        this.deliverySourceModelId = this.globals.depotId;
      }
      // empty pickup destinations
      this.pickupDestinationModelName = '';
      this.pickupDestinationModelId = null;
    } else if (stopFormValues.serviceTypeRadioButtonSelected === 'pickup') {
      this.pickupDestinationModelName = this.globals.companyDepotModelName;
      if (stopFormValues.depot) {
        this.pickupDestinationModelId = stopFormValues.depot.companyDepot.id;
      } else {
        this.pickupDestinationModelId = this.globals.depotId;
      }
      // empty delivery sources
      this.deliverySourceModelId = null;
      this.deliverySourceModelName = '';
    } else if (stopFormValues.serviceTypeRadioButtonSelected === 'sameDay') {
      this.pickupDestinationModelName = null;
      this.pickupDestinationModelId = null;

      // empty delivery sources
      this.deliverySourceModelId = null;
      this.deliverySourceModelName = '';
    }
    if (!this.positionSet) {
      if (stopFormValues.address.term.customer) {
        this.lat = stopFormValues.address.term.customer.address.lat;
        this.lon = stopFormValues.address.term.customer.address.lon;
        this.freeformAddress = this.addressService.getAddressLabel(stopFormValues.address.term.customer.address);
      } else {
        this.lat = stopFormValues.address.term.position[0];
        this.lon = stopFormValues.address.term.position[1];
        this.freeformAddress = stopFormValues.address.term.label;
      }
    }
    let durationSeconds;
    if (Array.isArray(stopFormValues.duration_seconds)) {
      durationSeconds = stopFormValues.duration_seconds[0];
    } else {
      durationSeconds = stopFormValues.duration_seconds;
    }
    this.duration = moment.duration(durationSeconds, 'seconds').toISOString();

    this.timeWindows = [];
    const projectProblemDepartureDatetime = this.projectProblemDepartureDatetime;
    const projectProblemTimeStart = moment(projectProblemDepartureDatetime).format('HH:mm');
    const projectProblemTimeStartMoment = moment(projectProblemTimeStart, 'HH:mm');

    let index = 0;
    for (let i = 0; i < this.timeWindowUnformatted.length; i = i + 2) {
      const timeWindowStartMoment = moment(this.timeWindowUnformatted[i], 'HH:mm');
      const timeWindowEndMoment = moment(this.timeWindowUnformatted[i + 1], 'HH:mm');
      const timeWindowStart = timeWindowStartMoment.format('HH:mm:SS');

      if (
        moment(timeWindowEndMoment).isBefore(projectProblemTimeStartMoment) &&
        i === this.timeWindowUnformatted.length - 1
      ) {
        this.errors.push(this.timeWindowErrorMsg + ' ' + projectProblemTimeStart);
      }
      const timeWindowRangeDuration = moment.duration(timeWindowEndMoment.diff(timeWindowStartMoment));
      const timeWindowRangeMinutes = timeWindowRangeDuration.asMinutes();
      const timeWindowRange = moment.duration(timeWindowRangeMinutes, 'minutes').toISOString();

      this.timeWindows.push({
        id: this.timeWindowIds[index] ? this.timeWindowIds[index] : null,
        time_window_range: timeWindowRange,
        start: timeWindowStart
      });
      index++;
    }
    if (stopFormValues.companyTimeWindow) {
      this.selectedTimeWindowId = stopFormValues.companyTimeWindow['CompanyTimeWindowCategory']['id'];
    }

    this.agreedShipping = [];
    if (this.previousAgreedShipping['id']) { this.agreedShipping = [this.previousAgreedShipping]; }

    if (cancelConfirmed === 'yes' || this.fulfillmentStatus === this.globals.stopPointFulfillmentStatusConstants['UN_SCHEDULED'] || !this.stopPointId) {
      if (this.agreedShippingDateChanged) {
        const dateElement = document.querySelector('#agreed-shipping-date');
        const date = M.Datepicker.getInstance(dateElement).date;
        if (date) {
          const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:SS').format('YYYY-MM-DD');
          this.agreedShipping = [
            {
              id: this.previousAgreedShipping['id'] ? this.previousAgreedShipping['id'] : null,
              date: formattedDate,
              start: this.previousAgreedShipping['start'] ? this.previousAgreedShipping['start'] : '',
              end: this.previousAgreedShipping['end'] ? this.previousAgreedShipping['end'] : ''
            }
          ];

          if (cancelConfirmed === 'yes') {
            const canceledConstant = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
            this.fulfillmentStatus = canceledConstant;
            this.fulfillmentEvent = {
              reason: this.globals.stopPointFulfillmentEventConstants[canceledConstant]['AGREED_SHIPPING'],
              description: ''
            }
          }
        } else {
          this.agreedShipping = [
            {
              id: this.previousAgreedShipping['id'] ? this.previousAgreedShipping['id'] : null,
              date: '',
              start: this.previousAgreedShipping['start'] ? this.previousAgreedShipping['start'] : '',
              end: this.previousAgreedShipping['end'] ? this.previousAgreedShipping['end'] : ''
            }
          ];

          if (cancelConfirmed === 'yes') {
            const canceledConstant = this.globals.stopPointFulfillmentStatusConstants['CANCELED'];
            this.fulfillmentStatus = canceledConstant;
            this.fulfillmentEvent = {
              reason: this.globals.stopPointFulfillmentEventConstants[canceledConstant]['AGREED_SHIPPING'],
              description: ''
            }
          }
        }
      }
    }

    if (!this.fulfillmentEvent['reason']) {
      this.fulfillmentStatus = null;
    }

    this.patchForm();
  }

  public submitStopForm(cancelConfirmed) {
    this.errors = [];
    this.prepareForSubmit(cancelConfirmed);
    const projectProblemId = this.data?.stopPoint.project_problem_id ?? this.projectProblemId;
    return this.stopPointService.saveStopPoint(this.myForm.value, this.stopPointId, projectProblemId, this.inWarehouse);
  }

  timeWindowCheckboxClicked() {
    if (this.myForm.value.stopPoint.doubleTimeWindow) {
      this.doubleTimeWindow = false;
      this.timeWindowUnformatted = ['08:00', '20:00'];
    } else {
      this.doubleTimeWindow = true;
      this.timeWindowUnformatted = ['08:00', '14:00', '17:00', '20:00'];
    }
  }

  inputFocusOut() {
    if (!this.myForm.value.stopPoint.address.term.timeZone && !this.myForm.value.stopPoint.address.term.customer) {
      if (this.ngSelect.itemsList['_filteredItems']) {
        const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
        if (firstItem) {
          this.ngSelect.select(firstItem);
        }
      }
    }
  }

  inputAddress(isPaste = false) {
    this.selectedAddress = '';
    this.myForm.patchValue({
      'stopPoint': {
        'address': {
          'term': this.selectedAddress,
        },
      },
    });

    if (isPaste) {
      setTimeout(() => {
        this.ngSelect.filter((<HTMLInputElement>document.getElementById('custom-input')).value);
      }, 50);
    } else {
      this.ngSelect.filter((<HTMLInputElement>document.getElementById('custom-input')).value);
    }
  }

  onAgreedShippingDateChange() {
    this.agreedShippingDateChanged = true;
  }

  onAddressChange() {
    const stopFormValues = this.myForm.value.stopPoint;
    // TODO update the day of week and selected timeWindows
    (<HTMLInputElement>document.getElementById('custom-input')).value = stopFormValues.address.term.label;
    if (stopFormValues.address.term) {
      if (stopFormValues.address.term.timeZone) {
        const timeZone = stopFormValues.address.term.timeZone;
        const targetDateTime = moment(this.projectProblemDepartureDatetime).tz(timeZone);
        this.projectProblemDayOfWeek = this.days[moment(targetDateTime).day()];
        if (this.selectedTimeWindow) {
          if (this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows']) {
            this.timeWindows = this.selectedTimeWindow['CompanyTimeWindowCategory']['time_windows'][this.projectProblemDayOfWeek];
            this.setTimeWindows(this.projectProblemDepartureDatetime);
          }
        }
      }
      if (stopFormValues.address.term.customer) {
        const customer = stopFormValues.address.term.customer;
        this.relatedCustomerId = customer.id;
        this.setAddress(customer.address);
        this.selectedAddress = this.myForm.value.stopPoint.address.term;
        this.draggableMarkerService.addDraggableMarker(Number(this.lat), Number(this.lon));

        this.serviceType = customer.stop_point_default_service_type;
        this.contactName = customer.contact_name;
        if (this.contactName === this.noNameConstant) {
          this.contactName = this.noNameLabel;
        }
        if (customer.telephone === 'n/a') { customer.telephone = ''; }
        this.telephone = customer.telephone;
        this.phoneNumber = this.telephone;
        if (this.telephone === 'n/a') { this.telephone = ''; }
        if (this.telephone === '+30n/a') { this.telephone = ''; }
        if (this.telephone) {
          if (this.telephone.length > 5) {
            const phoneObj = libphonenumber.parsePhoneNumber(this.telephone);
            this.phoneNumber = phoneObj.nationalNumber;
            this.countryPrefix = '+' + phoneObj.countryCallingCode;
          }
        }

        this.email = '';
        if (customer.email) {
          this.email = customer.email;
        }

        this.timeWindows = customer.time_windows[this.projectProblemDayOfWeek];
        this.setTimeWindows(this.projectProblemDepartureDatetime);

        this.duration = customer.stop_point_default_duration;
        this.durationSeconds = [moment.duration(this.duration).asSeconds()];
        this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);

        this.entityStatus = customer.entity_status;
        this.entityStatusEnabled = customer.entity_status === this.globals.stopPointEntityStatusConstants['ACTIVE'] ? true : false;

        this.customerEntityType = null;
        this.portalAccess = customer.portal_access ? customer.portal_access : 0;
        this.portalOn = false;
        this.portalPlus = false;
        if (this.portalAccess) {
          this.portalOn = true;
          if (this.portalAccess === this.globals.portalAccessConstants['ACCESS_WITH_HISTORY']) {
            this.portalPlus = true;
          }
        }
        this.priority = customer.priority;
        if (this.priority === this.globals.stopPointPriorityConstants['HIGH']) {
          this.priorityOn = true;
        } else {
          this.priorityOn = false;
        }

        this.notes = customer.note ? customer.note : '';
        this.setForm();
      } else {
        this.lat = stopFormValues.address.term.position[0];
        this.lon = stopFormValues.address.term.position[1];
        this.freeformAddress = stopFormValues.address.term.label;
        this.setAddress(stopFormValues.address.term.address);
        this.draggableMarkerService.addDraggableMarker(Number(this.lat), Number(this.lon));
      }
    }

    this.updateFormValidity.emit('true');
  }

  addAddressesAutofillEvents() {
    setTimeout(() => {
      document.getElementById('custom-input').addEventListener('onautocomplete', () => {
        // load first address found
        this.addressAutofillInterval = setInterval(() => {
          if (this.ngSelect.itemsList['_filteredItems']) {
            const firstItem = this.ngSelect.itemsList['_filteredItems'][0];
            if (firstItem) {
              this.ngSelect.select(firstItem);
              clearInterval(this.addressAutofillInterval)
              this.addressAutofillInterval = null;
            }
          }
        }, 500);
      });
    }, 1000);
  }

  initPickers() {
    const select = document.querySelectorAll('select');
    const instancesSelect = M.FormSelect.init(select);
    const dateElement = document.querySelectorAll('.datepicker');
    const dateInstances = M.Datepicker.init(dateElement);
  }

  updateSelectDrivers() {
    let driverId;
    // empty drivers array because otherwise the options are not assigned to the dropdown for some twisted reason
    this.drivers = [];
    this.projectProblemDataService.driversArray.forEach(driver => {
      driverId = driver.driver.id;
      this.drivers.push(this.projectProblemDataService.drivers[driverId]);
    });
  }

  updateSelectedPaymentMethod(data) {
    this.requestedPayOnDeliveryPaymentMethod = data['value'];
    this.chequeCollectionDatePayOnDelivery = moment().add(8, 'days').format('YYYY-MM-DD');

    if (this.stopPointId) {
      this.executedPayOnDeliveryPaymentMethod = data['value'];
    } else {
      this.executedPayOnDeliveryPaymentMethod = null;
    }

    this.myForm.patchValue({
      'stopPoint': {
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'executed_pay_on_delivery_payment_method': this.executedPayOnDeliveryPaymentMethod,
        'cheque_collection_date_pay_on_delivery': this.chequeCollectionDatePayOnDelivery
      }
    });

    M.updateTextFields();
  }

  populatePaymentOptions() {
    this.paymentOptions = [
      {
        value: this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'],
        label: this.cashLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CREDIT_CARD'],
        label: this.creditCardLabel
      },
      {
        value: this.globals.paymentOptionsConstants['BANK_DEPOSIT'],
        label: this.bankDepositLabel
      },
      {
        value: this.globals.paymentOptionsConstants['CHEQUE'],
        label: this.chequeLabel
      },
      {
        value: this.globals.paymentOptionsConstants['DAY_CHEQUE'],
        label: this.chequeDayLabel
      }
    ];
  }

  getTranslations() {
    this.listen.push(this.translate.get('STOP_POINT').subscribe((res: string) => {
      this.creditCardLabel = res['CREDIT_CARD'];
      this.cashLabel = res['CASH'];
      this.payOnDeliveryLabel = res['PAY_ON_DELIVERY'];
      this.bankDepositLabel = res['BANK_DEPOSIT'];
      this.chequeLabel = res['CHEQUE'];
      this.chequeDayLabel = res['DAY_CHEQUE'];
      this.populatePaymentOptions();
    }));
    this.listen.push(this.translate.get('ERROR.TIME_WINDOW_ERROR_MSG').subscribe((res: string) => { this.timeWindowErrorMsg = res; }));
    this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
    this.listen.push(this.translate.get('GENERIC.CHANGE_UNAVAILABLE_EDIT').subscribe((res: string) => { this.changeUnavailableEditTitle = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SAVED_NOTIFICATION').subscribe((res: string) => { this.stopPointSavedLabel = res; }));
    this.listen.push(this.translate.get('STOP_POINT.SMART_POINT_ERROR_TOO_MANY_ITEMS').subscribe((res: string) => { this.smartPointTooManyItemsText = res; }));
  }

  ngOnInit(): void {
    this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getTranslations();
    }));
    this.getTranslations();

    this.addAddressesAutofillEvents();

    this.timeZone = moment.tz.guess();
    this.initPickers();

    this.requestedPayOnDeliveryPaymentMethod = this.globals.paymentOptionsConstants['PAY_ON_DELIVERY'];
    this.duration = this.globals.defaultDuration;
    this.durationSeconds = [moment.duration(this.duration).asSeconds()];
    this.durationUnformatted = this.dateTimeCalculatorService.calculateDelayInMinutesAndSeconds(this.duration, true);
    this.myForm.patchValue({
      'stopPoint': {
        'requested_pay_on_delivery_payment_method': this.requestedPayOnDeliveryPaymentMethod,
        'duration': this.duration,
        'duration_seconds': this.durationSeconds,
      }
    });

    this.selectedDepotItem = {};
    const depotsDataRefreshIntervalId = setInterval(depotsDataChecker.bind(this), 200);
    function depotsDataChecker() {
      if (this.globals.depotsDataDone) {
        clearInterval(depotsDataRefreshIntervalId);
        this.depots = this.globals.depotsWithNamesArray;
        M.updateTextFields();
      }
    }

    // get partners
    this.selectedPartnerItem = null;
    if (this.globals.partnersData) {
      if (this.globals.partnersData['companies']) {
        this.globals.partnersData['companies'].forEach(partner => {
          this.partners.push({
            id: partner.id,
            name: partner.name
          })
        });
      } else {
        this.globals.partnersData.forEach(partner => {
          if (partner.companyCollaborator) {
            if (partner.companyCollaborator.partnerCompany) {
              this.partners.push({
                id: partner.companyCollaborator.partnerCompany.id,
                name: partner.companyCollaborator.collaborator.collaboratorData.collaborator_name
              });
            }
          }
        });
      }
      if (this.partners[0]) {
        this.selectedPartnerItem = this.partners[0];
      }
      M.updateTextFields();
    }

    const departureTimeElement = document.querySelectorAll('.timepicker');
    const timeInstances = M.Timepicker.init(departureTimeElement, {
      twelveHour: false
    });

    const elements = document.querySelectorAll('select');
    const instances = M.FormSelect.init(elements);

    this.addresses = concat(
      of([]), // default items
      this.addressInput.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.addressesLoading = true),
        switchMap(term => this.dataService.getAddressesAndCustomers(term, this.projectProblemId).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.addressesLoading = false)
        ))
      )
    );

    const timeWindowsDataRefreshIntervalId = setInterval(timeWindowsDataChecker.bind(this), 200);
    function timeWindowsDataChecker() {
      if (this.globals.timeWindowsDataDone) {
        clearInterval(timeWindowsDataRefreshIntervalId);
        this.customTimeWindows = this.globals.customTimeWindows;
      }
    }

    const projectProblemDataServiceDataRefreshIntervalId = setInterval(dataChecker.bind(this), 200);
    function dataChecker() {
      if (this.projectProblemDataService.dataReady()) {
        clearInterval(projectProblemDataServiceDataRefreshIntervalId);
        this.drivers = this.projectProblemDataService.driversArray;
        // this.updateSelectDrivers();
      }
    }

    this.portalAccess = this.globals.defaultPortalAccess ? this.globals.defaultPortalAccess : this.globals.portalAccessConstants['NO_ACCESS'];
    this.countryPrefix = this.globals.defaultCountryCode;
    this.setPortalSwitches();
    this.patchForm();
  }

  ngOnDestroy() {
    this.listen.forEach(element => {
      element.unsubscribe();
    });
  }

}
