import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@app/services/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AddressService } from '@app/services/address.service';
import * as moment from 'moment';
import QRCode from 'easyqrcodejs';
import { StopPointUtils } from '@app/utils/stop-point-utils';
import { ImageUtils } from '@app/utils/image-utils';

@Component({
    selector: 'app-voucher-print',
    templateUrl: './voucher-print.component.html',
    styleUrls: ['./voucher-print.component.scss']
})
export class VoucherPrintComponent implements OnInit, AfterViewInit {
    @ViewChild('printPage', { static: false }) public printPage: ElementRef;

    listen = [];

    vouchers = [];
    logoSrc = 'data:image/png;base64,LOGO_HASH'
    logoBase64 = '';
    parsed = [];

    companyName = '';
    companyDescription = '';
    companyAddress = '';
    companyPhone = '';
    companyWebsite = '';
    companyRegistryNumber = '';
    companyTaxOffice = '';
    companyTin = '';

    firstLineDetails = '';
    secondLineDetails = '';

    phoneLabel = '';
    registryNumberLabel = '';
    taxOfficeLabel = '';
    tinLabel = '';

    collaboratorLabel = '';
    recipientLabel = '';
    consignorLabel = '';
    notesLabel = '';
    itemsLabel = '';
    weightLabel = '';
    volumeLabel = '';
    weightVolLabel = '';
    servicesLabel = '';
    dateLabel = '';
    chargeLabel = '';
    paymentMethodLabel = '';

    creditCardLabel = '';
    payOnDeliveryLabel = '';
    bankDepositLabel = '';

    noNameLabel = '';
    returnLabel = '';
    noNameConstant = '_NO_NAME';
    returnConstant = '_RETURN';

    printed = false;

    partnerLogosByHash = {};

    // weightVol subdivision denominator constant
    weightVolDiv = 5;

    constructor(
        public translate: TranslateService,
        public globals: Globals,
        private addressService: AddressService,
        private stopPointUtils: StopPointUtils,
        private imageUtils: ImageUtils,
    ) {
    }

    filterVouchers() {
        this.vouchers = [];

        // for every stop point
        this.parsed.forEach((stopPoint, stopPointIndex) => {
            let pickupStopPoint;
            if (stopPoint.relatedStopPoint) {
                pickupStopPoint = this.stopPointUtils.getPickupFromTwoStopPoints(stopPoint.stopPoint, stopPoint.relatedStopPoint);
            }
            if (stopPoint.stopPoint.related_to === this.globals.stopPointRelatedToConstants['SELF']) {
                if (stopPoint.stopPoint.voucher) {
                    const courierData = {
                        logoBase64: '',
                        companyName: '',
                        companyDescription: '',
                        firstLineDetails: '',
                        secondLineDetails: ''
                    }
                    if (this.globals.collaboratorModeEnabled) {
                        const partnerId = stopPoint.stopPoint.company_partner_id;
                        if (partnerId) {
                            const partnerData = this.globals.partners[partnerId];
                            if (partnerData) {
                                if (partnerData.logo?.imageHash) {
                                    const hash = partnerData.logo.imageHash;
                                    if (this.partnerLogosByHash[hash]) {
                                        courierData.logoBase64 = this.logoSrc.replace('LOGO_HASH', this.partnerLogosByHash[hash]);
                                    }
                                }
                                courierData.companyName = partnerData.name;
                                courierData.companyDescription = partnerData.description ? partnerData.description : '';
                                courierData.firstLineDetails = this.getFirstLineDetails(partnerData.address_text, partnerData.telephone, partnerData.website);
                                courierData.secondLineDetails = this.getSecondLineDetails(partnerData.tin, partnerData.tax_office, partnerData.registry_number);
                            }
                        }
                    } else {
                        if (this.globals.companyLogo) {
                            if (this.globals.companyLogo['base64']) {
                                courierData.companyName = this.globals.companyName;
                                courierData.logoBase64 = this.logoSrc.replace('LOGO_HASH', this.globals.companyLogo['base64']);
                            }
                        }

                        courierData.companyName = this.globals.companyName;
                        courierData.companyDescription = this.companyDescription;
                        courierData.firstLineDetails = this.firstLineDetails;
                        courierData.secondLineDetails = this.secondLineDetails;
                    }

                    // data for dimensions of all parcels of the current stop point
                    if (stopPoint.stopPoint.mass_and_dimensions) {
                        // print a voucher for every different item in stop point
                        let currCount = 0;
                        const itemData = this.stopPointUtils.getTotalMassAndDimensions(stopPoint.stopPoint.mass_and_dimensions);
                        stopPoint.stopPoint.mass_and_dimensions.forEach((item, itemIndex) => {
                            // dimensions data for the voucher that will be printed
                            // (vouchers are printed individually and are equal to the sum of all the 'count' properties of mass and dimensions of the SP)
                            for (let itemCounter = 0; itemCounter < item.count; itemCounter++) {
                                currCount++;
                                const stopPointData = this.generateStopPointData(stopPoint, pickupStopPoint, courierData, stopPointIndex, currCount);
                                const barcode = item.parcels_info[itemCounter] ? item.parcels_info[itemCounter]['barcode'] : '';
                                if (barcode) {
                                    stopPointData['itemsData'] = { ...itemData, services: [], count: `${currCount}/${itemData.countTotal}`, barcode: barcode };
                                    this.vouchers.push(stopPointData);
                                }
                            }
                        });
                    }
                }
            }
        });

        let logoLoaded = false;

        if (this.globals.companyLogo && this.globals.companyLogo['base64']) {
            logoLoaded = true;
            this.logoBase64 = this.logoSrc.replace('LOGO_HASH', this.globals.companyLogo['base64']);
        } else {
            const self = this;
            if (!this.printed) {
                self.printed = true;
                setTimeout(() => {
                    self.loadQrCodes();
                }, 100);
            }
        }
    }

    generateStopPointData(stopPoint, pickupStopPoint, courierData, stopPointIndex, itemCounter) {
        const stopPointData = {};
        stopPointData['id'] = stopPoint.stopPoint.id;
        stopPointData['projectProblemId'] = stopPoint.stopPoint.project_problem_id ? stopPoint.stopPoint.project_problem_id : null;
        stopPointData['hash'] = stopPoint.stopPoint.id + 'testhash' + stopPointIndex;
        stopPointData['index'] = stopPointIndex + '-' + itemCounter;
        stopPointData['courier'] = courierData;

        let tin = '', senderName = '', senderAddress = '', senderPhone = '';

        // get sender's data from saved collaborators of voucher
        const sender = this.stopPointUtils.getSenderCollaborator(stopPoint['stopPoint']['voucher']['company_voucher_collaborators']);

        // console.log(stopPoint)
        if (sender) {
            senderName = sender.collaborator.collaboratorData.collaborator_name;
            senderAddress = this.addressService.getAddressLabel(sender.collaborator.address);
        } else if (stopPoint['stopPoint']['collaborator_name']) {
            senderName = stopPoint['stopPoint']['collaborator_name'];
            senderAddress = this.addressService.getAddressLabel(stopPoint['stopPoint']['temporary_assignor'].address);
        } else if (stopPoint['stopPoint']['supplier']) {
            senderName = stopPoint['stopPoint']['supplier'];
            senderAddress = this.addressService.getAddressLabel(stopPoint['stopPoint']['temporary_assignor'].address);
        }

        // (shipper portal) in case of new shipment print use the pickup's (aka related sp's) address as a fallback in case there is no sender address
        if (this.globals.collaboratorModeEnabled && !senderAddress && pickupStopPoint) {
            senderAddress = this.addressService.getAddressLabel(pickupStopPoint.address);
        }

        const collaboratorData = {
            tin: tin,
            name: senderName,
            address: senderAddress ? senderAddress : '',
            phone: senderPhone,
        };
        stopPointData['collaboratorData'] = collaboratorData;

        let name = stopPoint.stopPoint.contact_name;
        if (name.includes(this.noNameConstant)) {
            name = name.replace(this.noNameConstant, this.noNameLabel);
        }
        if (name.includes(this.returnConstant)) {
            name = name.replace(this.returnConstant, this.returnLabel);
        }

        const recipientData = {
            name: name,
            address: this.addressService.getAddressLabel(stopPoint.stopPoint.address),
            phone: stopPoint.stopPoint.telephone,
            notes: stopPoint.stopPoint.note,
        };
        stopPointData['recipientData'] = recipientData;

        let paymentType = '', chargeDate = '', chargeTo = '';
        if (stopPoint.stopPoint.voucher) {
            stopPointData['hash'] = stopPoint.stopPoint.voucher.hash;
            const paymentMethodConstant = stopPoint.stopPoint.voucher.payment_method
            if (paymentMethodConstant === this.globals.paymentOptionsConstants['CREDIT_CARD']) {
                paymentType = this.creditCardLabel;
            } else if (paymentMethodConstant === this.globals.paymentOptionsConstants['PAY_ON_DELIVERY']) {
                paymentType = this.payOnDeliveryLabel;
            } else if (paymentMethodConstant === this.globals.paymentOptionsConstants['BANK_DEPOSIT']) {
                paymentType = this.bankDepositLabel;
            }
            chargeDate = moment(stopPoint.stopPoint.voucher.creation_datetime).format('DD/MM/YY');

            switch (stopPoint.stopPoint.voucher.paid_by) {
                case this.globals.stopPointCollaboratorTypesConstants['CONSIGNOR']:
                    chargeTo = this.consignorLabel;
                    break;
                case this.globals.stopPointCollaboratorTypesConstants['RECEIVER']:
                    chargeTo = this.recipientLabel;
                    break;
                case this.globals.stopPointCollaboratorTypesConstants['SENDER']:
                    chargeTo = this.collaboratorLabel;
                    break;
            }
        }

        const chargeData = {
            date: chargeDate,
            charge: chargeTo,
            payOnDeliveryAmount: stopPoint.stopPoint.pay_amount ? stopPoint.stopPoint.pay_amount : 0,
            paymentType: paymentType,
            hash: stopPoint.stopPoint.voucher ? stopPoint.stopPoint.voucher.hash : '',
        };
        stopPointData['chargeData'] = chargeData;

        return stopPointData;
    }

    sortVouchers() {
        const sortFunctionsMap = {
            [this.globals.printVoucherOrder.CREATION_DATE_AND_TIME]: this.sortVouchersByDateTime,
            [this.globals.printVoucherOrder.POSTAL_CODE]: this.sortVouchersByPostalCode,
            [this.globals.printVoucherOrder.RECEPIENT_NAME]: this.sortVouchersByRecipientName,
            [this.globals.printVoucherOrder.ORDER_ID]: this.sortVouchersByOrderId,
        };

        const selectedFunction = sortFunctionsMap[this.globals.selectedPrintVoucherOrder] || this.sortVouchersByPostalCode;
        selectedFunction.call(this);
    }

    sortVouchersByPostalCode() {
        // sort by postal codes asc, after converting them to numbers removing spaces
        function comparePostalCodes(a, b) {
            if (a.stopPoint.address.postalCode && b.stopPoint.address.postalCode) {
                const numA = parseInt((a.stopPoint.address.postalCode).replace(/ /g, ''), 10)
                const numB = parseInt((b.stopPoint.address.postalCode).replace(/ /g, ''), 10)
                if (numA < numB) { return -1; }
                if (numA > numB) { return 1; }
            } else if (a.stopPoint.address.postalCode) {
                return -1;
            } else {
                return 1;
            }
            return 0;
        }
        this.parsed.sort(comparePostalCodes);
    }

    sortVouchersByDateTime() {
        this.parsed.sort((a, b) => {
            return new Date(a.stopPoint.creation_datetime).getTime() - new Date(b.stopPoint.creation_datetime).getTime();
        });
    }

    sortVouchersByRecipientName() {
        this.parsed.sort((a, b) => {
            return a.stopPoint.contact_name.localeCompare(b.stopPoint.contact_name);
        });
    }

    sortVouchersByOrderId() {
        this.parsed.sort((a, b) => {
            const orderIdA = a.stopPoint.order_id;
            const orderIdB = b.stopPoint.order_id;

            if (orderIdA === null && orderIdB === null) return 0;
            if (orderIdA === null) return 1;
            if (orderIdB === null) return -1;

            return orderIdA.localeCompare(orderIdB);
        });
    }

    loadQrCodes() {
        this.vouchers.forEach(stopPoint => {
            const options = {
                text: stopPoint.itemsData['barcode'],
                logo: 'assets/lastmilyAssets/logo.png',
                quietZone: 20,
            };
            const i = stopPoint['index'];
            if (document.getElementById('index-' + String(i))) {
                document.getElementById('index-' + String(i)).innerHTML = '';
                new QRCode(document.getElementById('index-' + String(i)), options);
            }
        });
        const printVouchers = function () {
            var comm100 = document.querySelector('[id^="comm100-button"]');
            comm100.classList.add('hidden');
            var comm1002 = document.querySelector('[id^="comm100-container"]');
            comm1002.classList.add('hidden');
            window.print();
        }
        const testOptions = {
            text: 'any',
            logo: 'assets/lastmilyAssets/logo.png',
            quietZone: 20,
            onRenderingEnd: printVouchers,
        };
        const testQr = new QRCode(document.getElementById('test-qr'), testOptions);
    }

    onLogoLoad() {
        const self = this;
        var comm100 = document.querySelector('[id^="comm100-button"]');
        comm100.classList.add('hidden');
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        comm1002.classList.add('hidden');
        setTimeout(() => {
            var comm100 = document.querySelector('[id^="comm100-button"]');
            comm100.classList.add('hidden');
            var comm1002 = document.querySelector('[id^="comm100-container"]');
            comm1002.classList.add('hidden');
            self.loadQrCodes();
        }, 100);
    }

    setCompanyDetails() {
        this.companyName = this.globals.companyName;
        this.companyDescription = this.globals.companyData['description'];
        const companyAddress = this.globals.companyData['address_text'];
        const companyPhone = this.globals.companyData['telephone'];
        const companyWebsite = this.globals.companyData['website'];
        const companyRegistryNumber = this.globals.companyData['registry_number'];
        const companyTaxOffice = this.globals.companyData['tax_office'];
        const companyTin = this.globals.companyData['tin'];

        this.firstLineDetails = this.getFirstLineDetails(companyAddress, companyPhone, companyWebsite);
        this.secondLineDetails = this.getSecondLineDetails(companyTin, companyTaxOffice, companyRegistryNumber);
    }

    getFirstLineDetails(companyAddress, companyPhone, companyWebsite) {
        if (companyAddress) { companyAddress += '. ' } else { companyAddress = '' }
        if (!companyPhone) { companyPhone = '-' }
        const firstLineDetails =
            companyAddress +
            this.phoneLabel + ':' + companyPhone + ', ' +
            (companyWebsite ? companyWebsite : '');
        return firstLineDetails;
    }

    getSecondLineDetails(companyTin, companyTaxOffice, companyRegistryNumber) {
        if (!companyTin) { companyTin = '-' }
        if (!companyTaxOffice) { companyTaxOffice = '-' }
        let secondLineDetails =
            this.tinLabel + ': ' + companyTin + ', ' +
            this.taxOfficeLabel + ': ' + companyTaxOffice;

        if (companyRegistryNumber) {
            secondLineDetails += ', ' + this.registryNumberLabel + ' ' + companyRegistryNumber;
        }

        return secondLineDetails;
    }

    setPartnerLogos() {
        const hashesToRequest = [];
        this.parsed.forEach((stopPoint) => {
            const partnerId = stopPoint.stopPoint.company_partner_id;
            if (partnerId) {
                const partnerData = this.globals.partners[partnerId];
                if (partnerData?.logo?.imageHash) {
                    const hash = partnerData.logo.imageHash;
                    if (!hashesToRequest.includes(hash)) {
                        hashesToRequest.push(hash);
                    }
                }
            }
        });
        this.imageUtils.fetchImagesViaHashes(`api/internal/v1/images/partner-company-logo`, hashesToRequest).then(images => {
            images.forEach((image, index) => {
                this.partnerLogosByHash[hashesToRequest[index]] = image;
            });
            this.filterVouchers();
        });
    }

    getTranslations() {
        this.listen.push(this.translate.get('STOP_POINT._NO_NAME').subscribe((res: string) => { this.noNameLabel = res; }));
        this.listen.push(this.translate.get('STOP_POINT.RETURN').subscribe((res: string) => { this.returnLabel = res; }));
        this.listen.push(this.translate.get('VOUCHER_PRINT').subscribe((res: string) => {
            this.phoneLabel = res['PHONE'];
            this.registryNumberLabel = res['REGISTRY_NUMBER_ALTERNATIVE'];
            this.taxOfficeLabel = res['TAX_OFFICE'];
            this.tinLabel = res['TIN'];
            this.collaboratorLabel = res['COLLABORATOR'];
            this.recipientLabel = res['RECIPIENT'];
            this.consignorLabel = res['CONSIGNOR'];
            this.notesLabel = res['NOTES'];
            this.itemsLabel = res['ITEMS'];
            this.weightLabel = res['WEIGHT_TOTAL'];
            this.volumeLabel = res['VOLUME_TOTAL'];
            this.weightVolLabel = res['WEIGHT_VOL_TOTAL'];
            this.servicesLabel = res['SERVICES'];
            this.dateLabel = res['DATE'];
            this.chargeLabel = res['CHARGE'];
            this.paymentMethodLabel = res['PAYMENT_METHOD'];
            this.creditCardLabel = res['CREDIT_CARD'];
            this.payOnDeliveryLabel = res['PAY_ON_DELIVERY'];
            this.bankDepositLabel = res['BANK_DEPOSIT'];
            this.setCompanyDetails();
            this.sortVouchers();
            this.setPartnerLogos();
        }));
    }

    ngAfterViewInit() { }

    ngOnInit() {
        var comm100 = document.querySelector('[id^="comm100-button"]');
        if (comm100) {
            comm100.classList.add('hidden');
        }
        var comm1002 = document.querySelector('[id^="comm100-container"]');
        if (comm1002) {
            comm1002.classList.add('hidden');
        }
        this.listen.push(this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getTranslations();
        }));
        this.getTranslations();

        const itemsStr = localStorage.getItem('vouchersToPrint');
        this.parsed = JSON.parse(itemsStr);
    }

    ngOnDestroy() {
        this.listen.forEach(element => {
            element.unsubscribe();
        });
    }

}
