import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  template: '<div></div>'
})
export class SvgIconsComponent {

  // some svgs are NOT interactable with mouse events because pointer-events: none is in their styles!
  iconRegionName = `<svg xmlns="http://www.w3.org/2000/svg">
  <symbol id="icon-region-name" viewBox="0 0 20 20">
      <path d="M439.669 481.888 238.293 375.264c-5.248-2.784-11.648-2.4-16.608.96L67.701 482.848a15.987 15.987 0 0 0-6.144 17.952c2.048 6.624 8.256 11.2 15.232 11.2h355.36c7.328 0 13.76-4.992 15.52-12.16 1.792-7.136-1.536-14.528-8-17.952zM507.061 451.488l-47.36-213.216c-1.152-5.216-4.832-9.472-9.792-11.424a16.023 16.023 0 0 0-14.944 1.728L280.949 335.2c-4.608 3.2-7.2 8.544-6.848 14.08a15.917 15.917 0 0 0 8.48 13.184l201.344 106.624c2.368 1.248 4.928 1.888 7.488 1.888 3.424 0 6.848-1.088 9.696-3.296a16.016 16.016 0 0 0 5.952-16.192zM395.637 203.232A16.037 16.037 0 0 0 380.341 192H234.293c-26.56 48.096-65.856 94.08-82.016 112.064C143.189 314.176 130.165 320 116.565 320s-26.624-5.824-35.712-15.936c-6.24-6.944-15.904-18.048-26.976-31.808l-38.72 174.272a16.055 16.055 0 0 0 6.688 16.736c2.688 1.824 5.824 2.72 8.928 2.72 3.2 0 6.368-.928 9.12-2.848L389.429 221.12c5.792-3.936 8.256-11.2 6.208-17.888z" fill="#c7c7c7" opacity="1" data-original="#000000"/>
      <path d="M116.565 0c-61.76 0-112 50.24-112 112 0 57.472 89.856 159.264 100.096 170.688 3.04 3.36 7.36 5.312 11.904 5.312s8.864-1.952 11.904-5.312C138.709 271.264 228.565 169.472 228.565 112c0-61.76-50.24-112-112-112zm0 160c-26.496 0-48-21.504-48-48s21.504-48 48-48 48 21.504 48 48-21.504 48-48 48z" fill="#c7c7c7" opacity="1" data-original="#000000"/>
  </symbol>
</svg>`;

  pngDomIcon = '<div style="position:relative; width: 30px; height: 30px; background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Map_marker_font_awesome.svg/240px-Map_marker_font_awesome.svg.png">' +
    '<span style="position:absolute; top:0;left0;">' + new Date().getMilliseconds() + '</span>'
  '</div>';

  circle = '<svg height="20" width="20"><circle cx="10" cy="10" r="8" stroke="black" stroke-width="2" fill="red" /></svg>';
  markerSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'borderColour' + '" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/>' +
    '<path fill="' + 'markerColour' + '" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/>' +
    '</g>' +
    '<g>' +
    'checkboxSvg' + 'smallClockSvg' + 'asteriskSvg' + 'cancelledPortalSvg' +
    '</g>' +
    'weightSvg' + 'largeClockSvg' +
    '<text fill="white" style="font-size:75px; font-weight: 500; font-family: Roboto, sans-serif; cursor: default" x="-30" y="267" text-anchor="middle" alignement-baseline="middle">' + 'markerNumber' + '</text>' +
    'completedSvg' + 'cancelledSvg' + 'userSvg' +
    '</svg>' +
    '';

  numberMarkerSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<text fill="white" style="font-size:75px; font-weight: 500; font-family: Roboto, sans-serif; cursor: default" x="' + 'XPosition' + '" y="267" text-anchor="middle" alignement-baseline="middle">' + 'markerNumber' + '</text>' +
    '</svg>';

  basicMarkerSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'borderColour' + '" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/>' +
    '<path fill="' + 'markerColour' + '" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/>' +
    '</g>' +
    '</svg>';

  markerWithSmartPointSvg = '' +
    '<div style="width: 1px; height: 1px; position: relative">' +
    '<span ' + 'idPlaceholder' + ' class="marker-icon" style="position: absolute; bottom: -6px; left: -17px;">' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'borderColour' + '" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/>' +
    '<path fill="' + 'markerColour' + '" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/>' +
    '</g>' +
    '</svg>' +
    '<div style="position: absolute; top: 10px; left: 10px;">' +
    '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">' +
    '<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">' +
    '<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651 -146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586 229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703 734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145 l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>' +
    '<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54 l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3 0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158 158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66 -456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064 0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>' +
    '</g>' +
    '</svg>' +
    '</div></span></div>';

  basicAsteriskSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M-62.6,164.9c3.7-3.3,5.8-7.6,5.8-12.4c0-3.4-1-6.6-3-9.8c-3.3-5.7-8.5-8.7-14.6-8.7c-2,0-3.8,0.3-5.8,0.9c-1.6-8.4-8.1-13.7-17.4-13.7c-9.1,0-15.8,5.4-17.4,13.7c-1.8-0.6-3.7-0.9-5.5-0.9c-6,0-11.5,3.1-14.8,8.7c-1.8,2.7-2.7,6-2.7,9.6c0,4.8,2.1,9.2,5.8,12.6c-3.7,3.1-5.8,7.6-5.8,12.7c0,3.4,0.9,6.5,2.6,9.2c3.3,5.7,8.7,8.9,14.9,8.9c2,0,3.7-0.3,5.5-0.9c1.6,8.4,8.3,13.7,17.4,13.7c9.2,0,15.8-5.4,17.4-13.7c2,0.6,3.8,0.9,5.8,0.9c6,0,11.3-3.3,14.6-8.7c2-3.1,3-6.4,3-9.8C-56.9,172.4-59,168.1-62.6,164.9z M-70.7,171.8c2.6,1.5,4.2,3.4,4.2,5.8c0,1.5-0.4,2.7-1.5,4.3c-1.5,2.5-3.4,3.8-5.8,3.8c-1.5,0-2.7-0.4-4.3-1.2l-11.9-6.9v13.9c0,4.8-2.5,7.3-7.3,7.3s-7.3-2.5-7.3-7.3V178l-11.6,6.6c-1.5,0.7-2.7,1.2-4.3,1.2c-2.5,0-4.6-1.3-5.9-3.8c-0.7-1.3-1.2-2.6-1.2-4.2c0-2.6,1.3-4.7,4.2-5.9l11.9-6.9l-11.9-6.9c-2.6-1.5-4.2-3.4-4.2-5.9c0-1.6,0.4-3,1.2-4.2c1.5-2.5,3.4-3.8,5.9-3.8c1.5,0,2.7,0.4,4.3,1.2l11.6,6.6v-13.5c0-4.8,2.5-7.3,7.3-7.3s7.3,2.5,7.3,7.3v13.9l11.9-6.9c1.5-0.7,2.7-1.2,4.3-1.2c2.3,0,4.3,1.3,5.8,3.8c0.9,1.5,1.5,2.7,1.5,4.3c0,2.3-1.3,4.3-4.2,5.8l-11.9,6.9L-70.7,171.8z"/>' +
    '<path fill="' + 'asteriskColour' + '" d="M-66.7,152.2c0-1.5-0.4-2.7-1.5-4.3c-1.5-2.5-3.4-3.8-5.8-3.8c-1.5,0-2.7,0.4-4.3,1.2l-11.9,6.9v-13.9c0-4.8-2.5-7.3-7.3-7.3s-7.3,2.5-7.3,7.3v13.5l-11.6-6.6c-1.5-0.7-2.7-1.2-4.3-1.2c-2.5,0-4.6,1.3-5.9,3.8c-0.7,1.2-1.2,2.5-1.2,4.2c0,2.5,1.3,4.6,4.2,5.9l11.9,6.9l-11.9,6.9c-2.6,1.3-4.2,3.3-4.2,5.9c0,1.5,0.4,2.7,1.2,4.2c1.5,2.5,3.4,3.8,5.9,3.8c1.5,0,2.7-0.4,4.3-1.2l11.6-6.6v13.5c0,4.8,2.5,7.3,7.3,7.3s7.3-2.5,7.3-7.3v-13.9l11.9,6.9c1.5,0.7,2.7,1.2,4.3,1.2c2.3,0,4.3-1.3,5.8-3.8c0.9-1.5,1.5-2.7,1.5-4.3c0-2.3-1.3-4.3-4.2-5.8l-11.9-6.9l11.9-6.9C-68.1,156.6-66.7,154.6-66.7,152.2z"/>' +
    '</g>' +
    '</svg>';

  basicCheckSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M-1.5,202.3l-38.9,43.9l-19.5-21.9c-3.5-3.9-9.4-3.9-13.1,0c-3.5,3.9-3.5,10.6,0,14.7 l26,29.2c3.5,3.9,9.4,3.9,13.1,0l45.5-51.3c3.7-4.1,3.7-10.8,0-14.7C8.1,198.2,2.2,198.2-1.5,202.3z"/>' +
    '</g>' +
    '</svg>';

  basicCancelledPortalSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M77.7,176.6L66.1,165l11.6-11.6l0.2-0.2c7.1-7.4,7-19.3-0.2-26.5l-0.2-0.2c-3.6-3.4-8.3-5.3-13.2-5.3c-5,0-9.7,1.9-13.3,5.4l-11.6,11.6l-11.6-11.6l-0.2-0.2c-3.6-3.4-8.3-5.3-13.2-5.3c-5,0-9.7,1.9-13.3,5.4l-0.2,0.2c-7.1,7.4-7,19.3,0.2,26.5L12.7,165L1.1,176.6l-0.2,0.2c-7.1,7.4-7,19.3,0.2,26.5l0.2,0.2c3.6,3.4,8.3,5.3,13.2,5.3c5,0,9.7-1.9,13.3-5.4l11.6-11.6L51,203.4l0.2,0.2c3.6,3.4,8.3,5.3,13.2,5.3c5,0,9.7-1.9,13.3-5.4l0.2-0.2C84.9,195.6,84.8,183.8,77.7,176.6z M70.6,196.2c-3.4,3.4-9,3.4-12.5,0l-18.7-18.7l-18.7,18.7c-3.4,3.4-9,3.4-12.5,0c-3.4-3.4-3.4-9,0-12.5L26.9,165L8.2,146.3c-3.4-3.4-3.4-9,0-12.5c3.4-3.4,9-3.4,12.5,0l18.7,18.7l18.7-18.7c3.4-3.4,9-3.4,12.5,0c3.4,3.4,3.4,9,0,12.5L51.9,165l18.7,18.7C73.9,187,73.9,192.7,70.6,196.2z"/>' +
    '<path fill="' + 'markerColour' + '" d="M51.9,165l18.7-18.7c3.4-3.5,3.4-9.2,0-12.5c-3.5-3.4-9.2-3.4-12.5,0l-18.7,18.7l-18.7-18.7c-3.5-3.4-9.2-3.4-12.5,0c-3.4,3.5-3.4,9.2,0,12.5L26.9,165L8.2,183.7c-3.4,3.5-3.4,9.2,0,12.5c3.5,3.4,9.2,3.4,12.5,0l18.7-18.7l18.7,18.7c3.5,3.4,9.2,3.4,12.5,0c3.4-3.5,3.4-9.2,0-12.5L51.9,165z"/>' +
    '</g>' +
    '</svg>';

  // basicCheckboxSvg = '' +
  //   '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
  //   '<g>' +
  //   '<path fill="white" d="M63.4,293.7H15.6c-10.9,0-19.7,8.9-19.7,19.7v47.8c0,10.9,8.9,19.7,19.7,19.7h47.8c10.9,0,19.7-8.9,19.7-19.7v-47.8C83.1,302.6,74.3,293.7,63.4,293.7z M73.1,361.3c0,5.4-4.3,9.7-9.7,9.7H15.6c-5.4,0-9.7-4.4-9.7-9.7v-47.8c0-5.4,4.3-9.7,9.7-9.7h47.8c5.3,0,9.7,4.4,9.7,9.7V361.3z"/>' +
  //   '<g>' +
  //   '<path fill="' + 'editColour' + '" class="st2" d="M39.8,370.9h23.6c5.4,0,9.7-4.4,9.7-9.7v-23.8H39.8V370.9z"/>' +
  //   '<path fill="' + 'editColour' + '" class="st2" d="M39.5,337.4H5.9v23.8c0,5.4,4.3,9.7,9.7,9.7h23.9h0.3v-33.5H39.5z"/>' +
  //   '<path fill="' + 'editColour' + '" class="st2" d="M73.1,337.4v-24c0-5.3-4.4-9.7-9.7-9.7H39.8v33.7H73.1z"/>' +
  //   '<path fill="' + 'editColour' + '" class="st2" d="M39.5,303.7H15.6c-5.4,0-9.7,4.4-9.7,9.7v24h33.6h0.3v-33.7H39.5z"/>' +
  //   '</g>' +
  //   'checkSvg' +
  //   'beforeAfterSvg' +
  //   '</g>' +
  //   '</svg>';

  basicCheckboxSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35px" enable-background="new -138 121.2 221.2 259.8" viewBox="-138 121.2 221.2 259.8" xml:space="preserve">' +
    '<path d="m63.4 293.7c-17.9 0.45335-35.856-0.97086-53.713 0.83444-10.158 3.1722-15.861 14.954-13.752 25.078 0.31476 15.83-0.92715 31.717 0.79869 47.5 3.1722 10.158 14.954 15.861 25.078 13.752 15.83-0.31478 31.717 0.92715 47.5-0.79878 10.155-3.1715 15.858-14.948 13.753-25.071-0.11648-15.259 0.47808-30.532-0.38029-45.778-1.8369-8.7506-10.284-15.734-19.285-15.517zm9.7 67.6c0.21844 6.3047-6.7418 11.158-12.663 9.6936-15.797-0.1641-31.603 0.37643-47.393-0.29339-5.5166-1.5273-8.328-8.0158-7.1311-13.338 0.13227-15.453-0.35149-30.913 0.2669-46.361 1.5251-5.78 8.3955-8.3871 13.846-7.1933 15.303 0.15815 30.615-0.38171 45.911 0.29786 6.3513 1.8018 8.4055 9.5071 7.1636 15.329v41.864z" fill="#fff"/>' +
    '<g>' +
    '<path class="st2"  fill="' + 'editColour' + '" d="m39.8 370.9c9.5174-0.62702 19.278 1.4136 28.596-1.3431 5.3669-3.471 5.2781-10.635 4.7042-16.277v-15.88h-33.3v33.5z"/>' +
    '<path class="st2"  fill="' + 'editColour' + '" d="m39.5 337.4h-33.6c0.62069 9.5828-1.395 19.4 1.318 28.791 3.4767 5.3783 10.654 5.2577 16.302 4.7093h16.28v-33.5h-0.24664z"/>' +
    '<path class="st2"  fill="' + 'editColour' + '" d="m73.1 337.4c-0.635-9.6448 1.4302-19.535-1.3561-28.979-3.4676-5.3594-10.622-5.3102-16.263-4.7214h-15.681v33.7h33.3z"/>' +
    '<path class="st2"  fill="' + 'editColour' + '" d="m39.5 303.7c-9.6174 0.63004-19.477-1.418-28.896 1.3431-5.3669 3.471-5.2781 10.635-4.7042 16.277v16.08h33.9v-33.7h-0.24664z"/>' +
    '</g>' +
    'checkSvg' +
    'beforeAfterSvg' +
    '</svg>';

  basicSmallCheckSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path id="checked" fill="white" d="M56.3,320.6l-22,24.8l-11-12.4c-2-2.2-5.3-2.2-7.4,0c-2,2.2-2,6,0,8.3l14.7,16.5c2,2.2,5.3,2.2,7.4,0l25.7-29c2.1-2.3,2.1-6.1,0-8.3C61.7,318.3,58.4,318.3,56.3,320.6z"/>' +
    '</g>' +
    '</svg>';

  basicBeforeAfterSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M66.7,336.6c-0.1-0.4-0.2-0.6-0.4-0.8l-22.7-23.6c-0.4-0.4-1-0.1-1.2,0.8c-0.1,0.3-0.1,0.5-0.1,0.8V361c0,1,0.4,1.8,0.9,1.8c0.1,0,0.3-0.1,0.4-0.2L66.3,339C66.7,338.6,66.9,337.5,66.7,336.6z"/>' +
    '<path fill="white" d="M12,338.2c0.1,0.4,0.2,0.6,0.4,0.8l22.7,23.6c0.4,0.4,1,0.1,1.2-0.8c0.1-0.3,0.1-0.5,0.1-0.8v-47.2c0-1-0.4-1.8-0.9-1.8c-0.1,0-0.3,0.1-0.4,0.2l-22.7,23.6C12,336.2,11.8,337.3,12,338.2z"/>' +
    '</g>' +
    '</svg>';

  basicCancelSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M3.8,254.5c3.7,3.7,3.7,9.9,0,13.8c-3.7,3.7-9.9,3.7-13.8,0l-20.6-20.6l-20.6,20.6 c-3.7,3.7-9.9,3.7-13.8,0c-3.7-3.7-3.7-9.9,0-13.8l20.6-20.6L-65,213.3c-3.7-3.7-3.7-9.9,0-13.8c3.7-3.7,9.9-3.7,13.8,0 l20.6,20.6l20.6-20.6c3.7-3.7,9.9-3.7,13.8,0c3.7,3.7,3.7,9.9,0,13.8l-20.6,20.6L3.8,254.5z"/>' +
    '</g>' +
    '</svg>';

  basicWeightSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<path id="weight" fill="white" d="M12.3,265.6l-9.9-51.3c-0.4-2.2-2.3-3.8-4.6-3.8h-16.5c3.1-3,5-7.3,5-11.9c0-9.2-7.5-16.8-16.8-16.8s-16.8,7.5-16.8,16.8c0,4.7,1.9,8.9,5,11.9h-16.5c-2.3,0-4.2,1.6-4.6,3.8l-9.9,51.3c-0.2,1.4,0.1,2.8,1,3.8c0.9,1.1,2.2,1.7,3.6,1.7H7.6c1.4,0,2.7-0.6,3.6-1.7C12.1,268.4,12.5,266.9,12.3,265.6z M-30.6,191.1c4.1,0,7.4,3.3,7.4,7.4c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4C-38,194.4-34.6,191.1-30.6,191.1z"/>' +
    '</svg>';

  basicLargeClockSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<path id="clock" fill="white" d="M-30.6,183.3c-23.2,0-42.1,18.8-42.1,42.1s18.8,42.1,42.1,42.1s42.1-18.8,42.1-42.1C11.5,202.1-7.4,183.3-30.6,183.3z M-12.9,230.6h-18.2c-2.6,0-4.8-2.1-4.8-4.8V199c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8v22.2h13.4c2.6,0,4.8,2.1,4.8,4.8C-8.2,228.5-10.3,230.6-12.9,230.6z"/>' +
    '</svg>';

  basicUserSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M-30.2,271.6L-30.2,271.6h-0.5h-38.4c0-28.1,24.7-28.1,30.2-35.5l0.6-3.4c-7.7-3.9-13.1-13.3-13.1-24.3c0-14.5,9.4-26.2,21-26.2s21,11.7,21,26.2c0,10.9-5.3,20.2-12.9,24.2l0.7,3.8c6,7,29.9,7.4,29.9,35.1h-38.5V271.6z"/>' +
    '</g>' +
    '</svg>';

  basicSmallClockSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'clockColour' + '" d="M39.5,131.3c-18.6,0-33.6,15-33.6,33.6c0,18.6,15,33.6,33.6,33.6s33.6-15,33.6-33.6S58.1,131.3,39.5,131.3zM53.6,169.1H39.1c-2.1,0-3.8-1.7-3.8-3.8v-21.5c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8v17.7h10.7c2.1,0,3.8,1.7,3.8,3.8S55.7,169.1,53.6,169.1z"/>' +
    '<path fill="white" d="M53.6,161.5H42.9v-17.7c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8v21.5c0,2.1,1.7,3.8,3.8,3.8h14.5c2.1,0,3.8-1.7,3.8-3.8S55.7,161.5,53.6,161.5z"/>' +
    '<path fill="white" d="M39.5,121.2c-24.1,0-43.7,19.6-43.7,43.7c0,24.1,19.6,43.7,43.7,43.7s43.7-19.6,43.7-43.7C83.2,140.8,63.6,121.2,39.5,121.2z M39.5,198.5c-18.6,0-33.6-15.1-33.6-33.6s15.1-33.6,33.6-33.6s33.6,15.1,33.6,33.6S58.1,198.5,39.5,198.5z"/>' +
    '</g>' +
    '</svg>';

  basicSmartPointSvg = '' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="34px" viewBox="0 0 448 500" style="enable-background:new 0 0 448 500;" xml:space="preserve">' +
    '<g>' +
    '<ellipse style="paint-order: stroke; stroke-width: 10px; stroke: rgb(255, 255, 255); fill: rgb(170, 170, 170);" cx="370.603" cy="85.271" rx="78.968" ry="78.968"/>' +
    '<path d="M 403.373 77.442 L 398.956 77.442 L 398.956 64.189 C 398.956 48.766 386.403 36.214 370.98 36.214 C 355.557 36.214 343.005 48.766 343.005 64.189 L 343.005 77.442 L 338.588 77.442 C 333.711 77.442 329.754 81.399 329.754 86.275 L 329.754 121.612 C 329.754 126.49 333.711 130.447 338.588 130.447 L 403.373 130.447 C 408.25 130.447 412.207 126.49 412.207 121.612 L 412.207 86.275 C 412.207 81.399 408.25 77.442 403.373 77.442 Z M 384.232 77.442 L 357.729 77.442 L 357.729 64.189 C 357.729 56.884 363.675 50.938 370.98 50.938 C 378.288 50.938 384.232 56.884 384.232 64.189 L 384.232 77.442 Z" style="fill: rgb(255, 255, 255);"/>' +
    '</g>' +
    '</svg>';

  domMarkerSvg = '<div style="width: 1px; height: 1px; position: relative">' +
    '<span ' + 'idPlaceholder' + ' class="marker-icon" style="position: absolute; bottom: -6px; left: -17px;">' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" viewBox="-138 121.2 221.2 259.8" style="enable-background:new -138 121.2 221.2 259.8;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'borderColour' + '" d="M28.1,163.4c-15.8-16.3-36.6-25.3-58.5-25.3c-46.8,0-84.8,41.1-84.8,91.6c0,13.3,2.5,25.9,7.4,37.2c12.9,30.9,48.3,84.6,68,109.5c2.3,2.9,5.7,4.6,9.4,4.6c3.5,0,6.7-1.5,9.1-4.2l0.1-0.2l0.1-0.2c19.5-24.5,54.8-77.9,68-109.4c4.9-11.9,7.3-24.4,7.3-37.1C54.4,204.7,45.1,180.9,28.1,163.4z M39.7,263.8c-12.5,29.9-47,82.5-66.8,107.4c-0.9,1-2,1.6-3.3,1.6s-2.5-0.6-3.3-1.6c-19.7-24.9-54.3-77.6-66.8-107.6c-4.5-10.5-6.7-21.9-6.7-34c0-46,34.5-83.5,76.9-83.5s76.9,37.4,76.7,83.7C46.4,241.9,44.1,253.4,39.7,263.8z"/>' +
    '<path fill="' + 'markerColour' + '" d="M-30.3,146.4c-42.4,0-76.9,37.4-76.9,83.5c0,12,2.2,23.4,6.7,34c12.5,30,47.1,82.6,66.8,107.6c0.8,1,2,1.6,3.3,1.6s2.4-0.6,3.3-1.6C-7.2,346.7,27.3,294,39.8,264c4.4-10.6,6.7-22.1,6.7-34C46.6,183.7,12.1,146.4-30.3,146.4z"/>' +
    '</g>' +
    '<g>' +
    'checkboxSvg' + 'smallClockSvg' + 'asteriskSvg' + 'cancelledPortalSvg' +
    '</g>' +
    'weightSvg' + 'largeClockSvg' +
    '<text fill="white" style="font-size:75px; font-weight: 500; font-family: Roboto, sans-serif; cursor: default" x="-30" y="267" text-anchor="middle" alignement-baseline="middle">' + 'markerNumber' + '</text>' +
    'completedSvg' + 'cancelledSvg' + 'userSvg' +
    '</svg>' +
    '</span></div>';

  completedSvg = '<path fill="white" d="M-1.5,202.3l-38.9,43.9l-19.5-21.9c-3.5-3.9-9.4-3.9-13.1,0c-3.5,3.9-3.5,10.6,0,14.7 l26,29.2c3.5,3.9,9.4,3.9,13.1,0l45.5-51.3c3.7-4.1,3.7-10.8,0-14.7C8.1,198.2,2.2,198.2-1.5,202.3z"/>';
  cancelledSvg = '<path fill="white" d="M3.8,254.5c3.7,3.7,3.7,9.9,0,13.8c-3.7,3.7-9.9,3.7-13.8,0l-20.6-20.6l-20.6,20.6 c-3.7,3.7-9.9,3.7-13.8,0c-3.7-3.7-3.7-9.9,0-13.8l20.6-20.6L-65,213.3c-3.7-3.7-3.7-9.9,0-13.8c3.7-3.7,9.9-3.7,13.8,0 l20.6,20.6l20.6-20.6c3.7-3.7,9.9-3.7,13.8,0c3.7,3.7,3.7,9.9,0,13.8l-20.6,20.6L3.8,254.5z"/>';
  userSvg = '<path fill="white" d="M-30.2,271.6L-30.2,271.6h-0.5h-38.4c0-28.1,24.7-28.1,30.2-35.5l0.6-3.4c-7.7-3.9-13.1-13.3-13.1-24.3c0-14.5,9.4-26.2,21-26.2s21,11.7,21,26.2c0,10.9-5.3,20.2-12.9,24.2l0.7,3.8c6,7,29.9,7.4,29.9,35.1h-38.5V271.6z"/>';
  cancelledPortalSvg = '<path fill="white" d="M77.7,176.6L66.1,165l11.6-11.6l0.2-0.2c7.1-7.4,7-19.3-0.2-26.5l-0.2-0.2c-3.6-3.4-8.3-5.3-13.2-5.3c-5,0-9.7,1.9-13.3,5.4l-11.6,11.6l-11.6-11.6l-0.2-0.2c-3.6-3.4-8.3-5.3-13.2-5.3c-5,0-9.7,1.9-13.3,5.4l-0.2,0.2c-7.1,7.4-7,19.3,0.2,26.5L12.7,165L1.1,176.6l-0.2,0.2c-7.1,7.4-7,19.3,0.2,26.5l0.2,0.2c3.6,3.4,8.3,5.3,13.2,5.3c5,0,9.7-1.9,13.3-5.4l11.6-11.6L51,203.4l0.2,0.2c3.6,3.4,8.3,5.3,13.2,5.3c5,0,9.7-1.9,13.3-5.4l0.2-0.2C84.9,195.6,84.8,183.8,77.7,176.6z M70.6,196.2c-3.4,3.4-9,3.4-12.5,0l-18.7-18.7l-18.7,18.7c-3.4,3.4-9,3.4-12.5,0c-3.4-3.4-3.4-9,0-12.5L26.9,165L8.2,146.3c-3.4-3.4-3.4-9,0-12.5c3.4-3.4,9-3.4,12.5,0l18.7,18.7l18.7-18.7c3.4-3.4,9-3.4,12.5,0c3.4,3.4,3.4,9,0,12.5L51.9,165l18.7,18.7C73.9,187,73.9,192.7,70.6,196.2z"/>' +
    '<path fill="' + 'markerColour' + '" d="M51.9,165l18.7-18.7c3.4-3.5,3.4-9.2,0-12.5c-3.5-3.4-9.2-3.4-12.5,0l-18.7,18.7l-18.7-18.7c-3.5-3.4-9.2-3.4-12.5,0c-3.4,3.5-3.4,9.2,0,12.5L26.9,165L8.2,183.7c-3.4,3.5-3.4,9.2,0,12.5c3.5,3.4,9.2,3.4,12.5,0l18.7-18.7l18.7,18.7c3.5,3.4,9.2,3.4,12.5,0c3.4-3.5,3.4-9.2,0-12.5L51.9,165z"/>';

  checkbox = '<g id="checkbox">' +
    '<path fill="white" d="M63.4,293.7H15.6c-10.9,0-19.7,8.9-19.7,19.7v47.8c0,10.9,8.9,19.7,19.7,19.7h47.8c10.9,0,19.7-8.9,19.7-19.7v-47.8C83.1,302.6,74.3,293.7,63.4,293.7z M73.1,361.3c0,5.4-4.3,9.7-9.7,9.7H15.6c-5.4,0-9.7-4.4-9.7-9.7v-47.8c0-5.4,4.3-9.7,9.7-9.7h47.8c5.3,0,9.7,4.4,9.7,9.7V361.3z"/>' +
    // '<path fill="' + 'editColour' + '" d="M57.4,309.7H9.6c-5.4,0-9.7,4.4-9.7,9.7v47.8c0,5.4,4.3,9.7,9.7,9.7h47.8c5.4,0,9.7-4.4,9.7-9.7v-47.8C67.1,314.1,62.7,309.7,57.4,309.7z"/>' +
    '<g>' +
    '<path fill="' + 'editColour' + '" class="st2" d="M39.8,370.9h23.6c5.4,0,9.7-4.4,9.7-9.7v-23.8H39.8V370.9z"/>' +
    '<path fill="' + 'editColour' + '" class="st2" d="M39.5,337.4H5.9v23.8c0,5.4,4.3,9.7,9.7,9.7h23.9h0.3v-33.5H39.5z"/>' +
    '<path fill="' + 'editColour' + '" class="st2" d="M73.1,337.4v-24c0-5.3-4.4-9.7-9.7-9.7H39.8v33.7H73.1z"/>' +
    '<path fill="' + 'editColour' + '" class="st2" d="M39.5,303.7H15.6c-5.4,0-9.7,4.4-9.7,9.7v24h33.6h0.3v-33.7H39.5z"/>' +
    '</g>' +
    'checkSvg' +
    'beforeAfterSvg' +
    '</g>';

  beforeAfter = '<g id="beforeAfter">' +
    '<path fill="white" d="M66.7,336.6c-0.1-0.4-0.2-0.6-0.4-0.8l-22.7-23.6c-0.4-0.4-1-0.1-1.2,0.8c-0.1,0.3-0.1,0.5-0.1,0.8V361c0,1,0.4,1.8,0.9,1.8c0.1,0,0.3-0.1,0.4-0.2L66.3,339C66.7,338.6,66.9,337.5,66.7,336.6z"/>' +
    '<path fill="white" d="M12,338.2c0.1,0.4,0.2,0.6,0.4,0.8l22.7,23.6c0.4,0.4,1,0.1,1.2-0.8c0.1-0.3,0.1-0.5,0.1-0.8v-47.2c0-1-0.4-1.8-0.9-1.8c-0.1,0-0.3,0.1-0.4,0.2l-22.7,23.6C12,336.2,11.8,337.3,12,338.2z"/>' +
    '</g>';

  check = '<path id="checked" fill="white" d="M56.3,320.6l-22,24.8l-11-12.4c-2-2.2-5.3-2.2-7.4,0c-2,2.2-2,6,0,8.3l14.7,16.5c2,2.2,5.3,2.2,7.4,0l25.7-29c2.1-2.3,2.1-6.1,0-8.3C61.7,318.3,58.4,318.3,56.3,320.6z"/>';

  smallClock = '<g id="timeWindow">' +
    '<path fill="' + 'clockColour' + '" d="M39.5,131.3c-18.6,0-33.6,15-33.6,33.6c0,18.6,15,33.6,33.6,33.6s33.6-15,33.6-33.6S58.1,131.3,39.5,131.3zM53.6,169.1H39.1c-2.1,0-3.8-1.7-3.8-3.8v-21.5c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8v17.7h10.7c2.1,0,3.8,1.7,3.8,3.8S55.7,169.1,53.6,169.1z"/>' +
    '<path fill="white" d="M53.6,161.5H42.9v-17.7c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8v21.5c0,2.1,1.7,3.8,3.8,3.8h14.5c2.1,0,3.8-1.7,3.8-3.8S55.7,161.5,53.6,161.5z"/>' +
    '<path fill="white" d="M39.5,121.2c-24.1,0-43.7,19.6-43.7,43.7c0,24.1,19.6,43.7,43.7,43.7s43.7-19.6,43.7-43.7C83.2,140.8,63.6,121.2,39.5,121.2z M39.5,198.5c-18.6,0-33.6-15.1-33.6-33.6s15.1-33.6,33.6-33.6s33.6,15.1,33.6,33.6S58.1,198.5,39.5,198.5z"/>' +
    '</g>';

  asterisk = '<g id="asterisk">' +
    '<path fill="white" d="M-62.6,164.9c3.7-3.3,5.8-7.6,5.8-12.4c0-3.4-1-6.6-3-9.8c-3.3-5.7-8.5-8.7-14.6-8.7c-2,0-3.8,0.3-5.8,0.9c-1.6-8.4-8.1-13.7-17.4-13.7c-9.1,0-15.8,5.4-17.4,13.7c-1.8-0.6-3.7-0.9-5.5-0.9c-6,0-11.5,3.1-14.8,8.7c-1.8,2.7-2.7,6-2.7,9.6c0,4.8,2.1,9.2,5.8,12.6c-3.7,3.1-5.8,7.6-5.8,12.7c0,3.4,0.9,6.5,2.6,9.2c3.3,5.7,8.7,8.9,14.9,8.9c2,0,3.7-0.3,5.5-0.9c1.6,8.4,8.3,13.7,17.4,13.7c9.2,0,15.8-5.4,17.4-13.7c2,0.6,3.8,0.9,5.8,0.9c6,0,11.3-3.3,14.6-8.7c2-3.1,3-6.4,3-9.8C-56.9,172.4-59,168.1-62.6,164.9z M-70.7,171.8c2.6,1.5,4.2,3.4,4.2,5.8c0,1.5-0.4,2.7-1.5,4.3c-1.5,2.5-3.4,3.8-5.8,3.8c-1.5,0-2.7-0.4-4.3-1.2l-11.9-6.9v13.9c0,4.8-2.5,7.3-7.3,7.3s-7.3-2.5-7.3-7.3V178l-11.6,6.6c-1.5,0.7-2.7,1.2-4.3,1.2c-2.5,0-4.6-1.3-5.9-3.8c-0.7-1.3-1.2-2.6-1.2-4.2c0-2.6,1.3-4.7,4.2-5.9l11.9-6.9l-11.9-6.9c-2.6-1.5-4.2-3.4-4.2-5.9c0-1.6,0.4-3,1.2-4.2c1.5-2.5,3.4-3.8,5.9-3.8c1.5,0,2.7,0.4,4.3,1.2l11.6,6.6v-13.5c0-4.8,2.5-7.3,7.3-7.3s7.3,2.5,7.3,7.3v13.9l11.9-6.9c1.5-0.7,2.7-1.2,4.3-1.2c2.3,0,4.3,1.3,5.8,3.8c0.9,1.5,1.5,2.7,1.5,4.3c0,2.3-1.3,4.3-4.2,5.8l-11.9,6.9L-70.7,171.8z"/>' +
    '<path fill="' + 'asteriskColour' + '" d="M-66.7,152.2c0-1.5-0.4-2.7-1.5-4.3c-1.5-2.5-3.4-3.8-5.8-3.8c-1.5,0-2.7,0.4-4.3,1.2l-11.9,6.9v-13.9c0-4.8-2.5-7.3-7.3-7.3s-7.3,2.5-7.3,7.3v13.5l-11.6-6.6c-1.5-0.7-2.7-1.2-4.3-1.2c-2.5,0-4.6,1.3-5.9,3.8c-0.7,1.2-1.2,2.5-1.2,4.2c0,2.5,1.3,4.6,4.2,5.9l11.9,6.9l-11.9,6.9c-2.6,1.3-4.2,3.3-4.2,5.9c0,1.5,0.4,2.7,1.2,4.2c1.5,2.5,3.4,3.8,5.9,3.8c1.5,0,2.7-0.4,4.3-1.2l11.6-6.6v13.5c0,4.8,2.5,7.3,7.3,7.3s7.3-2.5,7.3-7.3v-13.9l11.9,6.9c1.5,0.7,2.7,1.2,4.3,1.2c2.3,0,4.3-1.3,5.8-3.8c0.9-1.5,1.5-2.7,1.5-4.3c0-2.3-1.3-4.3-4.2-5.8l-11.9-6.9l11.9-6.9C-68.1,156.6-66.7,154.6-66.7,152.2z"/>' +
    '</g>';

  largeClock = '<path id="clock" fill="white" d="M-30.6,183.3c-23.2,0-42.1,18.8-42.1,42.1s18.8,42.1,42.1,42.1s42.1-18.8,42.1-42.1C11.5,202.1-7.4,183.3-30.6,183.3z M-12.9,230.6h-18.2c-2.6,0-4.8-2.1-4.8-4.8V199c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8v22.2h13.4c2.6,0,4.8,2.1,4.8,4.8C-8.2,228.5-10.3,230.6-12.9,230.6z"/>';

  weight = '<path id="weight" fill="white" d="M12.3,265.6l-9.9-51.3c-0.4-2.2-2.3-3.8-4.6-3.8h-16.5c3.1-3,5-7.3,5-11.9c0-9.2-7.5-16.8-16.8-16.8s-16.8,7.5-16.8,16.8c0,4.7,1.9,8.9,5,11.9h-16.5c-2.3,0-4.2,1.6-4.6,3.8l-9.9,51.3c-0.2,1.4,0.1,2.8,1,3.8c0.9,1.1,2.2,1.7,3.6,1.7H7.6c1.4,0,2.7-0.6,3.6-1.7C12.1,268.4,12.5,266.9,12.3,265.6z M-30.6,191.1c4.1,0,7.4,3.3,7.4,7.4c0,4.1-3.3,7.4-7.4,7.4s-7.4-3.3-7.4-7.4C-38,194.4-34.6,191.1-30.6,191.1z"/>';

  svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" width="40" viewBox="-743 1001 48 48">' +
    '<path fill="' + 'markerColour' + '" stroke="white" stroke-width="1px" d="M-719,1005c-7.7,0-14,6.3-14,14c0,10.5,14,26,14,26s14-15.5,14-26C-705,1011.3-711.3,1005-719,1005z"/>' +
    '</svg>';

  svgPinMarkerWithHole = (fill, strokeColor = 'white', strokeWidth = '20') => `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="36" height="44" 
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;">
 <g>
   <g stroke="${strokeColor}" stroke-width="${strokeWidth}">
     <path fill="${fill}" d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
       c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
       c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/>
   </g>
 </g>
 <g></g><g></g><g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g></g>
 <g>
 </g>
 <g>
 </g>
 </svg>`

  svgDepotMarker = '<svg xmlns="http://www.w3.org/2000/svg" baseProfile="basic" width="30" viewBox="-743 1001 48 48" xml:space="preserve">' +
    '<path fill="' + 'markerColour' + '" stroke="white" stroke-width="2px" d="M-719,1045c-11.1,0-20-8.9-20-20s8.9-20,20-20s20,8.9,20,20S-707.9,1045-719,1045z M-707.5,1021.5l-10.9-7.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.4,0.1-0.5,0.2l-10.9,7.3c-0.2,0.1-0.4,0.5-0.4,0.7c0,0.5,0.4,0.9,0.9,0.9h1.4c0.3,0,0.5,0.2,0.5,0.5v11.4c0,0.5,0.4,0.9,0.9,0.9h16.4c0.5,0,0.9-0.4,0.9-0.9v-11.4c0-0.3,0.2-0.5,0.5-0.5h1.4c0.5,0,0.9-0.4,0.9-0.9C-707.2,1022-707.3,1021.7-707.5,1021.5z M-714,1032.3h-2.7c-0.3,0-0.5-0.2-0.5-0.5v-4.1c0-0.5-0.4-0.9-0.9-0.9h-1.8c-0.5,0-0.9,0.4-0.9,0.9v4.1c0,0.3-0.2,0.5-0.5,0.5h-2.7c-0.3,0-0.5-0.2-0.5-0.5v-9.6c0-0.2,0.1-0.3,0.2-0.4l5-3.4c0.2-0.1,0.4-0.1,0.5,0l5,3.4c0.1,0.1,0.2,0.2,0.2,0.4v9.6C-713.5,1032.1-713.7,1032.3-714,1032.3z"/>' +
    '</svg>';

  svgDepotMarkerInstaShop = '<svg width="28" version="1.0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">' +
    '<g transform="translate(0 512) scale(.1 -.1)">' +
    '<path fill="#ed2e5d" d="m432 5104c-183-49-336-191-403-374l-24-65v-2110-2110l23-60c62-165 210-309 368-361 57-18 115-19 2164-19s2107 1 2164 19c158 52 306 196 368 361l23 60v2110 2110l-24 65c-68 185-221 326-407 375-85 22-4169 22-4252-1zm1914-888c28-13 60-37 72-53 30-43 45-110 34-158-5-22-76-157-159-300-167-289-343-594-470-817-46-81-97-161-114-177-90-90-246-64-303 51-30 61-33 108-7 163 10 22 172 308 360 635 233 405 354 605 379 628 62 56 129 65 208 28zm603-6c22-11 48-32 58-47 64-92 723-1254 728-1284 16-88-48-187-138-214-46-14-57-14-104 0-30 9-67 28-82 43-16 15-115 176-218 357-104 182-265 462-358 622-187 327-195 349-157 435 21 47 71 93 119 108 39 13 106 4 152-20zm-1729-1352c0-89 10-128 52-198 59-98 188-170 303-170 98 0 213 53 274 127 14 17 62 95 106 173l80 140 525-3 525-2 83-145c96-167 132-209 222-253 57-28 74-32 150-32 72 0 94 4 145 28 78 37 146 104 182 181 23 51 28 75 28 144v83l251-3c246-3 251-3 286-27 50-34 78-80 85-139 14-125-76-222-209-222-35 0-38-3-47-37-10-39-143-540-166-628-8-27-38-142-69-255-30-113-66-250-81-305s-35-130-45-167c-33-124-95-199-200-239-48-18-95-19-1140-19-1089 0-1089 0-1147 21-63 24-138 91-165 149-12 26-272 989-389 1441-9 36-10 36-69 42-107 9-180 75-188 171-5 68 9 113 51 156 55 57 69 59 330 60h237v-72z"/>' +
    '<path fill="#ed2e5d" d="m1604 2189c-11-6-28-23-37-39-15-25-17-74-17-460 0-385 2-435 17-460 33-57 104-66 155-21l33 29 3 437c2 380 1 440-13 469-24 50-87 70-141 45z"/>' +
    '<path fill="#ed2e5d" d="m2201 2184c-49-35-51-53-51-494s2-459 51-494c53-37 139-6 154 56 3 12 4 220 3 461l-3 439-28 24c-33 29-91 32-126 8z"/>' +
    '<path fill="#ed2e5d" d="m2793 2176-28-24-3-457-3-456 32-30c53-50 135-34 163 31 8 20 10 149 6 469l-5 443-28 24c-38 33-96 33-134 0z"/>' +
    '<path fill="#ed2e5d" d="m3410 2187c-51-40-50-31-50-495 0-387 2-437 17-462 33-57 104-66 155-21l33 29 3 447 3 446-35 35c-27 27-42 34-72 34-22 0-46-6-54-13z"/>' +
    '</g>' +
    '</svg>';

  svgMakerBackground = '<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30"> ' +
    '<circle cx="15" cy="15" r="10" fill="#ffffff" /></svg>';

  svgVehicleMakerClientPortal = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">' +
    '<path stroke="white" stroke-width="1px" fill-rule="evenodd" clip-rule="evenodd" fill="' + 'markerColour' + '" d="M36.139,31.844l-3.917,11.531h36.556l-3.917-11.531H36.139z M64.861,48.5c-2.089,0-3.917,1.794-3.917,3.844c0,2.05,1.828,3.844,3.917,3.844c2.089,0,3.917-1.794,3.917-3.844C68.778,50.294,66.95,48.5,64.861,48.5z M36.139,48.5c-2.089,0-3.917,1.794-3.917,3.844c0,2.05,1.828,3.844,3.917,3.844c2.089,0,3.917-1.794,3.917-3.844C40.055,50.294,38.228,48.5,36.139,48.5z M50,11c-21.539,0-39,17.461-39,39c0,21.539,17.461,39,39,39s39-17.461,39-39C89,28.461,71.539,11,50,11z M74,66.437C74,67.975,72.955,69,71.389,69h-2.611c-1.567,0-2.611-1.025-2.611-2.563v-2.563H34.833v2.563c0,1.537-1.044,2.563-2.611,2.563h-2.611C28.044,69,27,67.975,27,66.437v-20.5l5.483-15.375C33.005,29.025,34.311,28,36.139,28h28.722c1.567,0,3.133,1.025,3.656,2.562L74,45.937V66.437z"/></svg>';

  smartPointMarker = '<div style="width: 1px; height: 1px; position: relative;"><span class="fix-smart-point-icon">' +
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox = "0 0 448 512"">' +
    '<style type="text/css"> .st1 { fill: white; } </style>' +
    '<path class="st1" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"/>' +
    + '</svg></span></div>';

  newSmartPointMarker = '<div style="width: 1px; height: 1px; position: relative;"><span class="fix-smart-point-new-icon">' +
    '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">' +
    '<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">' +
    '<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651 -146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586 229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703 734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145 l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>' +
    '<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54 l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3 0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158 158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66 -456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064 0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>' +
    '</g>' +
    '</svg>' +
    '</span></div>';

  smartPointCompleteMarker = '<div style="width: 1px; height: 1px; position: relative;"><span class="fix-smart-point-icon">' +
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18"">
  <style type="text/css"> .st1 { fill: white; } </style>
  <path class="st1" d="M 358.374 204.507 L 337.789 204.507 L 337.789 142.754 C 337.789 70.881 279.296 12.387 207.422 12.387 C 135.549 12.387 77.056 70.881 77.056 142.754 L 77.056 204.507 L 56.471 204.507 C 33.743 204.507 15.303 222.947 15.303 245.675 L 15.303 410.349 C 15.303 433.078 33.743 451.518 56.471 451.518 L 221.573 452.065 C 146.598 324.881 301.509 222.33 399.542 288.593 L 399.542 245.675 C 399.542 222.947 381.102 204.507 358.374 204.507 Z M 269.175 204.507 L 145.67 204.507 L 145.67 142.754 C 145.67 108.704 173.373 81.002 207.422 81.002 C 241.472 81.002 269.175 108.704 269.175 142.754 L 269.175 204.507 Z" style=""/>
  <path class="st1" d="M 439.955 392.749 C 439.955 454.396 389.981 504.37 328.334 504.37 C 266.689 504.37 216.715 454.396 216.715 392.749 C 216.715 331.105 266.689 281.131 328.334 281.131 C 389.981 281.131 439.955 331.105 439.955 392.749 Z M 315.423 451.851 L 398.238 369.036 C 401.051 366.225 401.051 361.665 398.238 358.854 L 388.053 348.671 C 385.242 345.859 380.682 345.859 377.869 348.671 L 310.332 416.209 L 278.799 384.676 C 275.988 381.864 271.427 381.864 268.614 384.676 L 258.43 394.861 C 255.619 397.672 255.619 402.23 258.43 405.042 L 305.238 451.851 C 308.052 454.666 312.611 454.666 315.423 451.851 Z" style=""/>
  </svg></span></div>`

  circleWithText2 = '<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -15px; left: -15px;">' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-shadow" width="' + 'circleSize' + 'px" x="0px" y="0px" viewBox="-77 78 45 45" style="enable-background:new 0 0 25.7 25.7;" xml:space="preserve">' +
    '<g>' +
    '<path fill="' + 'markerBorderColour' + '" d="M-54.5,78C-66.9,78-77,88.1-77,100.5S-66.9,123-54.5,123S-32,112.9-32,100.5 S-42.1,78-54.5,78z M-54.5,119.7c-10.6,0-19.2-8.6-19.2-19.2s8.6-19.2,19.2-19.2s19.2,8.6,19.2,19.2S-43.9,119.7-54.5,119.7z"/>' +
    '<circle fill="' + 'markerColour' + '" cx="-54.5" cy="100.5" r="19.2"/>' +
    '</g>' +
    '<text fill="white" transform="matrix(1 0 0 1 -55.3956 108.2791)" style="font-size: 20px; font-family: Roboto, sans-serif; cursor: default" text-anchor="middle" alignement-baseline="middle">' + 'textInput' + '</text>' +
    '</svg>' +
    '</span></div>';

  clusterMarker = '<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -15px; left: -15px;">' +
    '<svg xmlns="http://www.w3.org/2000/svg" width="' + 'circleSize' + 'px" x="0px" y="0px" viewBox="-138 121.2 221.2 220.8" style="enable-background:new -138 121.2 221.2 220.8;" xml:space="preserve">' +
    '<g>' +
    '<g>' +
    '<path fill="' + 'markerBorderColour' + '" d="M50.8,153.6C30,132.8,2.3,121.3-27.3,121.3s-57.3,11.5-78.1,32.3c-20.8,20.8-32.3,48.5-32.3,78.1s11.5,57.3,32.3,78.1c20.8,20.8,48.5,32.3,78.1,32.3s57.3-11.5,78.1-32.3c20.8-20.8,32.3-48.5,32.3-78.1S71.6,174.4,50.8,153.6zM-27.3,332c-55.6,0-100.4-44.8-100.4-100.4s44.8-100.4,100.4-100.4S73.1,176.1,73.1,231.7S28.3,332-27.3,332z" />' +
    '<path fill="' + 'markerColour' + '" d="M-27.3,131.3c-55.6,0-100.4,44.8-100.4,100.4S-82.8,332-27.3,332S73.1,287.2,73.1,231.7S28.3,131.3-27.3,131.3z" />' +
    '</g>' +
    '<g>' +
    'checkboxSvg' +
    '<text transform="matrix(1 0 0 1 -30.1435 270.641)" fill="white" style="font-size: 100px; font-family: Roboto, sans-serif; cursor: default" text-anchor="middle" alignement-baseline="middle">' + 'textInput' + '</text>' +
    '</g>' +
    '</svg>' +
    '</span></div>';

  clusterCheckbox = '<path fill="white" d="M63.4,254.8H15.6c-10.9,0-19.7,8.9-19.7,19.7v47.8c0,10.9,8.9,19.7,19.7,19.7h47.8c10.9,0,19.7-8.9,19.7-19.7v-47.8C83.1,263.7,74.3,254.8,63.4,254.8z M73.1,322.4c0,5.4-4.3,9.7-9.7,9.7H15.6c-5.4,0-9.7-4.4-9.7-9.7v-47.8c0-5.4,4.3-9.7,9.7-9.7h47.8c5.3,0,9.7,4.4,9.7,9.7L73.1,322.4z" />' +
    '<g>' +
    '<path fill="#00aeba" d="M39.8,332h23.6c5.4,0,9.7-4.4,9.7-9.7v-23.8H39.8V332z" />' +
    '<path fill="#00aeba" d="M39.5,298.5H5.9v23.8c0,5.4,4.3,9.7,9.7,9.7h23.9h0.3v-33.5H39.5z" />' +
    '<path fill="#00aeba" d="M73.1,298.5v-24c0-5.3-4.4-9.7-9.7-9.7H39.8v33.7H73.1z" />' +
    '<path fill="#00aeba" d="M39.5,264.8H15.6c-5.4,0-9.7,4.4-9.7,9.7v24h33.6h0.3v-33.7H39.5z" />' +
    '</g>' +
    '<path fill="white" d="M56.3,281.7l-22,24.8l-11-12.4c-2-2.2-5.3-2.2-7.4,0c-2,2.2-2,6,0,8.3l14.7,16.5c2,2.2,5.3,2.2,7.4,0l25.7-29c2.1-2.3,2.1-6.1,0-8.3C61.7,279.4,58.4,279.4,56.3,281.7z" />' +
    '</g>';

  svgVehicleMaker = '<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -15px; left: -15px;">' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-shadow" width="30px" x="0px" y="0px" viewBox="-77 78 45 45" style="enable-background:new 0 0 25.7 25.7;" xml:space="preserve">' +
    '<g>' +
    '<path fill="white" d="M-54.5,78C-66.9,78-77,88.1-77,100.5S-66.9,123-54.5,123S-32,112.9-32,100.5 S-42.1,78-54.5,78z M-54.5,119.7c-10.6,0-19.2-8.6-19.2-19.2s8.6-19.2,19.2-19.2s19.2,8.6,19.2,19.2S-43.9,119.7-54.5,119.7z"/>' +
    '<circle fill="' + 'markerColour' + '" cx="-54.5" cy="100.5" r="19.2"/>' +
    '</g>' +
    '<text fill="white" transform="matrix(1 0 0 1 -55.3956 113.2791)" style="font-size:36.8848px; font-family: Roboto, sans-serif; cursor: default; user-select: none;" text-anchor="middle" alignement-baseline="middle">' + 'initials' + '</text>' +
    '</svg>' +
    '</span></div>';

  // navigator pointing right
  // svgVehicleMarkerMoving = '<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -15px; left: -15px; transform: rotate(' + 'rotationAngle' + 'deg);">' +
  //   '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-shadow" width="36px" x="0px" y="0px"' +
  //   'viewBox="-14.4 237.8 442.7 318.3" style="enable-background:new -14.4 237.8 442.7 318.3;" xml:space="preserve">' +
  //   '<path stroke="white" stroke-width="30" style="fill: ' + 'markerColour' + '" d="M421.7,387.6L-1.1,238.4c-9.5-3.4-17.4,7.9-11,15.7l112.7,136.5c3,3.7,3,9,0,12.6L-12,539.9 c-6.4,7.8,1.5,19,11,15.7l422.8-149.2C430.6,403.3,430.6,390.8,421.7,387.6z"/>' +
  //   '</svg>' +
  //   '</span></div>';

  // navigator pointing up
  svgVehicleMarkerMoving = '<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -15px; left: -15px; transform: rotate(' + 'rotationAngle' + 'deg);">' +
    '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-shadow" width="25px" x="0px" y="0px" viewBox="-21.9 175.4 318.4 442.8" style="enable-background:new -21.9 175.4 318.4 442.8;" xml:space="preserve">' +
    '<path stroke="white" stroke-width="10" style="fill: ' + 'markerColour' + '" d="M128,182.1L-21.2,604.9c-3.4,9.5,7.9,17.4,15.7,11L131,503.2c3.7-3,9-3,12.6,0l136.7,112.6c7.8,6.4,19-1.5,15.7-11L146.8,182C143.7,173.2,131.2,173.2,128,182.1z"/>' +
    '</svg>' +
    '</span></div>';

  timelapse = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="15">' +
    '<path d="M32.49 15.51C30.14 13.17 27.07 12 24 12v12l-8.49 8.49c4.69 4.69 12.28 4.69 16.97 0 4.69-4.69 4.69-12.29.01-16.98zM24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.96 20-20c0-11.05-8.95-20-20-20zm0 36c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"/>' +
    '</svg>';

  svgCircle = '<svg width="16" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="8" cy="8" r="4" fill="white" stroke="#00aeba" stroke-width="4"/>' +
    '</svg>';

  svgCircleColour = '<svg width="16" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="8" cy="8" r="4" fill="white" stroke="' + 'markerColour' + '" stroke-width="4"/>' +
    '</svg>';

  svgCircle2 = '<svg width="10" height="10" viewBox="-1 -1 2 2" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    '<circle cx="0" cy="0" r="1"  fill="#00aeba" />' +
    '</svg>';

  vehicle0 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M407.531,206.527c-13.212,0-25.855,2.471-37.501,6.966c-9.124-20.276-17.007-41.719-20.944-61.668c-6.323-32.038-34.634-55.291-67.318-55.291c-8.284,0-15,6.716-15,15s6.716,15,15,15c3.569,0,7.044,0.498,10.355,1.423c10.063,2.812,18.602,9.618,23.582,18.758c-0.403,0.512-0.787,1.043-1.128,1.618l-4.66,7.845l-23.576,39.69H160.377l-7.16-18.021h2.972c8.284,0,15-6.716,15-15s-6.716-15-15-15H104.47c-8.284,0-15,6.716-15,15s6.716,15,15,15h16.466l13.09,32.944c-9.376-2.77-19.294-4.265-29.556-4.265C46.865,206.527,0,253.392,0,310.996s46.865,104.469,104.469,104.469c52.511,0,96.091-38.946,103.388-89.469h27.547c5.292,0,10.193-2.789,12.896-7.339l78.827-132.706c4.624,14.31,10.412,28.648,16.651,42.346c-24.747,19.122-40.716,49.079-40.716,82.7c0,57.604,46.865,104.469,104.469,104.469S512,368.601,512,310.997S465.135,206.527,407.531,206.527z M104.469,325.996h72.951c-6.96,33.897-37.025,59.469-72.951,59.469C63.407,385.464,30,352.058,30,310.996c0-41.062,33.407-74.469,74.469-74.469c35.926,0,65.991,25.572,72.951,59.469h-72.951c-8.284,0-15,6.716-15,15S96.185,325.996,104.469,325.996z M226.867,295.996h-19.01c-3.481-24.099-15.216-45.561-32.241-61.421c-0.156-0.602-0.337-1.202-0.573-1.795l-2.746-6.911h96.225L226.867,295.996z M407.531,385.464c-41.063,0-74.469-33.407-74.469-74.469c0-21.753,9.378-41.355,24.301-54.983c18.448,35.256,36.467,61.538,37.823,63.504c2.911,4.217,7.594,6.48,12.358,6.48c2.938,0,5.907-0.862,8.508-2.657c6.818-4.706,8.53-14.048,3.824-20.866c-0.323-0.468-18.475-26.939-36.652-61.853c7.624-2.641,15.797-4.095,24.307-4.095c41.062,0,74.469,33.407,74.469,74.469C482,352.056,448.593,385.464,407.531,385.464z"/>' +
    '</svg>';

  vehicle1 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="80px" viewBox="156 -156 512 512" style="enable-background:new 156 -156 512 512;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M642.7,154.1c9-1.7,16.6-4.2,20.6-8.1c3-2.9,4.7-6.7,4.7-10.6c0-22-42.1-42.7-74.7-42.7c-23.1,0-32.3-24-32.9-25.5 L525.7-14H540c4.9,0,9.2-3.3,10.3-8.1L561-64.8c0.9-3.5-0.1-7.3-2.6-9.9c-2.5-2.6-6.3-3.8-9.8-3.1l-33.2,6.6 c-4.4,0.9-8.4,2.8-11.9,5.4c-6.9-14.3-22.6-21.8-38.2-17.9c-9,2.2-16.7,8.2-21.2,16.3c-4.5,8.1-5.4,17.9-2.4,26.7l3.7,11.2 l-28.2,16.9c-5,3-6.7,9.6-3.6,14.6c2,3.3,5.5,5.2,9.2,5.2c1.9,0,3.8-0.5,5.5-1.5l24.1-14.5l0,0l15-9l14.2-8.5c0,0,0,0,0,0 c5.1-3,11.6-1.4,14.6,3.7c3,5.1,1.4,11.6-3.6,14.6L459.2,12l16.1,48.2L426.2,178h-55.8c1.3-12.4,3.6-32.9,6.9-51.5 c0.5-3.1-0.3-6.3-2.3-8.7c-2-2.4-5-3.8-8.2-3.8H230.7c-12.4,0-24.2-4.4-34.1-12.7c-4.3-3.6-10.8-3.3-14.6,0.9 c-2,2.1-3.9,4.3-5.7,6.5c-13.1,16.1-20.3,36.9-20.3,58.7c0,17.6,14.4,32,32,32h11.5c-0.6,3.6-0.9,7.1-0.9,10.7 c0,41.2,33.5,74.7,74.7,74.7c41.2,0,74.7-33.5,74.7-74.7c0-3.5-0.3-7.1-0.9-10.7h11.5c0,0,0,0,0,0h74.7c0,0,0,0,0.1,0 c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.4,0c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.2,0,0.3-0.1,0.5-0.2 c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.5-0.3,0.7-0.4 c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.1-0.1,0.2-0.2 c0.2-0.2,0.4-0.4,0.6-0.7c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.3-0.4,0.4-0.6 c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.3-0.6,0.4-0.9l46.4-111.4l7.8,9.8c9.3,11.6,9.5,27.6,0.6,39.1c-6,7.8-10.9,15.9-14.5,24 c-4.4,9.9-3.5,21.3,2.5,30.4c6,9.1,16,14.6,26.9,14.6h6.5c-0.5,3.5-0.8,7.1-0.8,10.7c0,41.2,33.5,74.7,74.7,74.7 c41.2,0,74.7-33.5,74.7-74.7C668,187.8,658.2,167.8,642.7,154.1z M305.3,210c0,17.6-14.4,32-32,32s-32-14.4-32-32 c0-3.7,0.7-7.3,1.8-10.7h60.3C304.7,202.7,305.3,206.3,305.3,210z M593.3,242c-17.6,0-32-14.4-32-32s14.4-32,32-32s32,14.4,32,32 S611,242,593.3,242z"/>' +
    '<path class="vehicle-icon" d="M230.7,92.7H380c17.6,0,32-14.4,32-32c0-17.6-14.4-32-32-32H230.7c-17.6,0-32,14.4-32,32S213,92.7,230.7,92.7z"/>' +
    '</svg>';

  vehicle2 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80px" viewBox="-45 51.6 95.4 99.4" style="enable-background:new -45 51.6 95.4 99.4;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M-27.9,107c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6s-7.6-3.4-7.6-7.6S-32.1,107-27.9,107z M-27.9,118.6c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9C-31.9,116.8-30.1,118.6-27.9,118.6z"/>' +
    '<path class="vehicle-icon" d="M31.8,107c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6s-7.6-3.4-7.6-7.6S27.6,107,31.8,107z M31.8,118.6c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9C27.8,116.8,29.6,118.6,31.8,118.6z"/>' +
    '<path class="vehicle-icon" d="M-44.2,110.4l0.9-0.3c0,0,0-21.7,4.7-28.3c0.8-1.1,32.1-3.3,44.9,1.9c5.3,2.1,21.4,13,21.4,13s13.7,2.1,17.9,4.7c1.9,1.5,3,4.6,3,4.6l1.2,1.2c0.3,0.3,0.5,0.7,0.5,1.1v2.1c0,0.5-0.4,1-1,1v3.6c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0.1,0.4-0.1,0.5c-0.4,0.3-2.3,0.6-3.7,0.7c-1.3,0.2-5.5,0.6-5.5,0.6c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.1-0.8,0.1-1.1c0-5-4-9.2-9-9.2c-5.1,0-9.2,4.1-9.2,9c0,0.3,0,0.5,0,0.8c0,0.6,0.1,1.1,0.3,1.6l-41.9-0.6c0-0.2,0.1-0.4,0.1-0.5c0.1-0.4,0.1-0.8,0.1-1.1c0-5-4-9.2-9-9.2c-5.1,0-9.2,4.1-9.2,9c0,0.3,0,0.5,0,0.8c0,0.3,0.1,0.6,0.1,0.8l-5.9-0.5c-1.1-0.1-1.9-0.9-2-2l-0.2-2C-45.1,111.1-44.7,110.6-44.2,110.4z M-1.4,96c0.1,0.5,0.5,0.8,1,0.8h14.6v-1.7c0-1.1,0.8-2,1.8-2.3l-0.1-1.7c0,0-6.6-5.1-17.8-5.3c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.5-0.2,0.8L-1.4,96z"/>' +
    '</svg>';

  vehicle3 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80px" viewBox="156 -156 512 512" style="enable-background:new 156 -156 512 512;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M548.5,142.7c23.5,0,42.7,19.1,42.7,42.7c0,23.5-19.1,42.7-42.7,42.7c-23.5,0-42.7-19.1-42.7-42.7C505.9,161.8,525,142.7,548.5,142.7z M548.5,206.4c11.6,0,21-9.4,21-21s-9.4-21-21-21s-21,9.4-21,21C527.5,196.9,536.9,206.4,548.5,206.4z"/>' +
    '<path class="vehicle-icon" d="M275.5,142.7c23.5,0,42.7,19.1,42.7,42.7c0,23.5-19.1,42.7-42.7,42.7c-23.5,0-42.7-19.1-42.7-42.7C232.8,161.8,251.9,142.7,275.5,142.7z M275.5,206.4c11.6,0,21-9.4,21-21s-9.4-21-21-21c-11.6,0-21,9.4-21,21C254.4,196.9,263.9,206.4,275.5,206.4z"/>' +
    '<path class="vehicle-icon" d="M158.5,136.6l6-6v-5v-8.5V100v-8.5V-19.5c0-4.7,3.8-8.5,8.5-8.5h384c2.8,0,5.4,1.4,7,3.6L623.1,60c9.9,8.2,19.3,17.9,26.1,31.4l6.4,17.1c1.7,6.5,3.1,13.7,3.6,21.9l6.2,6.2c1.6,1.6,2.5,3.8,2.5,6v17.1c0,2.3-0.9,4.4-2.5,6l-6,6v13.5c0,4.7-3.8,8.5-8.5,8.5h-34.1c-4.7,0-8.5-3.8-8.5-8.5c0-32.9-26.8-59.7-59.7-59.7s-59.7,26.8-59.7,59.7c0,4.7-3.8,8.5-8.5,8.5H343.7c-4.7,0-8.5-3.8-8.5-8.5c0-32.9-26.8-59.7-59.7-59.7s-59.7,26.8-59.7,59.7c0,4.7-3.8,8.5-8.5,8.5h-34.1c-4.7,0-8.5-3.8-8.5-8.5v-13.5l-6-6c-1.6-1.6-2.5-3.8-2.5-6v-17.1C156,140.4,156.9,138.2,158.5,136.6z M480.3,48.8c0,4.7,3.8,8.5,8.5,8.5h93.9c3.1,0,6-1.7,7.5-4.5c1.5-2.8,1.3-6.1-0.4-8.8L555.6-7.1c-1.6-2.4-4.3-3.8-7.1-3.8h-59.7c-4.7,0-8.5,3.8-8.5,8.5V48.8z"/>' +
    '</svg>';

  vehicle4 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80px" viewBox="-76 77.9 46.1 46.1" style="enable-background:new -76 77.9 46.1 46.1;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M-74.8,84.8h31.6c0.7,0,1.3,0.5,1.3,1.2v3.6c0,0.7,0.5,1.2,1.2,1.2h4c0.5,0,0.9,0.3,1.1,0.7l3.3,6.7c0.2,0.4,0.6,0.7,1.1,0.7h0c0.7,0,1.2,0.5,1.2,1.2v9.6c0,0.7-0.5,1.2-1.2,1.2h-3.1c-0.6-3.2-3.5-5.6-6.8-5.6c-3.4,0-6.2,2.4-6.8,5.6h-2h-11.7c-0.6-3.2-3.5-5.6-6.8-5.6c-3.3,0-6.1,2.3-6.8,5.5c-0.4-0.2-0.7-0.6-0.7-1.1V86C-76,85.3-75.5,84.8-74.8,84.8z M-40.3,97.2c0,0.5,0.4,1,1,1h3.7c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0-0.6l-1.7-3.4c-0.2-0.3-0.5-0.5-0.9-0.5h-1.6c-0.5,0-1,0.4-1,1L-40.3,97.2L-40.3,97.2z"/>' +
    '<path class="vehicle-icon" d="M-68.5,107.2c2.8,0,5,2.2,5,5c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5C-73.5,109.4-71.2,107.2-68.5,107.2z M-68.5,114.4c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S-69.7,114.4-68.5,114.4z"/>' +
    '<path class="vehicle-icon" d="M-41.1,107.2c2.8,0,5,2.2,5,5c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5C-46.1,109.4-43.8,107.2-41.1,107.2z M-41.1,114.4c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S-42.3,114.4-41.1,114.4z"/>' +
    '</svg>';

  vehicle5 = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80px" viewBox="156 -156 512 512" style="enable-background:new 156 -156 512 512;" xml:space="preserve">' +
    '<path class="vehicle-icon" d="M219.9,215.2c0,28.2,23,51.2,51.2,51.2s51.2-23,51.2-51.2s-23-51.2-51.2-51.2C242.9,164,219.9,187,219.9,215.2zM245.2,215.2c0-14.3,11.6-25.9,25.9-25.9s25.9,11.6,25.9,25.9s-11.6,25.9-25.9,25.9C256.8,241.1,245.2,229.5,245.2,215.2z"/>' +
    '<path class="vehicle-icon" d="M510,215.2c0,28.2,23,51.2,51.2,51.2s51.2-23,51.2-51.2s-23-51.2-51.2-51.2C532.9,164,510,187,510,215.2z M535.2,215.2c0-14.3,11.6-25.9,25.9-25.9s25.9,11.6,25.9,25.9s-11.6,25.9-25.9,25.9C546.9,241.1,535.2,229.5,535.2,215.2z"/>' +
    '<path class="vehicle-icon" d="M156,146.9v42.7c0,4.7,3.8,8.5,8.5,8.5h34.1c3.9,0,7.1-2.6,8.2-6.1c9.7-27,35.5-45.1,64.2-45.1c28.9,0,54.8,18.3,64.4,45.6c1.2,3.4,4.6,5.6,8.2,5.6h128c4.7,0,8.5-3.8,8.5-8.5V164h25.6c2.1,0,4-0.8,5.7-2.1c10.8-8.5,32.1-14.9,49.9-14.9c28.9,0,54.8,18.3,64.4,45.6c1.2,3.4,4.6,5.6,8.2,5.6h25.4c4.7,0,8.5-3.8,8.5-8.5V95.7c0-1.7-0.5-3.3-1.4-4.7l-51.2-76.8c-1.6-2.4-4.3-3.8-7.1-3.8H505.9c-4.7,0-8.5,3.8-8.5,8.5v128h-17.1V-32.3c0-18.8-15.3-34.1-34.1-34.1H181.6c-4.7,0-8.5,3.8-8.5,8.5v196.3h-8.5C159.8,138.4,156,142.2,156,146.9z M548.5,31.7c0-2.4,1.9-4.3,4.3-4.3h48.6c1.4,0,2.8,0.7,3.6,1.9l34.1,51.2c1.9,2.8-0.1,6.6-3.6,6.6h-82.7c-2.4,0-4.3-1.9-4.3-4.3C548.5,82.9,548.5,31.7,548.5,31.7z"/>' +
    '</svg>';

  markerWithBorder = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="30px" y="30px" viewBox="-555 812 425 425" style="width:35px; height:40px; enable-background:new -555 812 425 425;" xml:space="preserve">' +
    '<switch>' +
    '<g i:extraneous="self">' +
    '<g>' +
    '<path fill="#666666" stroke="#666666" stroke-width="20px" d="M-342.5,812c-91.7,0-166.3,72-166.3,160.5c0,23.2,4.9,45.3,14.7,65.8c38.6,81.3,112.2,166.5,133.8,190.7c4.5,5,11,7.9,17.8,7.9s13.3-2.9,17.8-7.9c21.6-24.2,95.1-109.4,133.8-190.7c9.7-20.5,14.7-42.7,14.7-65.8C-176.2,884-250.8,812-342.5,812z M-204.8,1031.8c-37.7,79.4-110.1,163.2-131.3,187c-1.6,1.8-3.9,2.8-6.4,2.8c-2.4,0-4.8-1-6.4-2.8c-21.3-23.8-93.6-107.6-131.3-187c-8.8-18.4-13.2-38.4-13.2-59.2c0-80,67.7-145.1,150.9-145.1s150.9,65.1,150.9,145.1C-191.6,993.4-196,1013.3-204.8,1031.8z"/>' +
    '<path opacity="0" d="M-342.5,960.5c-27,0-49,21.1-49,47.1c0,6.8,1.4,13.2,4.3,19.2c12.3,25.8,35.7,53,42.6,60.7c0.5,0.6,1.3,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c6.9-7.7,30.4-34.9,42.6-60.7c2.8-6,4.3-12.5,4.3-19.2C-293.5,981.7-315.5,960.5-342.5,960.5z"/>' +
    '</g>' +
    '</g>' +
    '</switch>' +
    '</svg>';

  shipperBubbleWithLogoDomSvg = `<div style="width: 1px; height: 1px; position: relative;"><span style="position: absolute; bottom: -5px; left: -100px;">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="200" height="140"
viewBox="625 -390 2050 1000" style="filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2))" xml:space="preserve">
<g>
<path class="white-icon" fill="white" d="M2386.9-336h-791.6c-98.4,0-178.8,80.4-178.8,178.8v277.1c0,98.4,72.5,178.8,170.9,178.8h4.9l75.1,136
 l79.6-138.3c20-2.3,693.4-6.5,712.9-13.4c63.5-28.6,105.8-92.3,105.8-163.1v-277.1C2565.7-255.6,2485.3-336,2386.9-336z"/>
<g transform="translate(1600, -300) scale(11)">
  <image  width="75" height="50" xlink:href="
  BASE_64_HASH
  " />
</g>
</g>
</svg></span></div>`;

  pinMarkerWithHoleAndBubbleDomSvg = (fill, message = "") => `
<div style="width: 1px; height: 1px; position: relative;">
  <span style="
    top: auto; 
    position: absolute;
    left: -10px;
    width: 89px;
    text-align: center;
    bottom: 53px;
    z-index: 1;
    font-size: .9rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;"
  >
  ${message}
  </span>
  <span style="position: absolute; bottom: -5px; left: -15px;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100" height="100" viewBox="0 0 1000 1000" xml:space="preserve">
<rect x="0" y="0" width="100%" height="100%" fill="rgba(255,255,255,0)"/>
<g transform="matrix(0.6336 0 0 0.6566 158.7575 818.587)" id="520756">
<path style="stroke: rgb(255,255,255); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: ${fill}; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-256, -255.9997)" d="M 256 0 C 153.755 0 70.573 83.182 70.573 185.426 c 0 126.888 165.939 313.167 173.004 321.035 c 6.636 7.391 18.222 7.378 24.846 0 c 7.065 -7.868 173.004 -194.147 173.004 -321.035 C 441.425 83.182 358.244 0 256 0 z M 256 278.719 c -51.442 0 -93.292 -41.851 -93.292 -93.293 S 204.559 92.134 256 92.134 s 93.291 41.851 93.291 93.293 S 307.441 278.719 256 278.719 z" stroke-linecap="round"/>
</g>
<g transform="matrix(0.803 0 0 0.803 498.1748 346.5398)" id="192869">
<filter id="SVGID_62" y="-27.91%" height="155.82%" x="-25.4%" width="150.8%">
	<feGaussianBlur in="SourceAlpha" stdDeviation="28"/>
	<feOffset dx="6" dy="5" result="oBlur"/>
	<feFlood flood-color="rgb(192,192,192)" flood-opacity="1"/>
	<feComposite in2="oBlur" operator="in"/>
	<feMerge>
		<feMergeNode/>
		<feMergeNode in="SourceGraphic"/>
	</feMerge>
</filter>
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;filter: url(#SVGID_62);" vector-effect="non-scaling-stroke" transform=" translate(-1991.1, -49.35)" d="M 2386.9 -336 h -791.6 c -98.4 0 -178.8 80.4 -178.8 178.8 v 277.1 c 0 98.4 72.5 178.8 170.9 178.8 h 4.9 l 75.1 136 l 79.6 -138.3 c 20 -2.3 693.4 -6.5 712.9 -13.4 c 63.5 -28.6 105.8 -92.3 105.8 -163.1 v -277.1 C 2565.7 -255.6 2485.3 -336 2386.9 -336 z" stroke-linecap="round"/>
</g>
</svg></span></div>`;


  constructor() { }

}
